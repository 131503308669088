const resources = {
    usName: 'États-Unis',
    caName: 'Canada',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    address2Label: 'App / unité/étage (facultatif)',
    add: 'Ajouter',
    country: 'Pays',
    streetAddress: 'Adresse',
    city: 'Ville',
    phone: 'Téléphone',
    postalCode: 'Code postal',
    zipPostalCode: 'Code postal',
    emailAddress: 'Adresse de courriel',
    enterZipCodeText: 'Code postal',
    region: 'Région',
    stateRegion: 'État/Région',
    province: 'Province',
    emailRequiredText: 'Vous devez fournir votre adresse courriel pour recevoir les notifications d’expédition et suivre votre commande.'
};

export default function getResource(label) {
    return resources[label];
}
