export default function getResource(label, vars = []) {
    const resources = {
        storeHours: 'Heures d’ouverture',
        storeClosed: 'Magasin fermé',
        openUntil: `Ouvert jusqu’à ${vars[0]} aujourd’hui`,
        tempClosed: 'Le magasin est temporairement fermé',
        tempUnavailable: 'Non disponible pour le moment',
        curbsideHours: 'Heures du ramassage à l’extérieur',
        availableUntil: `Disponible jusqu’à ${vars[0]} aujourd’hui`,
        unavailable: 'Non disponible',
        specialCurbsideHours: 'Heures spéciales du ramassage à l’extérieur',
        specialStoreHours: 'Heures d’ouverture spéciales',
        available: 'Disponible',
        specialOpenUntil: `Ouvert jusqu’à ${vars[0]}`,
        kohlMessage: 'Les promotions, les récompenses, les services et les événements Sephora peuvent ne pas s’appliquer dans les magasins Kohl’s.'
    };

    return resources[label];
}
