/*eslint quotes: 0*/
export default function getResource(label, vars = []) {
    const resources = {
        callStore: 'Call Store',
        cancelOrderButton: 'Cancel Your Order',
        certonaTitle: 'People Also Bought',
        confirmEmail: 'confirmation email ',
        contactInfo: 'Contact Information',
        contactMessage1: 'Please have your ',
        continueShoppingButton: 'Continue Shopping',
        curbsidePickup: 'I’m Here for Curbside',
        curbsidePickUpFirstLine: 'If you’d like curbside pickup,',
        curbsidePickUpParagraphPrefix: 'help us find you by sharing your vehicle description when you arrive at the store.',
        curbsidePickUpParagraphSufix: 'for parking instructions and hours of operation.',
        curbsideConciergeInstructionsTitle: 'Curbside Concierge Instructions',
        conciergePickupPrefix: 'Park in the spot ',
        conciergePickupSuffix: ', and then check-in with your location and vehicle information',
        describedBelow: 'described below',
        curbsideConciergePickupRequirement: 'Please have your confirmation email or photo ID ready',
        deliverTo: 'Deliver To',
        deliveryOn: 'Delivery On',
        emailUpdatesText: 'You will receive order status updates via email.',
        estimatedDelivery: 'Estimated Delivery',
        or: 'or ',
        orderedMessageText: 'It’s ordered. Check your inbox shortly for a confirmation email.',
        orderNumberText: 'Order number',
        photoId: 'photo ID ',
        pickupBarcodeMsg: 'Show your pickup barcode. You can find it in your email or by opening this page on your mobile device.',
        pickupLocation: 'Pickup Location',
        pickupOrderedMessageText: 'You’ll receive a confirmation email with reservation details at',
        pickupOrderNumberText: 'Pickup Order Number',
        pickupPerson: 'Pickup Person',
        ready: 'ready when you pick up your order.',
        sameDayConfirmationText: `You'll receive a confirmation email at`,
        seeBelow: 'See below',
        seeFAQ: 'See FAQs',
        seeCurbsideConciergeLocation: 'See curbside concierge location',
        shipmentText: `Shipment ${vars[0]}`,
        shipTo: 'Ship To',
        standardShipping: 'Standard Shipping',
        storeDetails: 'Store Details',
        thankYouHeader: 'Thank you!',
        trackYourOrder: 'Track your order',
        viewBasketButton: 'View Basket',
        viewOrderLink: 'Order Details.',
        viewOrderText: 'To view or cancel your order, go to ',
        weGotItHeader: 'We got it!',
        whatToExpectText: 'What to Expect',
        canceledItems: 'One or more items were no longer available, but the rest of your items are on their way. See below for details.',
        shipToFeDexLocation: 'Shipping to FedEx Pickup Location',
        shipToPickupLocation: 'Shipping to a pickup Location',
        viewOrderTextForSDU: 'To view your order, go to ',
        giftMessageMsg: 'Your gift message and a gift receipt will be sent to',
        giftMessageStandard: 'when the order ships',
        giftMessageSdd: 'when the order is out for delivery',
        mixedBasketGiftMessageStandard1: 'We’ll send a copy of your gift message and a gift receipt for your items arriving via Standard Shipping to',
        mixedBasketGiftMessageStandard2: 'when the order has shipped.',
        mixedBasketGiftMessageSdd1: 'We’ll send a copy of your gift message and a gift receipt for your Same-Day Delivery items to',
        mixedBasketGiftMessageSdd2: 'when the order is out for delivery.'
    };

    return resources[label];
}
