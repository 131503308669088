import localeUtils from 'utils/LanguageLocale';
import {
    SET_TAX_CLAIM_DATA,
    SET_START_APPLICATION,
    ADD_WIZARD_FORM_DATA,
    FETCH_ORDER_DETAILS_SUCCESS,
    FETCH_ORDER_DETAILS_ERROR
} from 'constants/actionTypes/taxClaim';

const initialState = {
    isInitialized: false,
    wizardForm: {
        currentCategory: '',
        currentCategoryLabel: '',
        stepData: []
    },
    orderDetails: null,
    orderApiError: null
};

const updateOrderStepData = (stepData, orderNumberErrors, genericOrderNumberErrorExists) => {
    return stepData.map(step => {
        if (step.currentStep === 2) {
            return {
                ...step,
                formErrors: {
                    ...step.formErrors,
                    orderNumberErrors, // Accepts new errors
                    genericOrderNumberErrorExists // Accepts new flag status
                }
            };
        }

        return step; // Return unchanged step for others
    });
};

const reducer = function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_TAX_CLAIM_DATA: {
            const { data } = payload;

            return { ...state, wizardForm: { ...state.wizardForm, ...data } };
        }

        case SET_START_APPLICATION: {
            return { ...initialState, ...state, isInitialized: payload };
        }

        case ADD_WIZARD_FORM_DATA: {
            const { category, stepData: newStepData } = payload;

            // Ensure stepData is an array
            if (!Array.isArray(newStepData.stepData)) {
                Sephora.logger.verbose('Invalid stepData format:', newStepData.stepData);

                // Reset stepData to an empty array if it's invalid
                newStepData.stepData = []; // Ensure stepData is an empty array
            }

            const updatedStepData = [...state.wizardForm.stepData];

            newStepData.stepData.forEach(step => {
                const stepIndex = updatedStepData.findIndex(s => s.currentStep === step.currentStep);

                if (stepIndex === -1) {
                    updatedStepData.push(step);
                } else {
                    updatedStepData[stepIndex] = {
                        ...updatedStepData[stepIndex],
                        formData: {
                            ...updatedStepData[stepIndex].formData,
                            ...step.formData // Combine with existing formData
                        },
                        formErrors: step.formErrors // Each step tracks its own form errors
                    };
                }
            });

            const getText = localeUtils.getLocaleResourceFile('components/RichProfile/MyAccount/TaxClaim/locales', 'TaxClaim');
            const currentCategoryLabel = getText(`categoryTitleFor${category}`) || '';

            return {
                ...state,
                wizardForm: {
                    ...state.wizardForm,
                    currentCategory: category,
                    currentCategoryLabel,
                    stepData: updatedStepData
                },
                genericOrderNumberErrorExists: false // Reset the error flag here
            };
        }

        case FETCH_ORDER_DETAILS_SUCCESS: {
            return {
                ...state,
                orderDetails: payload,
                wizardForm: {
                    ...state.wizardForm,
                    stepData: updateOrderStepData(
                        state.wizardForm.stepData,
                        null, // Resetting orderNumberErrors to null
                        false // Reset the error flag
                    )
                }
            };
        }

        case FETCH_ORDER_DETAILS_ERROR: {
            const orderNumberErrors = payload.orderNumberErrors || null;

            return {
                ...state,
                orderApiError: payload, // Store real error for UFE devs
                wizardForm: {
                    ...state.wizardForm,
                    stepData: updateOrderStepData(state.wizardForm.stepData, orderNumberErrors, true)
                }
            };
        }

        default: {
            return state;
        }
    }
};

export default { reducer, initialState };
