export default function getResource(label, vars = []) {
    const resources = {
        transactionTaxRefunds: 'Transaction Tax Refunds',
        taxExemptionCategorySublabel: 'Please select a category.',
        categoryStepTitle: 'Tax Exemption Category',
        categoryStepDisplayName: 'Option to Select',
        categoryStepSubtitle: 'Please choose a category',
        uploadStepTitle: 'Upload Document(s)',
        uploadStepDisplayName: 'Documents',
        orderNumberInputStepTitle: 'Order Number',
        orderNumberInputStepDisplayName: 'Order Number',
        orderNumberInputStepPlaceholder: 'Order #',
        taxExemptionInfoStepTitle: 'Tax-Exemption Account Info',
        taxExemptionInfoStepDisplayName: 'Tax Exemption Information',
        additionalCommentsStepTitle: 'Additional Comments',
        additionalCommentsStepDisplayName: 'Additional Comments',
        generalInfo: 'General Info before you begin',
        uploadAction: 'Upload',
        nextAction: 'Next',
        editAction: 'Edit',
        maxDocumentsHint: 'Documents (max 3)',
        documentsNote: 'Please note, any incomplete information on your exemption document (signature, date, not checking every applicable box) will result in a request for you to resubmit the document with complete information.',
        documentsContrains: 'Please upload a jpeg/jpg, png, img, or pdf no more than 4 MB. ',
        documentsIndications: 'Please upload front and back of the document if the document is double-sided.',
        invalidFile: 'The file could not be uploaded. Please check the file size.',
        missingFreightForwarder: 'Please select one of the following',
        categoryTitleForIA: 'Indigenous American',
        categoryTitleForR: 'Reseller',
        categoryTitleForNPRCO: 'Non-Profit, Religious or Charitable Organizations',
        categoryTitleForDVIFO: 'Disabled Veterans and Immediate Family in Oklahoma',
        categoryTitleForESFFF: 'Export Sale via Florida Freight Forwarder',
        categoryTitleForSLGEI: 'State & Local Government & Educational Institutions',
        categoryTitleForO: 'Other',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        startApplication: 'Start Application'
    };

    return resources[label];
}
