/* eslint-disable comma-dangle */
const localization = {
    'Index/locales/Index_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/Index/locales/Index_en_US.js'),
    'Index/locales/Index_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/Index/locales/Index_fr_CA.js'),
    'actions/locales/AccountActions_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/actions/locales/AccountActions_en_US.js'),
    'actions/locales/AccountActions_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/actions/locales/AccountActions_fr_CA.js'),
    'actions/locales/AutoReplenishmentActions_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/actions/locales/AutoReplenishmentActions_en_US.js'),
    'actions/locales/AutoReplenishmentActions_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/actions/locales/AutoReplenishmentActions_fr_CA.js'),
    'actions/locales/BasketActions_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/actions/locales/BasketActions_en_US.js'),
    'actions/locales/BasketActions_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/actions/locales/BasketActions_fr_CA.js'),
    'actions/locales/GameActions_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/actions/locales/GameActions_en_US.js'),
    'actions/locales/GameActions_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/actions/locales/GameActions_fr_CA.js'),
    'actions/locales/SameDayUnlimitedActions_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/actions/locales/SameDayUnlimitedActions_en_US.js'),
    'actions/locales/SameDayUnlimitedActions_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/actions/locales/SameDayUnlimitedActions_fr_CA.js'),
    'actions/locales/UserActions_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/actions/locales/UserActions_en_US.js'),
    'actions/locales/UserActions_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/actions/locales/UserActions_fr_CA.js'),
    'analytics/locales/utils_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/analytics/locales/utils_en_US.js'),
    'analytics/locales/utils_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/analytics/locales/utils_fr_CA.js'),
    'components/AddReview/AboutMe/locales/AboutMe_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/AddReview/AboutMe/locales/AboutMe_en_US.js'),
    'components/AddReview/AboutMe/locales/AboutMe_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/AddReview/AboutMe/locales/AboutMe_fr_CA.js'),
    'components/AddReview/AddReviewNote/locales/AddReviewNote_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/AddReview/AddReviewNote/locales/AddReviewNote_en_US.js'),
    'components/AddReview/AddReviewNote/locales/AddReviewNote_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/AddReview/AddReviewNote/locales/AddReviewNote_fr_CA.js'),
    'components/AddReview/PostingConfirmation/locales/PostingConfirmation_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/AddReview/PostingConfirmation/locales/PostingConfirmation_en_US.js'),
    'components/AddReview/PostingConfirmation/locales/PostingConfirmation_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/AddReview/PostingConfirmation/locales/PostingConfirmation_fr_CA.js'),
    'components/AddReview/RateAndReview/locales/RateAndReview_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/AddReview/RateAndReview/locales/RateAndReview_en_US.js'),
    'components/AddReview/RateAndReview/locales/RateAndReview_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/AddReview/RateAndReview/locales/RateAndReview_fr_CA.js'),
    'components/AddReview/SelectShade/locales/SelectShade_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/AddReview/SelectShade/locales/SelectShade_en_US.js'),
    'components/AddReview/SelectShade/locales/SelectShade_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/AddReview/SelectShade/locales/SelectShade_fr_CA.js'),
    'components/AddReview/UploadMedia/locales/UploadMedia_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/AddReview/UploadMedia/locales/UploadMedia_en_US.js'),
    'components/AddReview/UploadMedia/locales/UploadMedia_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/AddReview/UploadMedia/locales/UploadMedia_fr_CA.js'),
    'components/AddToBasketButton/locales/AddToBasketButton_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/AddToBasketButton/locales/AddToBasketButton_en_US.js'),
    'components/AddToBasketButton/locales/AddToBasketButton_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/AddToBasketButton/locales/AddToBasketButton_fr_CA.js'),
    'components/Addresses/AddressForm/locales/AddressForm_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Addresses/AddressForm/locales/AddressForm_en_US.js'),
    'components/Addresses/AddressForm/locales/AddressForm_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Addresses/AddressForm/locales/AddressForm_fr_CA.js'),
    'components/Addresses/locales/Address_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Addresses/locales/Address_en_US.js'),
    'components/Addresses/locales/Address_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Addresses/locales/Address_fr_CA.js'),
    'components/Afterpay/AfterpayPaymentMethod/locales/AfterpayPaymentMethod_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Afterpay/AfterpayPaymentMethod/locales/AfterpayPaymentMethod_en_US.js'),
    'components/Afterpay/AfterpayPaymentMethod/locales/AfterpayPaymentMethod_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Afterpay/AfterpayPaymentMethod/locales/AfterpayPaymentMethod_fr_CA.js'),
    'components/ApplePayButton/locales/ApplePayButton_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ApplePayButton/locales/ApplePayButton_en_US.js'),
    'components/ApplePayButton/locales/ApplePayButton_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ApplePayButton/locales/ApplePayButton_fr_CA.js'),
    'components/BackToTopButton/locales/BackToTopButton_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/BackToTopButton/locales/BackToTopButton_en_US.js'),
    'components/BackToTopButton/locales/BackToTopButton_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/BackToTopButton/locales/BackToTopButton_fr_CA.js'),
    'components/Banner/AcceptCookieBanner/locales/AcceptCookieBanner_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Banner/AcceptCookieBanner/locales/AcceptCookieBanner_fr_CA.js'),
    'components/Banner/AndroidAppBanner/locales/AndroidAppBanner_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Banner/AndroidAppBanner/locales/AndroidAppBanner_en_US.js'),
    'components/Banner/AndroidAppBanner/locales/AndroidAppBanner_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Banner/AndroidAppBanner/locales/AndroidAppBanner_fr_CA.js'),
    'components/Basket/ActionButtons/locales/ActionButtons_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/ActionButtons/locales/ActionButtons_en_US.js'),
    'components/Basket/ActionButtons/locales/ActionButtons_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/ActionButtons/locales/ActionButtons_fr_CA.js'),
    'components/Basket/Basket/locales/Basket_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/Basket/locales/Basket_en_US.js'),
    'components/Basket/Basket/locales/Basket_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/Basket/locales/Basket_fr_CA.js'),
    'components/Basket/BasketList/AddGiftMessage/locales/AddGiftMessage_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/BasketList/AddGiftMessage/locales/AddGiftMessage_en_US.js'),
    'components/Basket/BasketList/AddGiftMessage/locales/AddGiftMessage_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/BasketList/AddGiftMessage/locales/AddGiftMessage_fr_CA.js'),
    'components/Basket/BasketList/SDURenewalPricing/locales/SDURenewalPricing_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/BasketList/SDURenewalPricing/locales/SDURenewalPricing_en_US.js'),
    'components/Basket/BasketList/SDURenewalPricing/locales/SDURenewalPricing_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/BasketList/SDURenewalPricing/locales/SDURenewalPricing_fr_CA.js'),
    'components/Basket/BasketList/locales/BasketList_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/BasketList/locales/BasketList_en_US.js'),
    'components/Basket/BasketList/locales/BasketList_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/BasketList/locales/BasketList_fr_CA.js'),
    'components/Basket/BasketLoves/locales/BasketLoves_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/BasketLoves/locales/BasketLoves_en_US.js'),
    'components/Basket/BasketLoves/locales/BasketLoves_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/BasketLoves/locales/BasketLoves_fr_CA.js'),
    'components/Basket/BasketMain/locales/BasketMain_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/BasketMain/locales/BasketMain_en_US.js'),
    'components/Basket/BasketMain/locales/BasketMain_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/BasketMain/locales/BasketMain_fr_CA.js'),
    'components/Basket/BasketMsg/locales/BasketMsg_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/BasketMsg/locales/BasketMsg_en_US.js'),
    'components/Basket/BasketMsg/locales/BasketMsg_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/BasketMsg/locales/BasketMsg_fr_CA.js'),
    'components/Basket/BasketOrderSummary/locales/BasketOrderSummary_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/BasketOrderSummary/locales/BasketOrderSummary_en_US.js'),
    'components/Basket/BasketOrderSummary/locales/BasketOrderSummary_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/BasketOrderSummary/locales/BasketOrderSummary_fr_CA.js'),
    'components/Basket/BasketQuickAdd/locales/BasketQuickAdd_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/BasketQuickAdd/locales/BasketQuickAdd_en_US.js'),
    'components/Basket/BasketQuickAdd/locales/BasketQuickAdd_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/BasketQuickAdd/locales/BasketQuickAdd_fr_CA.js'),
    'components/Basket/BasketSignIn/locales/BasketSignIn_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/BasketSignIn/locales/BasketSignIn_en_US.js'),
    'components/Basket/BasketSignIn/locales/BasketSignIn_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/BasketSignIn/locales/BasketSignIn_fr_CA.js'),
    'components/Basket/BasketTitle/locales/BasketTitle_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/BasketTitle/locales/BasketTitle_en_US.js'),
    'components/Basket/BasketTitle/locales/BasketTitle_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/BasketTitle/locales/BasketTitle_fr_CA.js'),
    'components/Basket/ChangeMethodModal/locale/ChangeMethodModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/ChangeMethodModal/locale/ChangeMethodModal_en_US.js'),
    'components/Basket/ChangeMethodModal/locale/ChangeMethodModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/ChangeMethodModal/locale/ChangeMethodModal_fr_CA.js'),
    'components/Basket/CountrySwitchLink/locales/CountrySwitchLink_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/CountrySwitchLink/locales/CountrySwitchLink_en_US.js'),
    'components/Basket/CountrySwitchLink/locales/CountrySwitchLink_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/CountrySwitchLink/locales/CountrySwitchLink_fr_CA.js'),
    'components/Basket/DeliveryInfo/AutoReplenishment/locales/AutoReplenishment_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/DeliveryInfo/AutoReplenishment/locales/AutoReplenishment_en_US.js'),
    'components/Basket/DeliveryInfo/AutoReplenishment/locales/AutoReplenishment_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/DeliveryInfo/AutoReplenishment/locales/AutoReplenishment_fr_CA.js'),
    'components/Basket/DeliveryInfo/SameDayDelivery/locales/SameDayDelivery_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/DeliveryInfo/SameDayDelivery/locales/SameDayDelivery_en_US.js'),
    'components/Basket/DeliveryInfo/SameDayDelivery/locales/SameDayDelivery_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/DeliveryInfo/SameDayDelivery/locales/SameDayDelivery_fr_CA.js'),
    'components/Basket/DeliveryInfo/StandardDelivery/locales/StandardDelivery_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/DeliveryInfo/StandardDelivery/locales/StandardDelivery_en_US.js'),
    'components/Basket/DeliveryInfo/StandardDelivery/locales/StandardDelivery_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/DeliveryInfo/StandardDelivery/locales/StandardDelivery_fr_CA.js'),
    'components/Basket/DisplayFreeReturnsText/locale/DisplayFreeReturnsText_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/DisplayFreeReturnsText/locale/DisplayFreeReturnsText_fr_CA.js'),
    'components/Basket/DisplayFreeReturnsText/locales/DisplayFreeReturnsText_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/DisplayFreeReturnsText/locales/DisplayFreeReturnsText_en_US.js'),
    'components/Basket/DisplayFreeReturnsText/locales/DisplayFreeReturnsText_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/DisplayFreeReturnsText/locales/DisplayFreeReturnsText_fr_CA.js'),
    'components/Basket/DisplaySDUErrorText/locales/DisplaySDUErrorText_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/DisplaySDUErrorText/locales/DisplaySDUErrorText_en_US.js'),
    'components/Basket/DisplaySDUErrorText/locales/DisplaySDUErrorText_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/DisplaySDUErrorText/locales/DisplaySDUErrorText_fr_CA.js'),
    'components/Basket/MobileSamplesList/locales/MobileSamplesList_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/MobileSamplesList/locales/MobileSamplesList_en_US.js'),
    'components/Basket/MobileSamplesList/locales/MobileSamplesList_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/MobileSamplesList/locales/MobileSamplesList_fr_CA.js'),
    'components/Basket/NeedAssistance/locales/NeedAssistance_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/NeedAssistance/locales/NeedAssistance_en_US.js'),
    'components/Basket/NeedAssistance/locales/NeedAssistance_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/NeedAssistance/locales/NeedAssistance_fr_CA.js'),
    'components/Basket/PaymentModal/locale/PaymentModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/PaymentModal/locale/PaymentModal_en_US.js'),
    'components/Basket/PaymentModal/locale/PaymentModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/PaymentModal/locale/PaymentModal_fr_CA.js'),
    'components/Basket/PickupInsteadModal/locale/PickupInsteadModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/PickupInsteadModal/locale/PickupInsteadModal_fr_CA.js'),
    'components/Basket/PreBasket/locale/PreBasket_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/PreBasket/locale/PreBasket_en_US.js'),
    'components/Basket/PreBasket/locale/PreBasket_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/PreBasket/locale/PreBasket_fr_CA.js'),
    'components/Basket/PromoSection/locales/PromoSection_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/PromoSection/locales/PromoSection_en_US.js'),
    'components/Basket/PromoSection/locales/PromoSection_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/PromoSection/locales/PromoSection_fr_CA.js'),
    'components/Basket/Rewards/MobileRewardsInBasket/locales/MobileRewardsInBasket_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/Rewards/MobileRewardsInBasket/locales/MobileRewardsInBasket_en_US.js'),
    'components/Basket/Rewards/MobileRewardsInBasket/locales/MobileRewardsInBasket_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/Rewards/MobileRewardsInBasket/locales/MobileRewardsInBasket_fr_CA.js'),
    'components/Basket/Rewards/RewardsCallToAction/locales/RewardsCallToAction_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/Rewards/RewardsCallToAction/locales/RewardsCallToAction_en_US.js'),
    'components/Basket/Rewards/RewardsCallToAction/locales/RewardsCallToAction_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/Rewards/RewardsCallToAction/locales/RewardsCallToAction_fr_CA.js'),
    'components/Basket/Rewards/locales/Rewards_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/Rewards/locales/Rewards_en_US.js'),
    'components/Basket/Rewards/locales/Rewards_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/Rewards/locales/Rewards_fr_CA.js'),
    'components/Basket/RopisBasket/RopisBasketOrderSummary/locales/RopisBasketOrderSummary_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/RopisBasket/RopisBasketOrderSummary/locales/RopisBasketOrderSummary_en_US.js'),
    'components/Basket/RopisBasket/RopisBasketOrderSummary/locales/RopisBasketOrderSummary_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/RopisBasket/RopisBasketOrderSummary/locales/RopisBasketOrderSummary_fr_CA.js'),
    'components/Basket/RopisBasket/locales/RopisBasket_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/RopisBasket/locales/RopisBasket_en_US.js'),
    'components/Basket/RopisBasket/locales/RopisBasket_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/RopisBasket/locales/RopisBasket_fr_CA.js'),
    'components/Basket/SDUCell/locales/SDUCell_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/SDUCell/locales/SDUCell_en_US.js'),
    'components/Basket/SDUCell/locales/SDUCell_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/SDUCell/locales/SDUCell_fr_CA.js'),
    'components/Basket/SampleRewardTabs/locales/SampleRewardTabs_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/SampleRewardTabs/locales/SampleRewardTabs_en_US.js'),
    'components/Basket/SampleRewardTabs/locales/SampleRewardTabs_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/SampleRewardTabs/locales/SampleRewardTabs_fr_CA.js'),
    'components/Basket/ShippingMessage/locales/ShippingMessage_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/ShippingMessage/locales/ShippingMessage_en_US.js'),
    'components/Basket/ShippingMessage/locales/ShippingMessage_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/ShippingMessage/locales/ShippingMessage_fr_CA.js'),
    'components/Basket/SwitchBasket/locales/SwitchBasket_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/SwitchBasket/locales/SwitchBasket_en_US.js'),
    'components/Basket/SwitchBasket/locales/SwitchBasket_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/SwitchBasket/locales/SwitchBasket_fr_CA.js'),
    'components/Basket/SwitchBasketBanner/locales/SwitchBasketBanner_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/SwitchBasketBanner/locales/SwitchBasketBanner_en_US.js'),
    'components/Basket/SwitchBasketBanner/locales/SwitchBasketBanner_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/SwitchBasketBanner/locales/SwitchBasketBanner_fr_CA.js'),
    'components/Basket/TaxModal/locale/TaxModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/TaxModal/locale/TaxModal_en_US.js'),
    'components/Basket/TaxModal/locale/TaxModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Basket/TaxModal/locale/TaxModal_fr_CA.js'),
    'components/BasketBreakdownSummary/locales/BasketBreakdownSummary_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/BasketBreakdownSummary/locales/BasketBreakdownSummary_en_US.js'),
    'components/BasketBreakdownSummary/locales/BasketBreakdownSummary_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/BasketBreakdownSummary/locales/BasketBreakdownSummary_fr_CA.js'),
    'components/Bcc/BccCarousel/locales/BccCarousel_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Bcc/BccCarousel/locales/BccCarousel_en_US.js'),
    'components/Bcc/BccCarousel/locales/BccCarousel_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Bcc/BccCarousel/locales/BccCarousel_fr_CA.js'),
    'components/Bcc/BccEmailSmsOptIn/locales/BccEmailSmsOptIn_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Bcc/BccEmailSmsOptIn/locales/BccEmailSmsOptIn_en_US.js'),
    'components/Bcc/BccEmailSmsOptIn/locales/BccEmailSmsOptIn_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Bcc/BccEmailSmsOptIn/locales/BccEmailSmsOptIn_fr_CA.js'),
    'components/Bcc/BccPlaceHolderApp/locales/BccPlaceHolderApp_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Bcc/BccPlaceHolderApp/locales/BccPlaceHolderApp_en_US.js'),
    'components/Bcc/BccPlaceHolderApp/locales/BccPlaceHolderApp_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Bcc/BccPlaceHolderApp/locales/BccPlaceHolderApp_fr_CA.js'),
    'components/Bcc/BccProductFinder/locales/BccProductFinder_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Bcc/BccProductFinder/locales/BccProductFinder_en_US.js'),
    'components/Bcc/BccProductFinder/locales/BccProductFinder_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Bcc/BccProductFinder/locales/BccProductFinder_fr_CA.js'),
    'components/Bcc/BccPromotion/locales/BccPromotion_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Bcc/BccPromotion/locales/BccPromotion_en_US.js'),
    'components/Bcc/BccPromotion/locales/BccPromotion_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Bcc/BccPromotion/locales/BccPromotion_fr_CA.js'),
    'components/Bcc/BccRwdPersonalizedPromoList/PromoListFallback/locales/PromoListFallback_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Bcc/BccRwdPersonalizedPromoList/PromoListFallback/locales/PromoListFallback_en_US.js'),
    'components/Bcc/BccRwdPersonalizedPromoList/PromoListFallback/locales/PromoListFallback_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Bcc/BccRwdPersonalizedPromoList/PromoListFallback/locales/PromoListFallback_fr_CA.js'),
    'components/Bcc/BccRwdProductList/locales/BccRwdProductList_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Bcc/BccRwdProductList/locales/BccRwdProductList_en_US.js'),
    'components/Bcc/BccRwdProductList/locales/BccRwdProductList_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Bcc/BccRwdProductList/locales/BccRwdProductList_fr_CA.js'),
    'components/Bcc/BccRwdPromoList/locales/BccRwdPromoItem_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Bcc/BccRwdPromoList/locales/BccRwdPromoItem_en_US.js'),
    'components/Bcc/BccRwdPromoList/locales/BccRwdPromoItem_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Bcc/BccRwdPromoList/locales/BccRwdPromoItem_fr_CA.js'),
    'components/Bcc/BccRwdRewardsList/locales/BccRwdRewardsList_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Bcc/BccRwdRewardsList/locales/BccRwdRewardsList_en_US.js'),
    'components/Bcc/BccRwdRewardsList/locales/BccRwdRewardsList_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Bcc/BccRwdRewardsList/locales/BccRwdRewardsList_fr_CA.js'),
    'components/Bcc/BccVideo/locales/BccVideo_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Bcc/BccVideo/locales/BccVideo_en_US.js'),
    'components/Bcc/BccVideo/locales/BccVideo_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Bcc/BccVideo/locales/BccVideo_fr_CA.js'),
    'components/BeautyMatchBadge/locales/BeautyMatchBadge_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/BeautyMatchBadge/locales/BeautyMatchBadge_en_US.js'),
    'components/BeautyMatchBadge/locales/BeautyMatchBadge_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/BeautyMatchBadge/locales/BeautyMatchBadge_fr_CA.js'),
    'components/BeautyPreferenceSpoke/locales/BeautyPreferenceSpoke_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/BeautyPreferenceSpoke/locales/BeautyPreferenceSpoke_en_US.js'),
    'components/BeautyPreferenceSpoke/locales/BeautyPreferenceSpoke_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/BeautyPreferenceSpoke/locales/BeautyPreferenceSpoke_fr_CA.js'),
    'components/BeautyWinPromo/locales/BeautyWinPromoBody_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/BeautyWinPromo/locales/BeautyWinPromoBody_en_US.js'),
    'components/BeautyWinPromo/locales/BeautyWinPromoBody_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/BeautyWinPromo/locales/BeautyWinPromoBody_fr_CA.js'),
    'components/BiQualify/locales/BiQualify_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/BiQualify/locales/BiQualify_en_US.js'),
    'components/BiQualify/locales/BiQualify_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/BiQualify/locales/BiQualify_fr_CA.js'),
    'components/BiRegisterForm/BiBirthdayForm/locales/BiBirthdayForm_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/BiRegisterForm/BiBirthdayForm/locales/BiBirthdayForm_en_US.js'),
    'components/BiRegisterForm/BiBirthdayForm/locales/BiBirthdayForm_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/BiRegisterForm/BiBirthdayForm/locales/BiBirthdayForm_fr_CA.js'),
    'components/BiRegisterForm/locales/BiRegisterForm_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/BiRegisterForm/locales/BiRegisterForm_en_US.js'),
    'components/BiRegisterForm/locales/BiRegisterForm_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/BiRegisterForm/locales/BiRegisterForm_fr_CA.js'),
    'components/Brand/BrandLaunch/locales/BrandLaunch_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Brand/BrandLaunch/locales/BrandLaunch_en_US.js'),
    'components/Brand/BrandLaunch/locales/BrandLaunch_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Brand/BrandLaunch/locales/BrandLaunch_fr_CA.js'),
    'components/Brand/BrandsList/FavoriteBrands/locales/FavoriteBrands_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Brand/BrandsList/FavoriteBrands/locales/FavoriteBrands_en_US.js'),
    'components/Brand/BrandsList/FavoriteBrands/locales/FavoriteBrands_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Brand/BrandsList/FavoriteBrands/locales/FavoriteBrands_fr_CA.js'),
    'components/Brand/BrandsList/locales/BrandsList_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Brand/BrandsList/locales/BrandsList_en_US.js'),
    'components/Brand/BrandsList/locales/BrandsList_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Brand/BrandsList/locales/BrandsList_fr_CA.js'),
    'components/BuyPage/RwdBuy/BuyItem/locales/BuyItem_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/BuyPage/RwdBuy/BuyItem/locales/BuyItem_en_US.js'),
    'components/BuyPage/RwdBuy/BuyItem/locales/BuyItem_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/BuyPage/RwdBuy/BuyItem/locales/BuyItem_fr_CA.js'),
    'components/BuyPage/RwdBuy/locales/BuyPage_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/BuyPage/RwdBuy/locales/BuyPage_en_US.js'),
    'components/BuyPage/RwdBuy/locales/BuyPage_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/BuyPage/RwdBuy/locales/BuyPage_fr_CA.js'),
    'components/Calendar/CalendarHeader/locales/CalendarHeader_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Calendar/CalendarHeader/locales/CalendarHeader_en_US.js'),
    'components/Calendar/CalendarHeader/locales/CalendarHeader_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Calendar/CalendarHeader/locales/CalendarHeader_fr_CA.js'),
    'components/Campaigns/Referrer/locales/Referrer_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Campaigns/Referrer/locales/Referrer_en_US.js'),
    'components/Campaigns/Referrer/locales/Referrer_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Campaigns/Referrer/locales/Referrer_fr_CA.js'),
    'components/Campaigns/RwdAdvocacy/AdvocacyViews/AdvocacyError/locales/AdvocacyError_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Campaigns/RwdAdvocacy/AdvocacyViews/AdvocacyError/locales/AdvocacyError_en_US.js'),
    'components/Campaigns/RwdAdvocacy/AdvocacyViews/AdvocacyError/locales/AdvocacyError_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Campaigns/RwdAdvocacy/AdvocacyViews/AdvocacyError/locales/AdvocacyError_fr_CA.js'),
    'components/Campaigns/RwdAdvocacy/AdvocacyViews/AdvocacyLanding/locales/AdvocacyLanding_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Campaigns/RwdAdvocacy/AdvocacyViews/AdvocacyLanding/locales/AdvocacyLanding_en_US.js'),
    'components/Campaigns/RwdAdvocacy/AdvocacyViews/AdvocacyLanding/locales/AdvocacyLanding_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Campaigns/RwdAdvocacy/AdvocacyViews/AdvocacyLanding/locales/AdvocacyLanding_fr_CA.js'),
    'components/Campaigns/RwdAdvocacy/AdvocacyViews/AdvocacySuccess/locales/AdvocacySuccess_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Campaigns/RwdAdvocacy/AdvocacyViews/AdvocacySuccess/locales/AdvocacySuccess_en_US.js'),
    'components/Campaigns/RwdAdvocacy/AdvocacyViews/AdvocacySuccess/locales/AdvocacySuccess_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Campaigns/RwdAdvocacy/AdvocacyViews/AdvocacySuccess/locales/AdvocacySuccess_fr_CA.js'),
    'components/Carousel/locales/Carousel_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Carousel/locales/Carousel_en_US.js'),
    'components/Carousel/locales/Carousel_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Carousel/locales/Carousel_fr_CA.js'),
    'components/Catalog/BreadCrumbs/locales/BreadCrumbs_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Catalog/BreadCrumbs/locales/BreadCrumbs_en_US.js'),
    'components/Catalog/BreadCrumbs/locales/BreadCrumbs_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Catalog/BreadCrumbs/locales/BreadCrumbs_fr_CA.js'),
    'components/Catalog/Filters/locales/Filters_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Catalog/Filters/locales/Filters_en_US.js'),
    'components/Catalog/Filters/locales/Filters_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Catalog/Filters/locales/Filters_fr_CA.js'),
    'components/Catalog/ProductGrid/NoResults/locales/NoResults_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Catalog/ProductGrid/NoResults/locales/NoResults_en_US.js'),
    'components/Catalog/ProductGrid/NoResults/locales/NoResults_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Catalog/ProductGrid/NoResults/locales/NoResults_fr_CA.js'),
    'components/Catalog/ProductGrid/ProductTile/locales/ProductTile_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Catalog/ProductGrid/ProductTile/locales/ProductTile_en_US.js'),
    'components/Catalog/ProductGrid/ProductTile/locales/ProductTile_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Catalog/ProductGrid/ProductTile/locales/ProductTile_fr_CA.js'),
    'components/Catalog/ProductSort/locales/ProductSort_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Catalog/ProductSort/locales/ProductSort_en_US.js'),
    'components/Catalog/ProductSort/locales/ProductSort_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Catalog/ProductSort/locales/ProductSort_fr_CA.js'),
    'components/Catalog/UpperFunnel/UpperFunnelProductTiles/locales/UpperFunnelProductTiles_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Catalog/UpperFunnel/UpperFunnelProductTiles/locales/UpperFunnelProductTiles_en_US.js'),
    'components/Catalog/UpperFunnel/UpperFunnelProductTiles/locales/UpperFunnelProductTiles_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Catalog/UpperFunnel/UpperFunnelProductTiles/locales/UpperFunnelProductTiles_fr_CA.js'),
    'components/Catalog/UpperFunnel/locales/UpperFunnelFilters_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Catalog/UpperFunnel/locales/UpperFunnelFilters_en_US.js'),
    'components/Catalog/UpperFunnel/locales/UpperFunnelFilters_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Catalog/UpperFunnel/locales/UpperFunnelFilters_fr_CA.js'),
    'components/Catalog/locales/Catalog_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Catalog/locales/Catalog_en_US.js'),
    'components/Catalog/locales/Catalog_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Catalog/locales/Catalog_fr_CA.js'),
    'components/CategoryTree/locales/CategoryTree_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CategoryTree/locales/CategoryTree_en_US.js'),
    'components/CategoryTree/locales/CategoryTree_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CategoryTree/locales/CategoryTree_fr_CA.js'),
    'components/Checkout/AccordionSection/locales/AccordionSection_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/AccordionSection/locales/AccordionSection_en_US.js'),
    'components/Checkout/AccordionSection/locales/AccordionSection_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/AccordionSection/locales/AccordionSection_fr_CA.js'),
    'components/Checkout/OrderSummary/CheckoutPromoSection/locales/CheckoutPromoSection_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/OrderSummary/CheckoutPromoSection/locales/CheckoutPromoSection_en_US.js'),
    'components/Checkout/OrderSummary/CheckoutPromoSection/locales/CheckoutPromoSection_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/OrderSummary/CheckoutPromoSection/locales/CheckoutPromoSection_fr_CA.js'),
    'components/Checkout/OrderSummary/OrderTotalSection/AgentAwareAgreement/locales/AgentAwareAgreement_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/OrderSummary/OrderTotalSection/AgentAwareAgreement/locales/AgentAwareAgreement_en_US.js'),
    'components/Checkout/OrderSummary/OrderTotalSection/AgentAwareAgreement/locales/AgentAwareAgreement_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/OrderSummary/OrderTotalSection/AgentAwareAgreement/locales/AgentAwareAgreement_fr_CA.js'),
    'components/Checkout/OrderSummary/OrderTotalSection/SDUAgreement/locales/SDUAgreement_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/OrderSummary/OrderTotalSection/SDUAgreement/locales/SDUAgreement_en_US.js'),
    'components/Checkout/OrderSummary/OrderTotalSection/SDUAgreement/locales/SDUAgreement_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/OrderSummary/OrderTotalSection/SDUAgreement/locales/SDUAgreement_fr_CA.js'),
    'components/Checkout/OrderSummary/OrderTotalSection/locales/OrderTotalSection_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/OrderSummary/OrderTotalSection/locales/OrderTotalSection_en_US.js'),
    'components/Checkout/OrderSummary/OrderTotalSection/locales/OrderTotalSection_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/OrderSummary/OrderTotalSection/locales/OrderTotalSection_fr_CA.js'),
    'components/Checkout/OrderSummary/locales/OrderSummary_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/OrderSummary/locales/OrderSummary_en_US.js'),
    'components/Checkout/OrderSummary/locales/OrderSummary_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/OrderSummary/locales/OrderSummary_fr_CA.js'),
    'components/Checkout/PlaceOrderButton/locales/PlaceOrderButton_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/PlaceOrderButton/locales/PlaceOrderButton_en_US.js'),
    'components/Checkout/PlaceOrderButton/locales/PlaceOrderButton_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/PlaceOrderButton/locales/PlaceOrderButton_fr_CA.js'),
    'components/Checkout/RopisCheckout/locales/RopisCheckout_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/RopisCheckout/locales/RopisCheckout_en_US.js'),
    'components/Checkout/RopisCheckout/locales/RopisCheckout_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/RopisCheckout/locales/RopisCheckout_fr_CA.js'),
    'components/Checkout/Sections/AccountCreation/locales/AccountCreation_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/AccountCreation/locales/AccountCreation_en_US.js'),
    'components/Checkout/Sections/AccountCreation/locales/AccountCreation_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/AccountCreation/locales/AccountCreation_fr_CA.js'),
    'components/Checkout/Sections/AlternatePickup/locales/AlternatePickup_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/AlternatePickup/locales/AlternatePickup_en_US.js'),
    'components/Checkout/Sections/AlternatePickup/locales/AlternatePickup_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/AlternatePickup/locales/AlternatePickup_fr_CA.js'),
    'components/Checkout/Sections/GiftCardShipOptions/locales/GiftCardShipOptionsForm_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/GiftCardShipOptions/locales/GiftCardShipOptionsForm_en_US.js'),
    'components/Checkout/Sections/GiftCardShipOptions/locales/GiftCardShipOptionsForm_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/GiftCardShipOptions/locales/GiftCardShipOptionsForm_fr_CA.js'),
    'components/Checkout/Sections/Payment/CheckoutCreditCardForm/locales/CheckoutCreditCardForm_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/Payment/CheckoutCreditCardForm/locales/CheckoutCreditCardForm_en_US.js'),
    'components/Checkout/Sections/Payment/CheckoutCreditCardForm/locales/CheckoutCreditCardForm_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/Payment/CheckoutCreditCardForm/locales/CheckoutCreditCardForm_fr_CA.js'),
    'components/Checkout/Sections/Payment/Display/locales/PaymentDisplay_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/Payment/Display/locales/PaymentDisplay_en_US.js'),
    'components/Checkout/Sections/Payment/Display/locales/PaymentDisplay_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/Payment/Display/locales/PaymentDisplay_fr_CA.js'),
    'components/Checkout/Sections/Payment/GiftCardSection/locales/GiftCardSection_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/Payment/GiftCardSection/locales/GiftCardSection_en_US.js'),
    'components/Checkout/Sections/Payment/GiftCardSection/locales/GiftCardSection_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/Payment/GiftCardSection/locales/GiftCardSection_fr_CA.js'),
    'components/Checkout/Sections/Payment/Section/locales/PaymentSection_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/Payment/Section/locales/PaymentSection_en_US.js'),
    'components/Checkout/Sections/Payment/Section/locales/PaymentSection_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/Payment/Section/locales/PaymentSection_fr_CA.js'),
    'components/Checkout/Sections/PickUpMethodOption/locales/PickUpMethodOption_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/PickUpMethodOption/locales/PickUpMethodOption_en_US.js'),
    'components/Checkout/Sections/PickUpMethodOption/locales/PickUpMethodOption_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/PickUpMethodOption/locales/PickUpMethodOption_fr_CA.js'),
    'components/Checkout/Sections/PickUpOrderContactInfo/locales/PickUpOrderContactInfo_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/PickUpOrderContactInfo/locales/PickUpOrderContactInfo_en_US.js'),
    'components/Checkout/Sections/PickUpOrderContactInfo/locales/PickUpOrderContactInfo_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/PickUpOrderContactInfo/locales/PickUpOrderContactInfo_fr_CA.js'),
    'components/Checkout/Sections/PickUpOrderLocation/locales/PickUpOrderLocation_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/PickUpOrderLocation/locales/PickUpOrderLocation_en_US.js'),
    'components/Checkout/Sections/PickUpOrderLocation/locales/PickUpOrderLocation_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/PickUpOrderLocation/locales/PickUpOrderLocation_fr_CA.js'),
    'components/Checkout/Sections/Review/locales/ReviewText_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/Review/locales/ReviewText_en_US.js'),
    'components/Checkout/Sections/Review/locales/ReviewText_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/Review/locales/ReviewText_fr_CA.js'),
    'components/Checkout/Sections/SddSections/ExpandableBasketItems/locales/ExpandableBasketItems_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/SddSections/ExpandableBasketItems/locales/ExpandableBasketItems_en_US.js'),
    'components/Checkout/Sections/SddSections/ExpandableBasketItems/locales/ExpandableBasketItems_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/SddSections/ExpandableBasketItems/locales/ExpandableBasketItems_fr_CA.js'),
    'components/Checkout/Sections/SddSections/SddSection/DeliveryWindow/SDUDeliveryPrice/locales/SDUDeliveryPrice_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/SddSections/SddSection/DeliveryWindow/SDUDeliveryPrice/locales/SDUDeliveryPrice_en_US.js'),
    'components/Checkout/Sections/SddSections/SddSection/DeliveryWindow/SDUDeliveryPrice/locales/SDUDeliveryPrice_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/SddSections/SddSection/DeliveryWindow/SDUDeliveryPrice/locales/SDUDeliveryPrice_fr_CA.js'),
    'components/Checkout/Sections/SddSections/SddSection/DeliveryWindow/locales/DeliveryWindowOption_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/SddSections/SddSection/DeliveryWindow/locales/DeliveryWindowOption_en_US.js'),
    'components/Checkout/Sections/SddSections/SddSection/DeliveryWindow/locales/DeliveryWindowOption_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/SddSections/SddSection/DeliveryWindow/locales/DeliveryWindowOption_fr_CA.js'),
    'components/Checkout/Sections/SddSections/SddSection/locales/SddSection_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/SddSections/SddSection/locales/SddSection_en_US.js'),
    'components/Checkout/Sections/SddSections/SddSection/locales/SddSection_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/SddSections/SddSection/locales/SddSection_fr_CA.js'),
    'components/Checkout/Sections/SddSections/StandardSection/locales/StandardSection_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/SddSections/StandardSection/locales/StandardSection_en_US.js'),
    'components/Checkout/Sections/SddSections/StandardSection/locales/StandardSection_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/SddSections/StandardSection/locales/StandardSection_fr_CA.js'),
    'components/Checkout/Sections/ShipAddress/locales/ShipAddress_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/ShipAddress/locales/ShipAddress_en_US.js'),
    'components/Checkout/Sections/ShipAddress/locales/ShipAddress_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/ShipAddress/locales/ShipAddress_fr_CA.js'),
    'components/Checkout/Sections/ShipOptions/locales/ShipOptions_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/ShipOptions/locales/ShipOptions_en_US.js'),
    'components/Checkout/Sections/ShipOptions/locales/ShipOptions_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/ShipOptions/locales/ShipOptions_fr_CA.js'),
    'components/Checkout/Sections/ShipToAccessPoint/locales/ShipToAccessPoint_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/ShipToAccessPoint/locales/ShipToAccessPoint_en_US.js'),
    'components/Checkout/Sections/ShipToAccessPoint/locales/ShipToAccessPoint_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Sections/ShipToAccessPoint/locales/ShipToAccessPoint_fr_CA.js'),
    'components/Checkout/Shared/AccessPointButton/AccessPointModal/locales/AccessPointModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Shared/AccessPointButton/AccessPointModal/locales/AccessPointModal_en_US.js'),
    'components/Checkout/Shared/AccessPointButton/AccessPointModal/locales/AccessPointModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Shared/AccessPointButton/AccessPointModal/locales/AccessPointModal_fr_CA.js'),
    'components/Checkout/Shared/AccessPointButton/locales/AccessPointButton_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Shared/AccessPointButton/locales/AccessPointButton_en_US.js'),
    'components/Checkout/Shared/AccessPointButton/locales/AccessPointButton_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Shared/AccessPointButton/locales/AccessPointButton_fr_CA.js'),
    'components/Checkout/Shared/GiftOptionsAutoReplenishMessage/locales/GiftOptionsAutoReplenishMessage_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Shared/GiftOptionsAutoReplenishMessage/locales/GiftOptionsAutoReplenishMessage_fr_CA.js'),
    'components/Checkout/Shared/Guest/locales/GuestSavePointsCheckbox_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Shared/Guest/locales/GuestSavePointsCheckbox_en_US.js'),
    'components/Checkout/Shared/Guest/locales/GuestSavePointsCheckbox_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Shared/Guest/locales/GuestSavePointsCheckbox_fr_CA.js'),
    'components/Checkout/Shared/PayPal/locales/SaveToAccountCheckbox_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Shared/PayPal/locales/SaveToAccountCheckbox_en_US.js'),
    'components/Checkout/Shared/PayPal/locales/SaveToAccountCheckbox_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Shared/PayPal/locales/SaveToAccountCheckbox_fr_CA.js'),
    'components/Checkout/Shared/ShippingAutoReplenishDeliveryInfoMessage/locales/ShippingAutoReplenishDeliveryInfoMessage_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Shared/ShippingAutoReplenishDeliveryInfoMessage/locales/ShippingAutoReplenishDeliveryInfoMessage_en_US.js'),
    'components/Checkout/Shared/ShippingAutoReplenishDeliveryInfoMessage/locales/ShippingAutoReplenishDeliveryInfoMessage_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Shared/ShippingAutoReplenishDeliveryInfoMessage/locales/ShippingAutoReplenishDeliveryInfoMessage_fr_CA.js'),
    'components/Checkout/Shared/ShippingCalculationInfoMessage/locales/ShippingCalculationInfoMessage_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Shared/ShippingCalculationInfoMessage/locales/ShippingCalculationInfoMessage_en_US.js'),
    'components/Checkout/Shared/ShippingCalculationInfoMessage/locales/ShippingCalculationInfoMessage_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Shared/ShippingCalculationInfoMessage/locales/ShippingCalculationInfoMessage_fr_CA.js'),
    'components/Checkout/Shared/locales/UpdateError_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Shared/locales/UpdateError_en_US.js'),
    'components/Checkout/Shared/locales/UpdateError_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/Shared/locales/UpdateError_fr_CA.js'),
    'components/Checkout/locales/CheckoutMain_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/locales/CheckoutMain_en_US.js'),
    'components/Checkout/locales/CheckoutMain_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Checkout/locales/CheckoutMain_fr_CA.js'),
    'components/CheckoutButton/locales/CheckoutButton_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CheckoutButton/locales/CheckoutButton_en_US.js'),
    'components/CheckoutButton/locales/CheckoutButton_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CheckoutButton/locales/CheckoutButton_fr_CA.js'),
    'components/Community/CommunityNavigation/locales/CommunityNavigation_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Community/CommunityNavigation/locales/CommunityNavigation_en_US.js'),
    'components/Community/CommunityNavigation/locales/CommunityNavigation_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Community/CommunityNavigation/locales/CommunityNavigation_fr_CA.js'),
    'components/Community/ExploreGallery/locales/ExploreGallery_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Community/ExploreGallery/locales/ExploreGallery_en_US.js'),
    'components/Community/ExploreGallery/locales/ExploreGallery_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Community/ExploreGallery/locales/ExploreGallery_fr_CA.js'),
    'components/Community/GalleryCard/locales/GalleryCard_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Community/GalleryCard/locales/GalleryCard_en_US.js'),
    'components/Community/GalleryCard/locales/GalleryCard_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Community/GalleryCard/locales/GalleryCard_fr_CA.js'),
    'components/Community/GalleryGreetings/locales/GalleryGreetings_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Community/GalleryGreetings/locales/GalleryGreetings_en_US.js'),
    'components/Community/GalleryGreetings/locales/GalleryGreetings_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Community/GalleryGreetings/locales/GalleryGreetings_fr_CA.js'),
    'components/Community/GalleryGridMasonry/locales/GalleryGridMasonry_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Community/GalleryGridMasonry/locales/GalleryGridMasonry_en_US.js'),
    'components/Community/GalleryGridMasonry/locales/GalleryGridMasonry_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Community/GalleryGridMasonry/locales/GalleryGridMasonry_fr_CA.js'),
    'components/Community/GalleryLightBoxData/locales/GalleryLightBoxData_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Community/GalleryLightBoxData/locales/GalleryLightBoxData_en_US.js'),
    'components/Community/GalleryLightBoxData/locales/GalleryLightBoxData_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Community/GalleryLightBoxData/locales/GalleryLightBoxData_fr_CA.js'),
    'components/Community/ShopThisPostCard/locales/ShopThisPostCard_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Community/ShopThisPostCard/locales/ShopThisPostCard_en_US.js'),
    'components/Community/ShopThisPostCard/locales/ShopThisPostCard_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Community/ShopThisPostCard/locales/ShopThisPostCard_fr_CA.js'),
    'components/Community/ShopThisPostCarousel/locales/ShopThisPostCarousel_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Community/ShopThisPostCarousel/locales/ShopThisPostCarousel_en_US.js'),
    'components/Community/ShopThisPostCarousel/locales/ShopThisPostCarousel_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Community/ShopThisPostCarousel/locales/ShopThisPostCarousel_fr_CA.js'),
    'components/Community/TrendingSection/locales/TrendingSection_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Community/TrendingSection/locales/TrendingSection_en_US.js'),
    'components/Community/TrendingSection/locales/TrendingSection_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Community/TrendingSection/locales/TrendingSection_fr_CA.js'),
    'components/Community/UsersGallery/locales/UsersGallery_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Community/UsersGallery/locales/UsersGallery_en_US.js'),
    'components/Community/UsersGallery/locales/UsersGallery_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Community/UsersGallery/locales/UsersGallery_fr_CA.js'),
    'components/ConciergeCurbsidePickupIndicator/locales/ConciergeCurbsidePickupIndicator_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ConciergeCurbsidePickupIndicator/locales/ConciergeCurbsidePickupIndicator_en_US.js'),
    'components/ConciergeCurbsidePickupIndicator/locales/ConciergeCurbsidePickupIndicator_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ConciergeCurbsidePickupIndicator/locales/ConciergeCurbsidePickupIndicator_fr_CA.js'),
    'components/Content/BeautyInsider/BeautyInsiderModules/ActiveCampaign/locales/ActiveCampaign_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/BeautyInsider/BeautyInsiderModules/ActiveCampaign/locales/ActiveCampaign_en_US.js'),
    'components/Content/BeautyInsider/BeautyInsiderModules/ActiveCampaign/locales/ActiveCampaign_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/BeautyInsider/BeautyInsiderModules/ActiveCampaign/locales/ActiveCampaign_fr_CA.js'),
    'components/Content/BeautyInsider/BeautyInsiderModules/BiCashOptions/locales/BiCashOptions_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/BeautyInsider/BeautyInsiderModules/BiCashOptions/locales/BiCashOptions_en_US.js'),
    'components/Content/BeautyInsider/BeautyInsiderModules/BiCashOptions/locales/BiCashOptions_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/BeautyInsider/BeautyInsiderModules/BiCashOptions/locales/BiCashOptions_fr_CA.js'),
    'components/Content/BeautyInsider/BeautyInsiderModules/BiRewardsCarousel/locales/BiRewardsCarousel_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/BeautyInsider/BeautyInsiderModules/BiRewardsCarousel/locales/BiRewardsCarousel_en_US.js'),
    'components/Content/BeautyInsider/BeautyInsiderModules/BiRewardsCarousel/locales/BiRewardsCarousel_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/BeautyInsider/BeautyInsiderModules/BiRewardsCarousel/locales/BiRewardsCarousel_fr_CA.js'),
    'components/Content/BeautyInsider/BeautyInsiderModules/BirthdayGifts/locales/BirthdayGifts_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/BeautyInsider/BeautyInsiderModules/BirthdayGifts/locales/BirthdayGifts_en_US.js'),
    'components/Content/BeautyInsider/BeautyInsiderModules/BirthdayGifts/locales/BirthdayGifts_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/BeautyInsider/BeautyInsiderModules/BirthdayGifts/locales/BirthdayGifts_fr_CA.js'),
    'components/Content/BeautyInsider/BeautyInsiderModules/GetMoreFromMembership/locales/GetMoreFromMembership_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/BeautyInsider/BeautyInsiderModules/GetMoreFromMembership/locales/GetMoreFromMembership_en_US.js'),
    'components/Content/BeautyInsider/BeautyInsiderModules/GetMoreFromMembership/locales/GetMoreFromMembership_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/BeautyInsider/BeautyInsiderModules/GetMoreFromMembership/locales/GetMoreFromMembership_fr_CA.js'),
    'components/Content/BeautyInsider/BeautyInsiderModules/PointMultiplierEvent/locales/PointMultiplierEvent_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/BeautyInsider/BeautyInsiderModules/PointMultiplierEvent/locales/PointMultiplierEvent_en_US.js'),
    'components/Content/BeautyInsider/BeautyInsiderModules/PointMultiplierEvent/locales/PointMultiplierEvent_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/BeautyInsider/BeautyInsiderModules/PointMultiplierEvent/locales/PointMultiplierEvent_fr_CA.js'),
    'components/Content/BeautyInsider/BeautyInsiderModules/PointsActivity/locales/PointsActivity_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/BeautyInsider/BeautyInsiderModules/PointsActivity/locales/PointsActivity_en_US.js'),
    'components/Content/BeautyInsider/BeautyInsiderModules/PointsActivity/locales/PointsActivity_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/BeautyInsider/BeautyInsiderModules/PointsActivity/locales/PointsActivity_fr_CA.js'),
    'components/Content/BeautyInsider/BeautyInsiderModules/PointsForDiscount/locales/PointsForDiscount_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/BeautyInsider/BeautyInsiderModules/PointsForDiscount/locales/PointsForDiscount_en_US.js'),
    'components/Content/BeautyInsider/BeautyInsiderModules/PointsForDiscount/locales/PointsForDiscount_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/BeautyInsider/BeautyInsiderModules/PointsForDiscount/locales/PointsForDiscount_fr_CA.js'),
    'components/Content/BeautyInsider/BeautyInsiderModules/RewardsRedeemed/locales/RewardsRedeemed_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/BeautyInsider/BeautyInsiderModules/RewardsRedeemed/locales/RewardsRedeemed_en_US.js'),
    'components/Content/BeautyInsider/BeautyInsiderModules/RewardsRedeemed/locales/RewardsRedeemed_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/BeautyInsider/BeautyInsiderModules/RewardsRedeemed/locales/RewardsRedeemed_fr_CA.js'),
    'components/Content/BeautyInsider/BeautyInsiderModules/RougeRewards/locales/RougeRewards_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/BeautyInsider/BeautyInsiderModules/RougeRewards/locales/RougeRewards_en_US.js'),
    'components/Content/BeautyInsider/BeautyInsiderModules/RougeRewards/locales/RougeRewards_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/BeautyInsider/BeautyInsiderModules/RougeRewards/locales/RougeRewards_fr_CA.js'),
    'components/Content/BeautyInsider/BeautyInsiderModules/YearAtAGlance/locales/YearAtAGlance_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/BeautyInsider/BeautyInsiderModules/YearAtAGlance/locales/YearAtAGlance_en_US.js'),
    'components/Content/BeautyInsider/BeautyInsiderModules/YearAtAGlance/locales/YearAtAGlance_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/BeautyInsider/BeautyInsiderModules/YearAtAGlance/locales/YearAtAGlance_fr_CA.js'),
    'components/Content/BeautyInsider/BeautyInsiderSummary/locales/BeautyInsiderSummary_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/BeautyInsider/BeautyInsiderSummary/locales/BeautyInsiderSummary_en_US.js'),
    'components/Content/BeautyInsider/BeautyInsiderSummary/locales/BeautyInsiderSummary_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/BeautyInsider/BeautyInsiderSummary/locales/BeautyInsiderSummary_fr_CA.js'),
    'components/Content/BugBounty/locales/BugBounty_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/BugBounty/locales/BugBounty_en_US.js'),
    'components/Content/BugBounty/locales/BugBounty_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/BugBounty/locales/BugBounty_fr_CA.js'),
    'components/Content/ContentLayout/LayoutProductGrid/locales/LayoutProductGrid_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/ContentLayout/LayoutProductGrid/locales/LayoutProductGrid_en_US.js'),
    'components/Content/ContentLayout/LayoutProductGrid/locales/LayoutProductGrid_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/ContentLayout/LayoutProductGrid/locales/LayoutProductGrid_fr_CA.js'),
    'components/Content/ContentLayout/LayoutQuiz/QuizResults/locales/QuizResults_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/ContentLayout/LayoutQuiz/QuizResults/locales/QuizResults_en_US.js'),
    'components/Content/ContentLayout/LayoutQuiz/QuizResults/locales/QuizResults_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/ContentLayout/LayoutQuiz/QuizResults/locales/QuizResults_fr_CA.js'),
    'components/Content/ContentLayout/LayoutRewardsBazaar/locales/LayoutRewardsBazaar_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/ContentLayout/LayoutRewardsBazaar/locales/LayoutRewardsBazaar_en_US.js'),
    'components/Content/ContentLayout/LayoutRewardsBazaar/locales/LayoutRewardsBazaar_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/ContentLayout/LayoutRewardsBazaar/locales/LayoutRewardsBazaar_fr_CA.js'),
    'components/Content/CreditCards/CreditCardApplyForm/locales/CreditCardApplyForm_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/CreditCards/CreditCardApplyForm/locales/CreditCardApplyForm_en_US.js'),
    'components/Content/CreditCards/CreditCardApplyForm/locales/CreditCardApplyForm_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/CreditCards/CreditCardApplyForm/locales/CreditCardApplyForm_fr_CA.js'),
    'components/Content/CreditCards/CreditCardPostApplication/locales/CreditCardPostApplication_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/CreditCards/CreditCardPostApplication/locales/CreditCardPostApplication_en_US.js'),
    'components/Content/CreditCards/CreditCardPostApplication/locales/CreditCardPostApplication_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/CreditCards/CreditCardPostApplication/locales/CreditCardPostApplication_fr_CA.js'),
    'components/Content/CreditCards/CreditCardRewards/locales/CreditCardRewards_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/CreditCards/CreditCardRewards/locales/CreditCardRewards_en_US.js'),
    'components/Content/CreditCards/CreditCardRewards/locales/CreditCardRewards_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/CreditCards/CreditCardRewards/locales/CreditCardRewards_fr_CA.js'),
    'components/Content/CustomerService/CallUs/locales/CallUs_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/CustomerService/CallUs/locales/CallUs_en_US.js'),
    'components/Content/CustomerService/CallUs/locales/CallUs_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/CustomerService/CallUs/locales/CallUs_fr_CA.js'),
    'components/Content/CustomerService/ChatWithUs/locales/ChatWithUs_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/CustomerService/ChatWithUs/locales/ChatWithUs_en_US.js'),
    'components/Content/CustomerService/ChatWithUs/locales/ChatWithUs_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/CustomerService/ChatWithUs/locales/ChatWithUs_fr_CA.js'),
    'components/Content/CustomerService/EmailUs/locales/EmailUs_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/CustomerService/EmailUs/locales/EmailUs_en_US.js'),
    'components/Content/CustomerService/EmailUs/locales/EmailUs_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/CustomerService/EmailUs/locales/EmailUs_fr_CA.js'),
    'components/Content/CustomerService/EmailUs/locales/constants_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/CustomerService/EmailUs/locales/constants_en_US.js'),
    'components/Content/CustomerService/EmailUs/locales/constants_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/CustomerService/EmailUs/locales/constants_fr_CA.js'),
    'components/Content/GameDetails/locales/GameDetails_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/GameDetails/locales/GameDetails_en_US.js'),
    'components/Content/GameDetails/locales/GameDetails_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/GameDetails/locales/GameDetails_fr_CA.js'),
    'components/Content/GiftCards/locales/GiftCards_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/GiftCards/locales/GiftCards_en_US.js'),
    'components/Content/GiftCards/locales/GiftCards_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/GiftCards/locales/GiftCards_fr_CA.js'),
    'components/Content/Happening/ActionButtons/locales/ActionButtons_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/ActionButtons/locales/ActionButtons_en_US.js'),
    'components/Content/Happening/ActionButtons/locales/ActionButtons_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/ActionButtons/locales/ActionButtons_fr_CA.js'),
    'components/Content/Happening/BookingConfirmationDetails/locales/BookingConfirmationDetails_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/BookingConfirmationDetails/locales/BookingConfirmationDetails_en_US.js'),
    'components/Content/Happening/BookingConfirmationDetails/locales/BookingConfirmationDetails_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/BookingConfirmationDetails/locales/BookingConfirmationDetails_fr_CA.js'),
    'components/Content/Happening/EventRSVPConfirmationDetails/locales/EventRSVPConfirmationDetails_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/EventRSVPConfirmationDetails/locales/EventRSVPConfirmationDetails_en_US.js'),
    'components/Content/Happening/EventRSVPConfirmationDetails/locales/EventRSVPConfirmationDetails_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/EventRSVPConfirmationDetails/locales/EventRSVPConfirmationDetails_fr_CA.js'),
    'components/Content/Happening/HappeningEDP/EDPInfo/EDPConfirmRsvpModal/locales/EDPConfirmRsvpModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/HappeningEDP/EDPInfo/EDPConfirmRsvpModal/locales/EDPConfirmRsvpModal_en_US.js'),
    'components/Content/Happening/HappeningEDP/EDPInfo/EDPConfirmRsvpModal/locales/EDPConfirmRsvpModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/HappeningEDP/EDPInfo/EDPConfirmRsvpModal/locales/EDPConfirmRsvpModal_fr_CA.js'),
    'components/Content/Happening/HappeningEDP/EDPInfo/EDPDescription/locales/EDPDescription_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/HappeningEDP/EDPInfo/EDPDescription/locales/EDPDescription_en_US.js'),
    'components/Content/Happening/HappeningEDP/EDPInfo/EDPDescription/locales/EDPDescription_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/HappeningEDP/EDPInfo/EDPDescription/locales/EDPDescription_fr_CA.js'),
    'components/Content/Happening/HappeningEDP/EDPInfo/EDPMedia/EDPMediaCarousel/ThumbnailCarousel/locales/ThumbnailCarousel_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/HappeningEDP/EDPInfo/EDPMedia/EDPMediaCarousel/ThumbnailCarousel/locales/ThumbnailCarousel_en_US.js'),
    'components/Content/Happening/HappeningEDP/EDPInfo/EDPMedia/EDPMediaCarousel/ThumbnailCarousel/locales/ThumbnailCarousel_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/HappeningEDP/EDPInfo/EDPMedia/EDPMediaCarousel/ThumbnailCarousel/locales/ThumbnailCarousel_fr_CA.js'),
    'components/Content/Happening/HappeningEDP/EDPInfo/EDPRsvpModule/locales/EDPRsvpModule_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/HappeningEDP/EDPInfo/EDPRsvpModule/locales/EDPRsvpModule_en_US.js'),
    'components/Content/Happening/HappeningEDP/EDPInfo/EDPRsvpModule/locales/EDPRsvpModule_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/HappeningEDP/EDPInfo/EDPRsvpModule/locales/EDPRsvpModule_fr_CA.js'),
    'components/Content/Happening/HappeningEDP/EDPReservationPolicies/locales/EDPReservationPolicies_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/HappeningEDP/EDPReservationPolicies/locales/EDPReservationPolicies_en_US.js'),
    'components/Content/Happening/HappeningEDP/EDPReservationPolicies/locales/EDPReservationPolicies_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/HappeningEDP/EDPReservationPolicies/locales/EDPReservationPolicies_fr_CA.js'),
    'components/Content/Happening/HappeningEvents/locales/HappeningEvents_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/HappeningEvents/locales/HappeningEvents_en_US.js'),
    'components/Content/Happening/HappeningEvents/locales/HappeningEvents_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/HappeningEvents/locales/HappeningEvents_fr_CA.js'),
    'components/Content/Happening/ReservationDetails/DetailsWrapper/locales/DetailsWrapper_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/ReservationDetails/DetailsWrapper/locales/DetailsWrapper_en_US.js'),
    'components/Content/Happening/ReservationDetails/DetailsWrapper/locales/DetailsWrapper_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/ReservationDetails/DetailsWrapper/locales/DetailsWrapper_fr_CA.js'),
    'components/Content/Happening/ReservationDetails/EventDetails/locales/EventDetails_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/ReservationDetails/EventDetails/locales/EventDetails_en_US.js'),
    'components/Content/Happening/ReservationDetails/EventDetails/locales/EventDetails_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/ReservationDetails/EventDetails/locales/EventDetails_fr_CA.js'),
    'components/Content/Happening/ReservationDetails/PaymentMethodsInfo/locales/PaymentMethodsInfo_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/ReservationDetails/PaymentMethodsInfo/locales/PaymentMethodsInfo_en_US.js'),
    'components/Content/Happening/ReservationDetails/PaymentMethodsInfo/locales/PaymentMethodsInfo_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/ReservationDetails/PaymentMethodsInfo/locales/PaymentMethodsInfo_fr_CA.js'),
    'components/Content/Happening/ReservationDetails/ServiceDetails/locales/ServiceDetails_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/ReservationDetails/ServiceDetails/locales/ServiceDetails_en_US.js'),
    'components/Content/Happening/ReservationDetails/ServiceDetails/locales/ServiceDetails_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/ReservationDetails/ServiceDetails/locales/ServiceDetails_fr_CA.js'),
    'components/Content/Happening/ReservationDetails/locales/ReservationDetails_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/ReservationDetails/locales/ReservationDetails_en_US.js'),
    'components/Content/Happening/ReservationDetails/locales/ReservationDetails_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/ReservationDetails/locales/ReservationDetails_fr_CA.js'),
    'components/Content/Happening/StoreDetails/Chunklets/locales/Chunklets_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/StoreDetails/Chunklets/locales/Chunklets_en_US.js'),
    'components/Content/Happening/StoreDetails/Chunklets/locales/Chunklets_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/StoreDetails/Chunklets/locales/Chunklets_fr_CA.js'),
    'components/Content/Happening/StoreDetails/HoursInformation/locales/HoursInformation_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/StoreDetails/HoursInformation/locales/HoursInformation_en_US.js'),
    'components/Content/Happening/StoreDetails/HoursInformation/locales/HoursInformation_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/StoreDetails/HoursInformation/locales/HoursInformation_fr_CA.js'),
    'components/Content/Happening/StoreDetails/MakeMyStoreConfirmationModal/locales/MakeMyStoreConfirmationModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/StoreDetails/MakeMyStoreConfirmationModal/locales/MakeMyStoreConfirmationModal_en_US.js'),
    'components/Content/Happening/StoreDetails/MakeMyStoreConfirmationModal/locales/MakeMyStoreConfirmationModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/StoreDetails/MakeMyStoreConfirmationModal/locales/MakeMyStoreConfirmationModal_fr_CA.js'),
    'components/Content/Happening/StoreDetails/StoreInformation/locales/StoreInformation_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/StoreDetails/StoreInformation/locales/StoreInformation_en_US.js'),
    'components/Content/Happening/StoreDetails/StoreInformation/locales/StoreInformation_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Happening/StoreDetails/StoreInformation/locales/StoreInformation_fr_CA.js'),
    'components/Content/LovesList/locales/LovesList_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/LovesList/locales/LovesList_en_US.js'),
    'components/Content/LovesList/locales/LovesList_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/LovesList/locales/LovesList_fr_CA.js'),
    'components/Content/MultiProductShadeFinderResults/locales/MultiProductShadeFinderResults_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/MultiProductShadeFinderResults/locales/MultiProductShadeFinderResults_en_US.js'),
    'components/Content/MultiProductShadeFinderResults/locales/MultiProductShadeFinderResults_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/MultiProductShadeFinderResults/locales/MultiProductShadeFinderResults_fr_CA.js'),
    'components/Content/ProductList/locales/ProductList_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/ProductList/locales/ProductList_en_US.js'),
    'components/Content/ProductList/locales/ProductList_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/ProductList/locales/ProductList_fr_CA.js'),
    'components/Content/PromotionList/locales/PromotionList_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/PromotionList/locales/PromotionList_en_US.js'),
    'components/Content/PromotionList/locales/PromotionList_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/PromotionList/locales/PromotionList_fr_CA.js'),
    'components/Content/PromotionListHandler/PromotionListForYouHandler/locales/PromotionListForYouHandler_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/PromotionListHandler/PromotionListForYouHandler/locales/PromotionListForYouHandler_en_US.js'),
    'components/Content/PromotionListHandler/PromotionListForYouHandler/locales/PromotionListForYouHandler_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/PromotionListHandler/PromotionListForYouHandler/locales/PromotionListForYouHandler_fr_CA.js'),
    'components/Content/Recap/RecapBasket/locales/RecapBasket_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Recap/RecapBasket/locales/RecapBasket_en_US.js'),
    'components/Content/Recap/RecapBasket/locales/RecapBasket_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/Recap/RecapBasket/locales/RecapBasket_fr_CA.js'),
    'components/Content/TargetedLandingPromotion/TargetedLandingContent/locales/TargetedLandingContent_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/TargetedLandingPromotion/TargetedLandingContent/locales/TargetedLandingContent_en_US.js'),
    'components/Content/TargetedLandingPromotion/TargetedLandingContent/locales/TargetedLandingContent_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Content/TargetedLandingPromotion/TargetedLandingContent/locales/TargetedLandingContent_fr_CA.js'),
    'components/ContentPage/DropdownMW/locales/DropdownMW_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ContentPage/DropdownMW/locales/DropdownMW_en_US.js'),
    'components/ContentPage/DropdownMW/locales/DropdownMW_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ContentPage/DropdownMW/locales/DropdownMW_fr_CA.js'),
    'components/CountrySwitcher/locales/CountrySwitcher_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CountrySwitcher/locales/CountrySwitcher_fr_CA.js'),
    'components/CreditCard/ApplyFlow/ApplyCreditCardLoves/locales/ApplyCreditCardLoves_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/ApplyFlow/ApplyCreditCardLoves/locales/ApplyCreditCardLoves_en_US.js'),
    'components/CreditCard/ApplyFlow/ApplyCreditCardLoves/locales/ApplyCreditCardLoves_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/ApplyFlow/ApplyCreditCardLoves/locales/ApplyCreditCardLoves_fr_CA.js'),
    'components/CreditCard/ApplyFlow/ApplyFlowResponse/locales/ApplyFlowResponse_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/ApplyFlow/ApplyFlowResponse/locales/ApplyFlowResponse_en_US.js'),
    'components/CreditCard/ApplyFlow/ApplyFlowResponse/locales/ApplyFlowResponse_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/ApplyFlow/ApplyFlowResponse/locales/ApplyFlowResponse_fr_CA.js'),
    'components/CreditCard/ApplyFlow/ContentInformationRules/locales/ContentInformationRules_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/ApplyFlow/ContentInformationRules/locales/ContentInformationRules_en_US.js'),
    'components/CreditCard/ApplyFlow/ContentInformationRules/locales/ContentInformationRules_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/ApplyFlow/ContentInformationRules/locales/ContentInformationRules_fr_CA.js'),
    'components/CreditCard/ApplyFlow/ElectronicConsent/locales/ElectronicConsent_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/ApplyFlow/ElectronicConsent/locales/ElectronicConsent_en_US.js'),
    'components/CreditCard/ApplyFlow/ElectronicConsent/locales/ElectronicConsent_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/ApplyFlow/ElectronicConsent/locales/ElectronicConsent_fr_CA.js'),
    'components/CreditCard/ApplyFlow/OpeningAccount/locales/OpeningAccount_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/ApplyFlow/OpeningAccount/locales/OpeningAccount_en_US.js'),
    'components/CreditCard/ApplyFlow/OpeningAccount/locales/OpeningAccount_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/ApplyFlow/OpeningAccount/locales/OpeningAccount_fr_CA.js'),
    'components/CreditCard/ApplyFlow/PersonalInformation/locales/PersonalInformation_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/ApplyFlow/PersonalInformation/locales/PersonalInformation_en_US.js'),
    'components/CreditCard/ApplyFlow/PersonalInformation/locales/PersonalInformation_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/ApplyFlow/PersonalInformation/locales/PersonalInformation_fr_CA.js'),
    'components/CreditCard/ApplyFlow/SecureInformation/locales/SecureInformation_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/ApplyFlow/SecureInformation/locales/SecureInformation_en_US.js'),
    'components/CreditCard/ApplyFlow/SecureInformation/locales/SecureInformation_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/ApplyFlow/SecureInformation/locales/SecureInformation_fr_CA.js'),
    'components/CreditCard/ApplyFlow/locales/ApplyFlowMain_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/ApplyFlow/locales/ApplyFlowMain_en_US.js'),
    'components/CreditCard/ApplyFlow/locales/ApplyFlowMain_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/ApplyFlow/locales/ApplyFlowMain_fr_CA.js'),
    'components/CreditCard/MarketingPage/locales/MarketingPage_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/MarketingPage/locales/MarketingPage_en_US.js'),
    'components/CreditCard/MarketingPage/locales/MarketingPage_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/MarketingPage/locales/MarketingPage_fr_CA.js'),
    'components/CreditCard/Rewards/ScanRewards/locales/BiCardRewards_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/Rewards/ScanRewards/locales/BiCardRewards_en_US.js'),
    'components/CreditCard/Rewards/ScanRewards/locales/BiCardRewards_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/Rewards/ScanRewards/locales/BiCardRewards_fr_CA.js'),
    'components/CreditCard/Rewards/ScanRewards/locales/CreditCardRewards_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/Rewards/ScanRewards/locales/CreditCardRewards_en_US.js'),
    'components/CreditCard/Rewards/ScanRewards/locales/CreditCardRewards_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/Rewards/ScanRewards/locales/CreditCardRewards_fr_CA.js'),
    'components/CreditCard/Rewards/ScanRewards/locales/ScanRewardButton_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/Rewards/ScanRewards/locales/ScanRewardButton_en_US.js'),
    'components/CreditCard/Rewards/ScanRewards/locales/ScanRewardButton_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/Rewards/ScanRewards/locales/ScanRewardButton_fr_CA.js'),
    'components/CreditCard/Rewards/locales/RewardList_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/Rewards/locales/RewardList_en_US.js'),
    'components/CreditCard/Rewards/locales/RewardList_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/Rewards/locales/RewardList_fr_CA.js'),
    'components/CreditCard/Rewards/locales/Reward_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/Rewards/locales/Reward_en_US.js'),
    'components/CreditCard/Rewards/locales/Reward_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CreditCard/Rewards/locales/Reward_fr_CA.js'),
    'components/CurbsidePickupIndicator/locales/CurbsidePickupIndicator_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CurbsidePickupIndicator/locales/CurbsidePickupIndicator_en_US.js'),
    'components/CurbsidePickupIndicator/locales/CurbsidePickupIndicator_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CurbsidePickupIndicator/locales/CurbsidePickupIndicator_fr_CA.js'),
    'components/CustomerServiceChat/locales/CustomerServiceChat_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CustomerServiceChat/locales/CustomerServiceChat_en_US.js'),
    'components/CustomerServiceChat/locales/CustomerServiceChat_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/CustomerServiceChat/locales/CustomerServiceChat_fr_CA.js'),
    'components/EmailSignUp/locales/EmailSignUp_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/EmailSignUp/locales/EmailSignUp_en_US.js'),
    'components/EmailSignUp/locales/EmailSignUp_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/EmailSignUp/locales/EmailSignUp_fr_CA.js'),
    'components/EmailUs/locales/EmailUs_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/EmailUs/locales/EmailUs_en_US.js'),
    'components/EmailUs/locales/EmailUs_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/EmailUs/locales/EmailUs_fr_CA.js'),
    'components/EmailUs/locales/constants_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/EmailUs/locales/constants_en_US.js'),
    'components/EmailUs/locales/constants_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/EmailUs/locales/constants_fr_CA.js'),
    'components/ErrorPages/locales/Error404_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ErrorPages/locales/Error404_en_US.js'),
    'components/ErrorPages/locales/Error404_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ErrorPages/locales/Error404_fr_CA.js'),
    'components/Filters/LegacyUpperFunnelFilters/locales/UpperFunnelFilters_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Filters/LegacyUpperFunnelFilters/locales/UpperFunnelFilters_fr_CA.js'),
    'components/Footer/locales/Footer_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Footer/locales/Footer_en_US.js'),
    'components/Footer/locales/Footer_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Footer/locales/Footer_fr_CA.js'),
    'components/GAdTag/locales/GAdTag_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GAdTag/locales/GAdTag_en_US.js'),
    'components/GAdTag/locales/GAdTag_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GAdTag/locales/GAdTag_fr_CA.js'),
    'components/GiftCards/locales/GiftCards_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GiftCards/locales/GiftCards_en_US.js'),
    'components/GiftCards/locales/GiftCards_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GiftCards/locales/GiftCards_fr_CA.js'),
    'components/GlobalModals/AccountDeactivatedModal/locales/AccountDeactivatedModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/AccountDeactivatedModal/locales/AccountDeactivatedModal_en_US.js'),
    'components/GlobalModals/AccountDeactivatedModal/locales/AccountDeactivatedModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/AccountDeactivatedModal/locales/AccountDeactivatedModal_fr_CA.js'),
    'components/GlobalModals/AddGiftMessageModal/locales/AddGiftMessageModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/AddGiftMessageModal/locales/AddGiftMessageModal_en_US.js'),
    'components/GlobalModals/AddGiftMessageModal/locales/AddGiftMessageModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/AddGiftMessageModal/locales/AddGiftMessageModal_fr_CA.js'),
    'components/GlobalModals/AddPaymentMethodModal/locales/AddPaymentMethodModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/AddPaymentMethodModal/locales/AddPaymentMethodModal_en_US.js'),
    'components/GlobalModals/AddPaymentMethodModal/locales/AddPaymentMethodModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/AddPaymentMethodModal/locales/AddPaymentMethodModal_fr_CA.js'),
    'components/GlobalModals/AddToBasketModal/locales/AddToBasketModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/AddToBasketModal/locales/AddToBasketModal_en_US.js'),
    'components/GlobalModals/AddToBasketModal/locales/AddToBasketModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/AddToBasketModal/locales/AddToBasketModal_fr_CA.js'),
    'components/GlobalModals/BeautyPreferencesModal/locales/BeautyPreferencesModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/BeautyPreferencesModal/locales/BeautyPreferencesModal_en_US.js'),
    'components/GlobalModals/BeautyPreferencesModal/locales/BeautyPreferencesModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/BeautyPreferencesModal/locales/BeautyPreferencesModal_fr_CA.js'),
    'components/GlobalModals/BiCardModal/locales/BiCardModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/BiCardModal/locales/BiCardModal_en_US.js'),
    'components/GlobalModals/BiCardModal/locales/BiCardModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/BiCardModal/locales/BiCardModal_fr_CA.js'),
    'components/GlobalModals/BiRegisterModal/locales/BiRegisterModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/BiRegisterModal/locales/BiRegisterModal_en_US.js'),
    'components/GlobalModals/BiRegisterModal/locales/BiRegisterModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/BiRegisterModal/locales/BiRegisterModal_fr_CA.js'),
    'components/GlobalModals/BookWidgetModal/locales/BookWidgetModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/BookWidgetModal/locales/BookWidgetModal_en_US.js'),
    'components/GlobalModals/BookWidgetModal/locales/BookWidgetModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/BookWidgetModal/locales/BookWidgetModal_fr_CA.js'),
    'components/GlobalModals/BuyNowPayLaterModal/locales/BuyNowPayLater_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/BuyNowPayLaterModal/locales/BuyNowPayLater_en_US.js'),
    'components/GlobalModals/BuyNowPayLaterModal/locales/BuyNowPayLater_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/BuyNowPayLaterModal/locales/BuyNowPayLater_fr_CA.js'),
    'components/GlobalModals/CancelReservationModal/locales/CancelReservationModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/CancelReservationModal/locales/CancelReservationModal_en_US.js'),
    'components/GlobalModals/CancelReservationModal/locales/CancelReservationModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/CancelReservationModal/locales/CancelReservationModal_fr_CA.js'),
    'components/GlobalModals/CheckPasswordModal/locales/CheckPasswordModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/CheckPasswordModal/locales/CheckPasswordModal_en_US.js'),
    'components/GlobalModals/CheckPasswordModal/locales/CheckPasswordModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/CheckPasswordModal/locales/CheckPasswordModal_fr_CA.js'),
    'components/GlobalModals/CheckYourEmailModal/locales/CheckYourEmailModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/CheckYourEmailModal/locales/CheckYourEmailModal_en_US.js'),
    'components/GlobalModals/CheckYourEmailModal/locales/CheckYourEmailModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/CheckYourEmailModal/locales/CheckYourEmailModal_fr_CA.js'),
    'components/GlobalModals/CloseAccountModal/locales/CloseAccountModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/CloseAccountModal/locales/CloseAccountModal_en_US.js'),
    'components/GlobalModals/CloseAccountModal/locales/CloseAccountModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/CloseAccountModal/locales/CloseAccountModal_fr_CA.js'),
    'components/GlobalModals/CloseAccountSuccessful/locales/CloseAccountSuccessful_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/CloseAccountSuccessful/locales/CloseAccountSuccessful_en_US.js'),
    'components/GlobalModals/CloseAccountSuccessful/locales/CloseAccountSuccessful_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/CloseAccountSuccessful/locales/CloseAccountSuccessful_fr_CA.js'),
    'components/GlobalModals/ColorIQModal/locales/ColorIQModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ColorIQModal/locales/ColorIQModal_en_US.js'),
    'components/GlobalModals/ColorIQModal/locales/ColorIQModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ColorIQModal/locales/ColorIQModal_fr_CA.js'),
    'components/GlobalModals/ConfirmResumeAutoReplenModal/locales/ConfirmResumeAutoReplenModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ConfirmResumeAutoReplenModal/locales/ConfirmResumeAutoReplenModal_en_US.js'),
    'components/GlobalModals/ConfirmResumeAutoReplenModal/locales/ConfirmResumeAutoReplenModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ConfirmResumeAutoReplenModal/locales/ConfirmResumeAutoReplenModal_fr_CA.js'),
    'components/GlobalModals/ConsumerPrivacyModal/locales/ConsumerPrivacyModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ConsumerPrivacyModal/locales/ConsumerPrivacyModal_en_US.js'),
    'components/GlobalModals/ConsumerPrivacyModal/locales/ConsumerPrivacyModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ConsumerPrivacyModal/locales/ConsumerPrivacyModal_fr_CA.js'),
    'components/GlobalModals/CountrySwitcherModal/locales/CountrySwitcherModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/CountrySwitcherModal/locales/CountrySwitcherModal_en_US.js'),
    'components/GlobalModals/CountrySwitcherModal/locales/CountrySwitcherModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/CountrySwitcherModal/locales/CountrySwitcherModal_fr_CA.js'),
    'components/GlobalModals/CreditReportDetailsModal/locales/CreditReportDetailsModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/CreditReportDetailsModal/locales/CreditReportDetailsModal_en_US.js'),
    'components/GlobalModals/CreditReportDetailsModal/locales/CreditReportDetailsModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/CreditReportDetailsModal/locales/CreditReportDetailsModal_fr_CA.js'),
    'components/GlobalModals/DeliveryFrequencyModal/locales/DeliveryFrequencyModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/DeliveryFrequencyModal/locales/DeliveryFrequencyModal_en_US.js'),
    'components/GlobalModals/DeliveryFrequencyModal/locales/DeliveryFrequencyModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/DeliveryFrequencyModal/locales/DeliveryFrequencyModal_fr_CA.js'),
    'components/GlobalModals/DeliveryIssueModal/locales/DeliveryIssueModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/DeliveryIssueModal/locales/DeliveryIssueModal_en_US.js'),
    'components/GlobalModals/DeliveryIssueModal/locales/DeliveryIssueModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/DeliveryIssueModal/locales/DeliveryIssueModal_fr_CA.js'),
    'components/GlobalModals/EDP/GoogleMapModal/locales/GoogleMapModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/EDP/GoogleMapModal/locales/GoogleMapModal_en_US.js'),
    'components/GlobalModals/EDP/GoogleMapModal/locales/GoogleMapModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/EDP/GoogleMapModal/locales/GoogleMapModal_fr_CA.js'),
    'components/GlobalModals/EDP/SignInWithAuthenticateModal/GuestForm/locales/GuestForm_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/EDP/SignInWithAuthenticateModal/GuestForm/locales/GuestForm_en_US.js'),
    'components/GlobalModals/EDP/SignInWithAuthenticateModal/GuestForm/locales/GuestForm_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/EDP/SignInWithAuthenticateModal/GuestForm/locales/GuestForm_fr_CA.js'),
    'components/GlobalModals/EDP/SignInWithAuthenticateModal/PhoneNumberModal/locales/PhoneNumberModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/EDP/SignInWithAuthenticateModal/PhoneNumberModal/locales/PhoneNumberModal_en_US.js'),
    'components/GlobalModals/EDP/SignInWithAuthenticateModal/PhoneNumberModal/locales/PhoneNumberModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/EDP/SignInWithAuthenticateModal/PhoneNumberModal/locales/PhoneNumberModal_fr_CA.js'),
    'components/GlobalModals/EDP/SignInWithAuthenticateModal/locales/SignInWithAuthenticateModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/EDP/SignInWithAuthenticateModal/locales/SignInWithAuthenticateModal_en_US.js'),
    'components/GlobalModals/EDP/SignInWithAuthenticateModal/locales/SignInWithAuthenticateModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/EDP/SignInWithAuthenticateModal/locales/SignInWithAuthenticateModal_fr_CA.js'),
    'components/GlobalModals/EmailLookupModal/locales/EmailLookupModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/EmailLookupModal/locales/EmailLookupModal_en_US.js'),
    'components/GlobalModals/EmailLookupModal/locales/EmailLookupModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/EmailLookupModal/locales/EmailLookupModal_fr_CA.js'),
    'components/GlobalModals/EmailMeWhenInStockModal/locales/EmailMeWhenInStockModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/EmailMeWhenInStockModal/locales/EmailMeWhenInStockModal_en_US.js'),
    'components/GlobalModals/EmailMeWhenInStockModal/locales/EmailMeWhenInStockModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/EmailMeWhenInStockModal/locales/EmailMeWhenInStockModal_fr_CA.js'),
    'components/GlobalModals/FindInStore/FindInStoreAddress/locales/FindInStoreAddress_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/FindInStore/FindInStoreAddress/locales/FindInStoreAddress_en_US.js'),
    'components/GlobalModals/FindInStore/FindInStoreAddress/locales/FindInStoreAddress_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/FindInStore/FindInStoreAddress/locales/FindInStoreAddress_fr_CA.js'),
    'components/GlobalModals/FindInStore/FindInStoreMapModal/locales/FindInStoreMapModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/FindInStore/FindInStoreMapModal/locales/FindInStoreMapModal_en_US.js'),
    'components/GlobalModals/FindInStore/FindInStoreMapModal/locales/FindInStoreMapModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/FindInStore/FindInStoreMapModal/locales/FindInStoreMapModal_fr_CA.js'),
    'components/GlobalModals/FindInStore/FindInStoreModal/locales/FindInStoreModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/FindInStore/FindInStoreModal/locales/FindInStoreModal_en_US.js'),
    'components/GlobalModals/FindInStore/FindInStoreModal/locales/FindInStoreModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/FindInStore/FindInStoreModal/locales/FindInStoreModal_fr_CA.js'),
    'components/GlobalModals/ForgotPasswordModal/locales/ForgotPasswordModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ForgotPasswordModal/locales/ForgotPasswordModal_en_US.js'),
    'components/GlobalModals/ForgotPasswordModal/locales/ForgotPasswordModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ForgotPasswordModal/locales/ForgotPasswordModal_fr_CA.js'),
    'components/GlobalModals/FreeReturnsModal/locale/FreeReturnsModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/FreeReturnsModal/locale/FreeReturnsModal_en_US.js'),
    'components/GlobalModals/FreeReturnsModal/locale/FreeReturnsModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/FreeReturnsModal/locale/FreeReturnsModal_fr_CA.js'),
    'components/GlobalModals/FreeSamplesModal/locales/FreeSamplesModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/FreeSamplesModal/locales/FreeSamplesModal_en_US.js'),
    'components/GlobalModals/FreeSamplesModal/locales/FreeSamplesModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/FreeSamplesModal/locales/FreeSamplesModal_fr_CA.js'),
    'components/GlobalModals/GalleryLightBoxKebabModal/locales/GalleryLightBoxKebabModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/GalleryLightBoxKebabModal/locales/GalleryLightBoxKebabModal_en_US.js'),
    'components/GlobalModals/GalleryLightBoxKebabModal/locales/GalleryLightBoxKebabModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/GalleryLightBoxKebabModal/locales/GalleryLightBoxKebabModal_fr_CA.js'),
    'components/GlobalModals/GetItSoonerAutoReplenModal/locales/GetItSoonerAutoReplenModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/GetItSoonerAutoReplenModal/locales/GetItSoonerAutoReplenModal_en_US.js'),
    'components/GlobalModals/GetItSoonerAutoReplenModal/locales/GetItSoonerAutoReplenModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/GetItSoonerAutoReplenModal/locales/GetItSoonerAutoReplenModal_fr_CA.js'),
    'components/GlobalModals/GiftAddressWarningModal/locale/GiftAddressWarningModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/GiftAddressWarningModal/locale/GiftAddressWarningModal_en_US.js'),
    'components/GlobalModals/GiftAddressWarningModal/locale/GiftAddressWarningModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/GiftAddressWarningModal/locale/GiftAddressWarningModal_fr_CA.js'),
    'components/GlobalModals/LocationAndStoresModal/locales/LocationAndStoresModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/LocationAndStoresModal/locales/LocationAndStoresModal_en_US.js'),
    'components/GlobalModals/LocationAndStoresModal/locales/LocationAndStoresModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/LocationAndStoresModal/locales/LocationAndStoresModal_fr_CA.js'),
    'components/GlobalModals/ManageSubscriptionModal/locales/ManageSubscriptionModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ManageSubscriptionModal/locales/ManageSubscriptionModal_en_US.js'),
    'components/GlobalModals/ManageSubscriptionModal/locales/ManageSubscriptionModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ManageSubscriptionModal/locales/ManageSubscriptionModal_fr_CA.js'),
    'components/GlobalModals/MobileConfirmModal/locales/MobileConfirmModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/MobileConfirmModal/locales/MobileConfirmModal_en_US.js'),
    'components/GlobalModals/MobileConfirmModal/locales/MobileConfirmModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/MobileConfirmModal/locales/MobileConfirmModal_fr_CA.js'),
    'components/GlobalModals/ModifySubscriptionErrorModal/locales/ModifySubscriptionErrorModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ModifySubscriptionErrorModal/locales/ModifySubscriptionErrorModal_en_US.js'),
    'components/GlobalModals/ModifySubscriptionErrorModal/locales/ModifySubscriptionErrorModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ModifySubscriptionErrorModal/locales/ModifySubscriptionErrorModal_fr_CA.js'),
    'components/GlobalModals/MultipleRougeRewardsModal/locales/MultipleRougeRewardsModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/MultipleRougeRewardsModal/locales/MultipleRougeRewardsModal_en_US.js'),
    'components/GlobalModals/MultipleRougeRewardsModal/locales/MultipleRougeRewardsModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/MultipleRougeRewardsModal/locales/MultipleRougeRewardsModal_fr_CA.js'),
    'components/GlobalModals/OrderCancelationModal/locales/OrderCancelationModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/OrderCancelationModal/locales/OrderCancelationModal_en_US.js'),
    'components/GlobalModals/OrderCancelationModal/locales/OrderCancelationModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/OrderCancelationModal/locales/OrderCancelationModal_fr_CA.js'),
    'components/GlobalModals/PaidReservationModal/PaymentSection/PaymentSectionContent/PaymentInfo/BillingAddressForm/locales/BillingAddressForm_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/PaidReservationModal/PaymentSection/PaymentSectionContent/PaymentInfo/BillingAddressForm/locales/BillingAddressForm_en_US.js'),
    'components/GlobalModals/PaidReservationModal/PaymentSection/PaymentSectionContent/PaymentInfo/BillingAddressForm/locales/BillingAddressForm_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/PaidReservationModal/PaymentSection/PaymentSectionContent/PaymentInfo/BillingAddressForm/locales/BillingAddressForm_fr_CA.js'),
    'components/GlobalModals/PaidReservationModal/PaymentSection/PaymentSectionContent/PaymentInfo/CreditCardForm/locales/CreditCardForm_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/PaidReservationModal/PaymentSection/PaymentSectionContent/PaymentInfo/CreditCardForm/locales/CreditCardForm_en_US.js'),
    'components/GlobalModals/PaidReservationModal/PaymentSection/PaymentSectionContent/PaymentInfo/CreditCardForm/locales/CreditCardForm_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/PaidReservationModal/PaymentSection/PaymentSectionContent/PaymentInfo/CreditCardForm/locales/CreditCardForm_fr_CA.js'),
    'components/GlobalModals/PaidReservationModal/PaymentSection/PaymentSectionContent/PaymentInfo/locales/PaymentInfo_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/PaidReservationModal/PaymentSection/PaymentSectionContent/PaymentInfo/locales/PaymentInfo_en_US.js'),
    'components/GlobalModals/PaidReservationModal/PaymentSection/PaymentSectionContent/PaymentInfo/locales/PaymentInfo_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/PaidReservationModal/PaymentSection/PaymentSectionContent/PaymentInfo/locales/PaymentInfo_fr_CA.js'),
    'components/GlobalModals/PaidReservationModal/UserInfoSection/locales/UserInfoSection_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/PaidReservationModal/UserInfoSection/locales/UserInfoSection_en_US.js'),
    'components/GlobalModals/PaidReservationModal/UserInfoSection/locales/UserInfoSection_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/PaidReservationModal/UserInfoSection/locales/UserInfoSection_fr_CA.js'),
    'components/GlobalModals/PaidReservationModal/WaiverSection/locales/WaiverSection_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/PaidReservationModal/WaiverSection/locales/WaiverSection_en_US.js'),
    'components/GlobalModals/PaidReservationModal/WaiverSection/locales/WaiverSection_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/PaidReservationModal/WaiverSection/locales/WaiverSection_fr_CA.js'),
    'components/GlobalModals/PaidReservationModal/locales/PaidReservationModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/PaidReservationModal/locales/PaidReservationModal_en_US.js'),
    'components/GlobalModals/PaidReservationModal/locales/PaidReservationModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/PaidReservationModal/locales/PaidReservationModal_fr_CA.js'),
    'components/GlobalModals/PauseAutoReplenModal/locales/PauseAutoReplenModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/PauseAutoReplenModal/locales/PauseAutoReplenModal_en_US.js'),
    'components/GlobalModals/PauseAutoReplenModal/locales/PauseAutoReplenModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/PauseAutoReplenModal/locales/PauseAutoReplenModal_fr_CA.js'),
    'components/GlobalModals/ProductSamplesModal/Minidrawer/locales/Minidrawer_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ProductSamplesModal/Minidrawer/locales/Minidrawer_en_US.js'),
    'components/GlobalModals/ProductSamplesModal/Minidrawer/locales/Minidrawer_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ProductSamplesModal/Minidrawer/locales/Minidrawer_fr_CA.js'),
    'components/GlobalModals/ProductSamplesModal/locales/ProductSamplesModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ProductSamplesModal/locales/ProductSamplesModal_en_US.js'),
    'components/GlobalModals/ProductSamplesModal/locales/ProductSamplesModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ProductSamplesModal/locales/ProductSamplesModal_fr_CA.js'),
    'components/GlobalModals/QuickLookModal/ProductQuickLookModal/CallToActions/locales/CallToActions_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/QuickLookModal/ProductQuickLookModal/CallToActions/locales/CallToActions_en_US.js'),
    'components/GlobalModals/QuickLookModal/ProductQuickLookModal/CallToActions/locales/CallToActions_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/QuickLookModal/ProductQuickLookModal/CallToActions/locales/CallToActions_fr_CA.js'),
    'components/GlobalModals/QuickLookModal/ProductQuickLookModal/CallToActions/locales/Reward_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/QuickLookModal/ProductQuickLookModal/CallToActions/locales/Reward_en_US.js'),
    'components/GlobalModals/QuickLookModal/ProductQuickLookModal/CallToActions/locales/Reward_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/QuickLookModal/ProductQuickLookModal/CallToActions/locales/Reward_fr_CA.js'),
    'components/GlobalModals/QuickLookModal/ProductQuickLookModal/ProductQuickLookMessage/locales/ProductQuickLookMessage_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/QuickLookModal/ProductQuickLookModal/ProductQuickLookMessage/locales/ProductQuickLookMessage_en_US.js'),
    'components/GlobalModals/QuickLookModal/ProductQuickLookModal/ProductQuickLookMessage/locales/ProductQuickLookMessage_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/QuickLookModal/ProductQuickLookModal/ProductQuickLookMessage/locales/ProductQuickLookMessage_fr_CA.js'),
    'components/GlobalModals/QuickLookModal/ProductQuickLookModal/locales/ProductQuickLookModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/QuickLookModal/ProductQuickLookModal/locales/ProductQuickLookModal_en_US.js'),
    'components/GlobalModals/QuickLookModal/ProductQuickLookModal/locales/ProductQuickLookModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/QuickLookModal/ProductQuickLookModal/locales/ProductQuickLookModal_fr_CA.js'),
    'components/GlobalModals/QuickLookModal/RewardSampleQuickLookModal/locales/RewardSampleQuickLookModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/QuickLookModal/RewardSampleQuickLookModal/locales/RewardSampleQuickLookModal_en_US.js'),
    'components/GlobalModals/QuickLookModal/RewardSampleQuickLookModal/locales/RewardSampleQuickLookModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/QuickLookModal/RewardSampleQuickLookModal/locales/RewardSampleQuickLookModal_fr_CA.js'),
    'components/GlobalModals/RegisterModal/RegisterForm/locales/RegisterForm_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/RegisterModal/RegisterForm/locales/RegisterForm_en_US.js'),
    'components/GlobalModals/RegisterModal/RegisterForm/locales/RegisterForm_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/RegisterModal/RegisterForm/locales/RegisterForm_fr_CA.js'),
    'components/GlobalModals/RegisterModal/locales/RegisterModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/RegisterModal/locales/RegisterModal_en_US.js'),
    'components/GlobalModals/RegisterModal/locales/RegisterModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/RegisterModal/locales/RegisterModal_fr_CA.js'),
    'components/GlobalModals/RemoveGiftMessageModal/locales/RemoveGiftMessageModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/RemoveGiftMessageModal/locales/RemoveGiftMessageModal_en_US.js'),
    'components/GlobalModals/RemoveGiftMessageModal/locales/RemoveGiftMessageModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/RemoveGiftMessageModal/locales/RemoveGiftMessageModal_fr_CA.js'),
    'components/GlobalModals/ReportContentModal/locales/ReportContentModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ReportContentModal/locales/ReportContentModal_en_US.js'),
    'components/GlobalModals/ReportContentModal/locales/ReportContentModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ReportContentModal/locales/ReportContentModal_fr_CA.js'),
    'components/GlobalModals/ReserveAndPickUpModal/locales/ReserveAndPickUpModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ReserveAndPickUpModal/locales/ReserveAndPickUpModal_en_US.js'),
    'components/GlobalModals/ReserveAndPickUpModal/locales/ReserveAndPickUpModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ReserveAndPickUpModal/locales/ReserveAndPickUpModal_fr_CA.js'),
    'components/GlobalModals/ReserveAndPickUpModal/locales/StoresList_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ReserveAndPickUpModal/locales/StoresList_en_US.js'),
    'components/GlobalModals/ReserveAndPickUpModal/locales/StoresList_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ReserveAndPickUpModal/locales/StoresList_fr_CA.js'),
    'components/GlobalModals/ResetPasswordConfirmationModal/locales/ResetPasswordConfirmationModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ResetPasswordConfirmationModal/locales/ResetPasswordConfirmationModal_en_US.js'),
    'components/GlobalModals/ResetPasswordConfirmationModal/locales/ResetPasswordConfirmationModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ResetPasswordConfirmationModal/locales/ResetPasswordConfirmationModal_fr_CA.js'),
    'components/GlobalModals/ResumeSubscriptionModal/locales/ResumeSubscriptionModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ResumeSubscriptionModal/locales/ResumeSubscriptionModal_en_US.js'),
    'components/GlobalModals/ResumeSubscriptionModal/locales/ResumeSubscriptionModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ResumeSubscriptionModal/locales/ResumeSubscriptionModal_fr_CA.js'),
    'components/GlobalModals/RewardModal/locales/RewardModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/RewardModal/locales/RewardModal_en_US.js'),
    'components/GlobalModals/RewardModal/locales/RewardModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/RewardModal/locales/RewardModal_fr_CA.js'),
    'components/GlobalModals/RewardsBazaarModal/locales/RewardsBazaarModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/RewardsBazaarModal/locales/RewardsBazaarModal_en_US.js'),
    'components/GlobalModals/RewardsBazaarModal/locales/RewardsBazaarModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/RewardsBazaarModal/locales/RewardsBazaarModal_fr_CA.js'),
    'components/GlobalModals/RougeRewardCardModal/locales/RougeRewardCardButtons_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/RougeRewardCardModal/locales/RougeRewardCardButtons_en_US.js'),
    'components/GlobalModals/RougeRewardCardModal/locales/RougeRewardCardButtons_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/RougeRewardCardModal/locales/RougeRewardCardButtons_fr_CA.js'),
    'components/GlobalModals/RougeRewardCardModal/locales/RougeRewardCardModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/RougeRewardCardModal/locales/RougeRewardCardModal_en_US.js'),
    'components/GlobalModals/RougeRewardCardModal/locales/RougeRewardCardModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/RougeRewardCardModal/locales/RougeRewardCardModal_fr_CA.js'),
    'components/GlobalModals/SDULandingPageModal/locales/SDULandingPageModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SDULandingPageModal/locales/SDULandingPageModal_en_US.js'),
    'components/GlobalModals/SDULandingPageModal/locales/SDULandingPageModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SDULandingPageModal/locales/SDULandingPageModal_fr_CA.js'),
    'components/GlobalModals/SMSSignInModal/locales/SMSSignInModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SMSSignInModal/locales/SMSSignInModal_en_US.js'),
    'components/GlobalModals/SMSSignInModal/locales/SMSSignInModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SMSSignInModal/locales/SMSSignInModal_fr_CA.js'),
    'components/GlobalModals/SMSSignupModal/locales/SMSSignupModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SMSSignupModal/locales/SMSSignupModal_en_US.js'),
    'components/GlobalModals/SMSSignupModal/locales/SMSSignupModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SMSSignupModal/locales/SMSSignupModal_fr_CA.js'),
    'components/GlobalModals/SampleModal/locales/SampleModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SampleModal/locales/SampleModal_en_US.js'),
    'components/GlobalModals/SampleModal/locales/SampleModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SampleModal/locales/SampleModal_fr_CA.js'),
    'components/GlobalModals/ScanRewardCardModal/locales/ScanRewardCardModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ScanRewardCardModal/locales/ScanRewardCardModal_en_US.js'),
    'components/GlobalModals/ScanRewardCardModal/locales/ScanRewardCardModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ScanRewardCardModal/locales/ScanRewardCardModal_fr_CA.js'),
    'components/GlobalModals/ShareLinkModal/locales/ShareLinkModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ShareLinkModal/locales/ShareLinkModal_en_US.js'),
    'components/GlobalModals/ShareLinkModal/locales/ShareLinkModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ShareLinkModal/locales/ShareLinkModal_fr_CA.js'),
    'components/GlobalModals/ShippingDeliveryLocationModal/locales/ShippingDeliveryLocationModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ShippingDeliveryLocationModal/locales/ShippingDeliveryLocationModal_en_US.js'),
    'components/GlobalModals/ShippingDeliveryLocationModal/locales/ShippingDeliveryLocationModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/ShippingDeliveryLocationModal/locales/ShippingDeliveryLocationModal_fr_CA.js'),
    'components/GlobalModals/SignInModal/SignInForm/locales/SignInForm_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SignInModal/SignInForm/locales/SignInForm_en_US.js'),
    'components/GlobalModals/SignInModal/SignInForm/locales/SignInForm_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SignInModal/SignInForm/locales/SignInForm_fr_CA.js'),
    'components/GlobalModals/SignInModal/SignInFormFooter/locales/SignInFormFooter_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SignInModal/SignInFormFooter/locales/SignInFormFooter_en_US.js'),
    'components/GlobalModals/SignInModal/SignInFormFooter/locales/SignInFormFooter_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SignInModal/SignInFormFooter/locales/SignInFormFooter_fr_CA.js'),
    'components/GlobalModals/SignInModal/SignInFormTerms/locales/SignInFormTerms_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SignInModal/SignInFormTerms/locales/SignInFormTerms_en_US.js'),
    'components/GlobalModals/SignInModal/SignInFormTerms/locales/SignInFormTerms_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SignInModal/SignInFormTerms/locales/SignInFormTerms_fr_CA.js'),
    'components/GlobalModals/SignInModal/locales/SignInModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SignInModal/locales/SignInModal_en_US.js'),
    'components/GlobalModals/SignInModal/locales/SignInModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SignInModal/locales/SignInModal_fr_CA.js'),
    'components/GlobalModals/SignInWithMessagingModal/CreditCardApplyMessaging/locales/CreditCardApplyMessaging_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SignInWithMessagingModal/CreditCardApplyMessaging/locales/CreditCardApplyMessaging_en_US.js'),
    'components/GlobalModals/SignInWithMessagingModal/CreditCardApplyMessaging/locales/CreditCardApplyMessaging_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SignInWithMessagingModal/CreditCardApplyMessaging/locales/CreditCardApplyMessaging_fr_CA.js'),
    'components/GlobalModals/SignInWithMessagingModal/GuestCheckoutMessaging/locales/GuestCheckoutMessaging_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SignInWithMessagingModal/GuestCheckoutMessaging/locales/GuestCheckoutMessaging_en_US.js'),
    'components/GlobalModals/SignInWithMessagingModal/GuestCheckoutMessaging/locales/GuestCheckoutMessaging_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SignInWithMessagingModal/GuestCheckoutMessaging/locales/GuestCheckoutMessaging_fr_CA.js'),
    'components/GlobalModals/SignInWithMessagingModal/locales/SignInWithMessagingModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SignInWithMessagingModal/locales/SignInWithMessagingModal_en_US.js'),
    'components/GlobalModals/SignInWithMessagingModal/locales/SignInWithMessagingModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SignInWithMessagingModal/locales/SignInWithMessagingModal_fr_CA.js'),
    'components/GlobalModals/SimilarProductsModal/locales/SimilarProductsModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SimilarProductsModal/locales/SimilarProductsModal_en_US.js'),
    'components/GlobalModals/SimilarProductsModal/locales/SimilarProductsModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SimilarProductsModal/locales/SimilarProductsModal_fr_CA.js'),
    'components/GlobalModals/SkipAutoReplenModal/locales/SkipAutoReplenModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SkipAutoReplenModal/locales/SkipAutoReplenModal_en_US.js'),
    'components/GlobalModals/SkipAutoReplenModal/locales/SkipAutoReplenModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SkipAutoReplenModal/locales/SkipAutoReplenModal_fr_CA.js'),
    'components/GlobalModals/SocialReOptModal/locales/SocialReOptModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SocialReOptModal/locales/SocialReOptModal_en_US.js'),
    'components/GlobalModals/SocialReOptModal/locales/SocialReOptModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SocialReOptModal/locales/SocialReOptModal_fr_CA.js'),
    'components/GlobalModals/SocialRegistrationModal/locales/SocialRegistrationModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SocialRegistrationModal/locales/SocialRegistrationModal_en_US.js'),
    'components/GlobalModals/SocialRegistrationModal/locales/SocialRegistrationModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SocialRegistrationModal/locales/SocialRegistrationModal_fr_CA.js'),
    'components/GlobalModals/SubscriptionUpdatePaymentModal/locales/SubscriptionUpdatePaymentModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SubscriptionUpdatePaymentModal/locales/SubscriptionUpdatePaymentModal_en_US.js'),
    'components/GlobalModals/SubscriptionUpdatePaymentModal/locales/SubscriptionUpdatePaymentModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/SubscriptionUpdatePaymentModal/locales/SubscriptionUpdatePaymentModal_fr_CA.js'),
    'components/GlobalModals/UnsubscribeAutoReplenModal/locales/UnsubscribeAutoReplenModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/UnsubscribeAutoReplenModal/locales/UnsubscribeAutoReplenModal_en_US.js'),
    'components/GlobalModals/UnsubscribeAutoReplenModal/locales/UnsubscribeAutoReplenModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/UnsubscribeAutoReplenModal/locales/UnsubscribeAutoReplenModal_fr_CA.js'),
    'components/GlobalModals/UserDoesNotQualifyModal/locales/UserDoesNotQualifyModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/UserDoesNotQualifyModal/locales/UserDoesNotQualifyModal_fr_CA.js'),
    'components/GlobalModals/WelcomeMatModal/locales/WelcomeMatModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/WelcomeMatModal/locales/WelcomeMatModal_en_US.js'),
    'components/GlobalModals/WelcomeMatModal/locales/WelcomeMatModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/WelcomeMatModal/locales/WelcomeMatModal_fr_CA.js'),
    'components/GlobalModals/locales/modals_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/locales/modals_en_US.js'),
    'components/GlobalModals/locales/modals_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GlobalModals/locales/modals_fr_CA.js'),
    'components/GoogleMap/InfoWindow/locales/InfoWindow_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GoogleMap/InfoWindow/locales/InfoWindow_en_US.js'),
    'components/GoogleMap/InfoWindow/locales/InfoWindow_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GoogleMap/InfoWindow/locales/InfoWindow_fr_CA.js'),
    'components/GreetingText/locales/GreetingText_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GreetingText/locales/GreetingText_en_US.js'),
    'components/GreetingText/locales/GreetingText_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/GreetingText/locales/GreetingText_fr_CA.js'),
    'components/HappeningNonContent/Reservations/EmptyReservations/locales/EmptyReservations_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/Reservations/EmptyReservations/locales/EmptyReservations_en_US.js'),
    'components/HappeningNonContent/Reservations/EmptyReservations/locales/EmptyReservations_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/Reservations/EmptyReservations/locales/EmptyReservations_fr_CA.js'),
    'components/HappeningNonContent/Reservations/ReservationCard/locales/ReservationCard_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/Reservations/ReservationCard/locales/ReservationCard_en_US.js'),
    'components/HappeningNonContent/Reservations/ReservationCard/locales/ReservationCard_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/Reservations/ReservationCard/locales/ReservationCard_fr_CA.js'),
    'components/HappeningNonContent/Reservations/ReservationLogin/locales/ReservationLogin_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/Reservations/ReservationLogin/locales/ReservationLogin_en_US.js'),
    'components/HappeningNonContent/Reservations/ReservationLogin/locales/ReservationLogin_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/Reservations/ReservationLogin/locales/ReservationLogin_fr_CA.js'),
    'components/HappeningNonContent/Reservations/locales/Reservations_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/Reservations/locales/Reservations_en_US.js'),
    'components/HappeningNonContent/Reservations/locales/Reservations_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/Reservations/locales/Reservations_fr_CA.js'),
    'components/HappeningNonContent/ServiceBooking/BookingInfo/locales/BookingInfo_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/ServiceBooking/BookingInfo/locales/BookingInfo_en_US.js'),
    'components/HappeningNonContent/ServiceBooking/BookingInfo/locales/BookingInfo_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/ServiceBooking/BookingInfo/locales/BookingInfo_fr_CA.js'),
    'components/HappeningNonContent/ServiceBooking/PickArtistDateTime/locales/PickArtistDateTime_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/ServiceBooking/PickArtistDateTime/locales/PickArtistDateTime_en_US.js'),
    'components/HappeningNonContent/ServiceBooking/PickArtistDateTime/locales/PickArtistDateTime_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/ServiceBooking/PickArtistDateTime/locales/PickArtistDateTime_fr_CA.js'),
    'components/HappeningNonContent/ServiceBooking/PickStore/locales/PickStore_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/ServiceBooking/PickStore/locales/PickStore_en_US.js'),
    'components/HappeningNonContent/ServiceBooking/PickStore/locales/PickStore_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/ServiceBooking/PickStore/locales/PickStore_fr_CA.js'),
    'components/HappeningNonContent/ServiceBooking/ReviewAndPay/PaymentInfo/AddCardHeader/locales/AddCardHeader_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/ServiceBooking/ReviewAndPay/PaymentInfo/AddCardHeader/locales/AddCardHeader_en_US.js'),
    'components/HappeningNonContent/ServiceBooking/ReviewAndPay/PaymentInfo/AddCardHeader/locales/AddCardHeader_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/ServiceBooking/ReviewAndPay/PaymentInfo/AddCardHeader/locales/AddCardHeader_fr_CA.js'),
    'components/HappeningNonContent/ServiceBooking/ReviewAndPay/PaymentInfo/CreditCard/locales/CreditCard_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/ServiceBooking/ReviewAndPay/PaymentInfo/CreditCard/locales/CreditCard_en_US.js'),
    'components/HappeningNonContent/ServiceBooking/ReviewAndPay/PaymentInfo/CreditCard/locales/CreditCard_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/ServiceBooking/ReviewAndPay/PaymentInfo/CreditCard/locales/CreditCard_fr_CA.js'),
    'components/HappeningNonContent/ServiceBooking/ReviewAndPay/PaymentInfo/CreditCardForm/AddressForm/locales/AddressForm_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/ServiceBooking/ReviewAndPay/PaymentInfo/CreditCardForm/AddressForm/locales/AddressForm_en_US.js'),
    'components/HappeningNonContent/ServiceBooking/ReviewAndPay/PaymentInfo/CreditCardForm/AddressForm/locales/AddressForm_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/ServiceBooking/ReviewAndPay/PaymentInfo/CreditCardForm/AddressForm/locales/AddressForm_fr_CA.js'),
    'components/HappeningNonContent/ServiceBooking/ReviewAndPay/PaymentInfo/CreditCardForm/locales/CreditCardForm_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/ServiceBooking/ReviewAndPay/PaymentInfo/CreditCardForm/locales/CreditCardForm_en_US.js'),
    'components/HappeningNonContent/ServiceBooking/ReviewAndPay/PaymentInfo/CreditCardForm/locales/CreditCardForm_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/ServiceBooking/ReviewAndPay/PaymentInfo/CreditCardForm/locales/CreditCardForm_fr_CA.js'),
    'components/HappeningNonContent/ServiceBooking/ReviewAndPay/PaymentInfo/CreditCardList/locales/CreditCardList_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/ServiceBooking/ReviewAndPay/PaymentInfo/CreditCardList/locales/CreditCardList_en_US.js'),
    'components/HappeningNonContent/ServiceBooking/ReviewAndPay/PaymentInfo/CreditCardList/locales/CreditCardList_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/ServiceBooking/ReviewAndPay/PaymentInfo/CreditCardList/locales/CreditCardList_fr_CA.js'),
    'components/HappeningNonContent/ServiceBooking/ReviewAndPay/PaymentInfo/locales/PaymentInfo_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/ServiceBooking/ReviewAndPay/PaymentInfo/locales/PaymentInfo_en_US.js'),
    'components/HappeningNonContent/ServiceBooking/ReviewAndPay/PaymentInfo/locales/PaymentInfo_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/ServiceBooking/ReviewAndPay/PaymentInfo/locales/PaymentInfo_fr_CA.js'),
    'components/HappeningNonContent/ServiceBooking/ReviewAndPay/ReviewAndPayInfo/locales/ReviewAndPayInfo_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/ServiceBooking/ReviewAndPay/ReviewAndPayInfo/locales/ReviewAndPayInfo_en_US.js'),
    'components/HappeningNonContent/ServiceBooking/ReviewAndPay/ReviewAndPayInfo/locales/ReviewAndPayInfo_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/ServiceBooking/ReviewAndPay/ReviewAndPayInfo/locales/ReviewAndPayInfo_fr_CA.js'),
    'components/HappeningNonContent/ServiceBooking/ReviewAndPay/ReviewAndPayInfoBottom/locales/ReviewAndPayInfoBottom_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/ServiceBooking/ReviewAndPay/ReviewAndPayInfoBottom/locales/ReviewAndPayInfoBottom_en_US.js'),
    'components/HappeningNonContent/ServiceBooking/ReviewAndPay/ReviewAndPayInfoBottom/locales/ReviewAndPayInfoBottom_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/ServiceBooking/ReviewAndPay/ReviewAndPayInfoBottom/locales/ReviewAndPayInfoBottom_fr_CA.js'),
    'components/HappeningNonContent/ServiceBooking/ReviewAndPay/locales/ReviewAndPay_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/ServiceBooking/ReviewAndPay/locales/ReviewAndPay_en_US.js'),
    'components/HappeningNonContent/ServiceBooking/ReviewAndPay/locales/ReviewAndPay_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/HappeningNonContent/ServiceBooking/ReviewAndPay/locales/ReviewAndPay_fr_CA.js'),
    'components/Header/AccountGreeting/locales/AccountGreeting_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/AccountGreeting/locales/AccountGreeting_en_US.js'),
    'components/Header/AccountGreeting/locales/AccountGreeting_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/AccountGreeting/locales/AccountGreeting_fr_CA.js'),
    'components/Header/AccountMenu/locales/AccountMenu_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/AccountMenu/locales/AccountMenu_en_US.js'),
    'components/Header/AccountMenu/locales/AccountMenu_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/AccountMenu/locales/AccountMenu_fr_CA.js'),
    'components/Header/BeautyPreferences/BeautyProfileHeading/locales/BeautyProfileHeading_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/BeautyPreferences/BeautyProfileHeading/locales/BeautyProfileHeading_en_US.js'),
    'components/Header/BeautyPreferences/BeautyProfileHeading/locales/BeautyProfileHeading_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/BeautyPreferences/BeautyProfileHeading/locales/BeautyProfileHeading_fr_CA.js'),
    'components/Header/BeautyPreferences/ColorIQHistory/locales/ColorIQHistory_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/BeautyPreferences/ColorIQHistory/locales/ColorIQHistory_en_US.js'),
    'components/Header/BeautyPreferences/ColorIQHistory/locales/ColorIQHistory_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/BeautyPreferences/ColorIQHistory/locales/ColorIQHistory_fr_CA.js'),
    'components/Header/BeautyPreferences/NotSureOrPreference/locales/NotSureOrPreference_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/BeautyPreferences/NotSureOrPreference/locales/NotSureOrPreference_en_US.js'),
    'components/Header/BeautyPreferences/NotSureOrPreference/locales/NotSureOrPreference_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/BeautyPreferences/NotSureOrPreference/locales/NotSureOrPreference_fr_CA.js'),
    'components/Header/BeautyPreferences/PersonalizedPicks/locales/PersonalizedPicks_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/BeautyPreferences/PersonalizedPicks/locales/PersonalizedPicks_en_US.js'),
    'components/Header/BeautyPreferences/PersonalizedPicks/locales/PersonalizedPicks_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/BeautyPreferences/PersonalizedPicks/locales/PersonalizedPicks_fr_CA.js'),
    'components/Header/BeautyPreferences/PreferencesModal/locales/PreferencesModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/BeautyPreferences/PreferencesModal/locales/PreferencesModal_en_US.js'),
    'components/Header/BeautyPreferences/PreferencesModal/locales/PreferencesModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/BeautyPreferences/PreferencesModal/locales/PreferencesModal_fr_CA.js'),
    'components/Header/BeautyPreferences/RedirectProfileContent/locales/RedirectProfileContent_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/BeautyPreferences/RedirectProfileContent/locales/RedirectProfileContent_en_US.js'),
    'components/Header/BeautyPreferences/RedirectProfileContent/locales/RedirectProfileContent_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/BeautyPreferences/RedirectProfileContent/locales/RedirectProfileContent_fr_CA.js'),
    'components/Header/BeautyPreferences/SearchableProfileContent/locales/SearchableProfileContent_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/BeautyPreferences/SearchableProfileContent/locales/SearchableProfileContent_en_US.js'),
    'components/Header/BeautyPreferences/SearchableProfileContent/locales/SearchableProfileContent_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/BeautyPreferences/SearchableProfileContent/locales/SearchableProfileContent_fr_CA.js'),
    'components/Header/BeautyPreferences/ShoppingLinks/locales/ShoppingLinks_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/BeautyPreferences/ShoppingLinks/locales/ShoppingLinks_en_US.js'),
    'components/Header/BeautyPreferences/ShoppingLinks/locales/ShoppingLinks_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/BeautyPreferences/ShoppingLinks/locales/ShoppingLinks_fr_CA.js'),
    'components/Header/BeautyPreferences/TiledProfileContent/locales/TiledProfileContent_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/BeautyPreferences/TiledProfileContent/locales/TiledProfileContent_en_US.js'),
    'components/Header/BeautyPreferences/TiledProfileContent/locales/TiledProfileContent_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/BeautyPreferences/TiledProfileContent/locales/TiledProfileContent_fr_CA.js'),
    'components/Header/BeautyPreferences/TooltipModal/locales/TooltipModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/BeautyPreferences/TooltipModal/locales/TooltipModal_en_US.js'),
    'components/Header/BeautyPreferences/TooltipModal/locales/TooltipModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/BeautyPreferences/TooltipModal/locales/TooltipModal_fr_CA.js'),
    'components/Header/BeautyPreferences/locales/BeautyPreferences_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/BeautyPreferences/locales/BeautyPreferences_en_US.js'),
    'components/Header/BeautyPreferences/locales/BeautyPreferences_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/BeautyPreferences/locales/BeautyPreferences_fr_CA.js'),
    'components/Header/CommunityContent/locales/CommunityContent_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/CommunityContent/locales/CommunityContent_en_US.js'),
    'components/Header/CommunityContent/locales/CommunityContent_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/CommunityContent/locales/CommunityContent_fr_CA.js'),
    'components/Header/InlineLoves/locales/InlineLoves_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/InlineLoves/locales/InlineLoves_en_US.js'),
    'components/Header/InlineLoves/locales/InlineLoves_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/InlineLoves/locales/InlineLoves_fr_CA.js'),
    'components/Header/PersistentBanner/locales/PersistentBanner_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/PersistentBanner/locales/PersistentBanner_en_US.js'),
    'components/Header/PersistentBanner/locales/PersistentBanner_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/PersistentBanner/locales/PersistentBanner_fr_CA.js'),
    'components/Header/ShopModal/locales/ShopModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/ShopModal/locales/ShopModal_en_US.js'),
    'components/Header/ShopModal/locales/ShopModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/ShopModal/locales/ShopModal_fr_CA.js'),
    'components/Header/StoresContent/locales/StoresContent_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/StoresContent/locales/StoresContent_en_US.js'),
    'components/Header/StoresContent/locales/StoresContent_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/StoresContent/locales/StoresContent_fr_CA.js'),
    'components/Header/locales/Header_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/locales/Header_en_US.js'),
    'components/Header/locales/Header_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Header/locales/Header_fr_CA.js'),
    'components/Homepage/ChicletNavExtended/locales/ChicletNavExtended_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Homepage/ChicletNavExtended/locales/ChicletNavExtended_en_US.js'),
    'components/Homepage/ChicletNavExtended/locales/ChicletNavExtended_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Homepage/ChicletNavExtended/locales/ChicletNavExtended_fr_CA.js'),
    'components/Homepage/SignInBanner/locales/SignInBanner_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Homepage/SignInBanner/locales/SignInBanner_en_US.js'),
    'components/Homepage/SignInBanner/locales/SignInBanner_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Homepage/SignInBanner/locales/SignInBanner_fr_CA.js'),
    'components/InlineBasket/BasketDesktop/locales/BasketDesktop_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/InlineBasket/BasketDesktop/locales/BasketDesktop_en_US.js'),
    'components/InlineBasket/BasketDesktop/locales/BasketDesktop_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/InlineBasket/BasketDesktop/locales/BasketDesktop_fr_CA.js'),
    'components/InlineBasket/BasketDesktop/locales/BasketItem_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/InlineBasket/BasketDesktop/locales/BasketItem_en_US.js'),
    'components/InlineBasket/BasketDesktop/locales/BasketItem_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/InlineBasket/BasketDesktop/locales/BasketItem_fr_CA.js'),
    'components/InlineBasket/BasketMobile/locales/BasketMobile_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/InlineBasket/BasketMobile/locales/BasketMobile_en_US.js'),
    'components/InlineBasket/BasketMobile/locales/BasketMobile_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/InlineBasket/BasketMobile/locales/BasketMobile_fr_CA.js'),
    'components/InlineBasket/BasketMsg/locales/BasketMsg_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/InlineBasket/BasketMsg/locales/BasketMsg_fr_CA.js'),
    'components/InlineBasket/locales/InlineBasket_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/InlineBasket/locales/InlineBasket_en_US.js'),
    'components/InlineBasket/locales/InlineBasket_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/InlineBasket/locales/InlineBasket_fr_CA.js'),
    'components/Inputs/InputZip/locales/InputZip_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Inputs/InputZip/locales/InputZip_en_US.js'),
    'components/Inputs/InputZip/locales/InputZip_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Inputs/InputZip/locales/InputZip_fr_CA.js'),
    'components/Inputs/PasswordRevealInput/locales/PasswordRevealInput_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Inputs/PasswordRevealInput/locales/PasswordRevealInput_en_US.js'),
    'components/Inputs/PasswordRevealInput/locales/PasswordRevealInput_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Inputs/PasswordRevealInput/locales/PasswordRevealInput_fr_CA.js'),
    'components/Inputs/Textarea/locales/Textarea_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Inputs/Textarea/locales/Textarea_en_US.js'),
    'components/Inputs/Textarea/locales/Textarea_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Inputs/Textarea/locales/Textarea_fr_CA.js'),
    'components/Invoice/InvoiceDetails/locales/InvoiceDetails_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Invoice/InvoiceDetails/locales/InvoiceDetails_en_US.js'),
    'components/Invoice/InvoiceDetails/locales/InvoiceDetails_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Invoice/InvoiceDetails/locales/InvoiceDetails_fr_CA.js'),
    'components/Invoice/InvoiceNoLongerAvailable/locales/InvoiceNoLongerAvailable_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Invoice/InvoiceNoLongerAvailable/locales/InvoiceNoLongerAvailable_en_US.js'),
    'components/Invoice/InvoiceNoLongerAvailable/locales/InvoiceNoLongerAvailable_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Invoice/InvoiceNoLongerAvailable/locales/InvoiceNoLongerAvailable_fr_CA.js'),
    'components/Invoice/PaymentConfirmation/locales/PaymentConfirmation_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Invoice/PaymentConfirmation/locales/PaymentConfirmation_en_US.js'),
    'components/Invoice/PaymentConfirmation/locales/PaymentConfirmation_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Invoice/PaymentConfirmation/locales/PaymentConfirmation_fr_CA.js'),
    'components/Invoice/SubmitPayment/locales/SubmitPayment_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Invoice/SubmitPayment/locales/SubmitPayment_en_US.js'),
    'components/Invoice/SubmitPayment/locales/SubmitPayment_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Invoice/SubmitPayment/locales/SubmitPayment_fr_CA.js'),
    'components/Invoice/locales/Invoice_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Invoice/locales/Invoice_en_US.js'),
    'components/Invoice/locales/Invoice_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Invoice/locales/Invoice_fr_CA.js'),
    'components/ItemSubstitution/FirstChoiceItem/locales/FirstChoiceItem_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ItemSubstitution/FirstChoiceItem/locales/FirstChoiceItem_en_US.js'),
    'components/ItemSubstitution/FirstChoiceItem/locales/FirstChoiceItem_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ItemSubstitution/FirstChoiceItem/locales/FirstChoiceItem_fr_CA.js'),
    'components/ItemSubstitution/ItemSubstitutionModal/locales/ItemSubstitutionModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ItemSubstitution/ItemSubstitutionModal/locales/ItemSubstitutionModal_en_US.js'),
    'components/ItemSubstitution/ItemSubstitutionModal/locales/ItemSubstitutionModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ItemSubstitution/ItemSubstitutionModal/locales/ItemSubstitutionModal_fr_CA.js'),
    'components/ItemSubstitution/ItemSubstitutionRecommendation/locales/ItemSubstitutionRecommendation_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ItemSubstitution/ItemSubstitutionRecommendation/locales/ItemSubstitutionRecommendation_en_US.js'),
    'components/ItemSubstitution/ItemSubstitutionRecommendation/locales/ItemSubstitutionRecommendation_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ItemSubstitution/ItemSubstitutionRecommendation/locales/ItemSubstitutionRecommendation_fr_CA.js'),
    'components/ItemSubstitution/SubstituteItem/locales/SubstituteItem_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ItemSubstitution/SubstituteItem/locales/SubstituteItem_en_US.js'),
    'components/ItemSubstitution/SubstituteItem/locales/SubstituteItem_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ItemSubstitution/SubstituteItem/locales/SubstituteItem_fr_CA.js'),
    'components/ItemSubstitution/locales/ItemSubstitution_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ItemSubstitution/locales/ItemSubstitution_en_US.js'),
    'components/ItemSubstitution/locales/ItemSubstitution_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ItemSubstitution/locales/ItemSubstitution_fr_CA.js'),
    'components/Klarna/KlarnaPaymentMethod/locales/KlarnaPaymentMethod_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Klarna/KlarnaPaymentMethod/locales/KlarnaPaymentMethod_en_US.js'),
    'components/Klarna/KlarnaPaymentMethod/locales/KlarnaPaymentMethod_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Klarna/KlarnaPaymentMethod/locales/KlarnaPaymentMethod_fr_CA.js'),
    'components/Klarna/locales/Klarna_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Klarna/locales/Klarna_en_US.js'),
    'components/Klarna/locales/Klarna_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Klarna/locales/Klarna_fr_CA.js'),
    'components/LandingPage/PhoneSubscriber/SMSConfirmationModal/locales/SMSConfirmationModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/LandingPage/PhoneSubscriber/SMSConfirmationModal/locales/SMSConfirmationModal_en_US.js'),
    'components/LandingPage/PhoneSubscriber/SMSConfirmationModal/locales/SMSConfirmationModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/LandingPage/PhoneSubscriber/SMSConfirmationModal/locales/SMSConfirmationModal_fr_CA.js'),
    'components/LandingPage/PhoneSubscriber/locales/PhoneSubscriber_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/LandingPage/PhoneSubscriber/locales/PhoneSubscriber_en_US.js'),
    'components/LandingPage/PhoneSubscriber/locales/PhoneSubscriber_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/LandingPage/PhoneSubscriber/locales/PhoneSubscriber_fr_CA.js'),
    'components/LegacyCarousel/locales/LegacyCarousel_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/LegacyCarousel/locales/LegacyCarousel_en_US.js'),
    'components/LegacyCarousel/locales/LegacyCarousel_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/LegacyCarousel/locales/LegacyCarousel_fr_CA.js'),
    'components/Medallia/locales/Medallia_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Medallia/locales/Medallia_en_US.js'),
    'components/Medallia/locales/Medallia_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Medallia/locales/Medallia_fr_CA.js'),
    'components/Modal/locales/Modal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Modal/locales/Modal_en_US.js'),
    'components/Modal/locales/Modal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Modal/locales/Modal_fr_CA.js'),
    'components/MyOffers/MyOffersLink/locales/MyOffersLink_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/MyOffers/MyOffersLink/locales/MyOffersLink_en_US.js'),
    'components/MyOffers/MyOffersLink/locales/MyOffersLink_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/MyOffers/MyOffersLink/locales/MyOffersLink_fr_CA.js'),
    'components/OnlineReservation/CheckinForm/locales/CheckinForm_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/CheckinForm/locales/CheckinForm_en_US.js'),
    'components/OnlineReservation/CheckinForm/locales/CheckinForm_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/CheckinForm/locales/CheckinForm_fr_CA.js'),
    'components/OnlineReservation/ExperienceDetailPage/ActionsNav/locales/ActionsNav_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/ExperienceDetailPage/ActionsNav/locales/ActionsNav_en_US.js'),
    'components/OnlineReservation/ExperienceDetailPage/ActionsNav/locales/ActionsNav_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/ExperienceDetailPage/ActionsNav/locales/ActionsNav_fr_CA.js'),
    'components/OnlineReservation/ExperienceDetailPage/BookWidget/locales/BookWidget_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/ExperienceDetailPage/BookWidget/locales/BookWidget_en_US.js'),
    'components/OnlineReservation/ExperienceDetailPage/BookWidget/locales/BookWidget_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/ExperienceDetailPage/BookWidget/locales/BookWidget_fr_CA.js'),
    'components/OnlineReservation/ExperienceDetailPage/DatePickerWidget/locales/DatePickerWidget_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/ExperienceDetailPage/DatePickerWidget/locales/DatePickerWidget_en_US.js'),
    'components/OnlineReservation/ExperienceDetailPage/DatePickerWidget/locales/DatePickerWidget_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/ExperienceDetailPage/DatePickerWidget/locales/DatePickerWidget_fr_CA.js'),
    'components/OnlineReservation/ExperienceDetailPage/ExperienceLocation/GeoLocationDisclaimerModal/locales/GeoLocationDisclaimerModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/ExperienceDetailPage/ExperienceLocation/GeoLocationDisclaimerModal/locales/GeoLocationDisclaimerModal_en_US.js'),
    'components/OnlineReservation/ExperienceDetailPage/ExperienceLocation/GeoLocationDisclaimerModal/locales/GeoLocationDisclaimerModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/ExperienceDetailPage/ExperienceLocation/GeoLocationDisclaimerModal/locales/GeoLocationDisclaimerModal_fr_CA.js'),
    'components/OnlineReservation/ExperienceDetailPage/ExperienceLocation/locales/ExperienceLocation_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/ExperienceDetailPage/ExperienceLocation/locales/ExperienceLocation_en_US.js'),
    'components/OnlineReservation/ExperienceDetailPage/ExperienceLocation/locales/ExperienceLocation_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/ExperienceDetailPage/ExperienceLocation/locales/ExperienceLocation_fr_CA.js'),
    'components/OnlineReservation/ExperienceDetailPage/RsvpWidget/locales/RsvpWidget_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/ExperienceDetailPage/RsvpWidget/locales/RsvpWidget_en_US.js'),
    'components/OnlineReservation/ExperienceDetailPage/RsvpWidget/locales/RsvpWidget_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/ExperienceDetailPage/RsvpWidget/locales/RsvpWidget_fr_CA.js'),
    'components/OnlineReservation/ExperienceDetailPage/StoreInformation/locales/StoreInformation_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/ExperienceDetailPage/StoreInformation/locales/StoreInformation_en_US.js'),
    'components/OnlineReservation/ExperienceDetailPage/StoreInformation/locales/StoreInformation_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/ExperienceDetailPage/StoreInformation/locales/StoreInformation_fr_CA.js'),
    'components/OnlineReservation/ExperienceDetailPage/StoresAvailability/StoreTimeSlots/locales/StoreTimeSlots_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/ExperienceDetailPage/StoresAvailability/StoreTimeSlots/locales/StoreTimeSlots_en_US.js'),
    'components/OnlineReservation/ExperienceDetailPage/StoresAvailability/StoreTimeSlots/locales/StoreTimeSlots_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/ExperienceDetailPage/StoresAvailability/StoreTimeSlots/locales/StoreTimeSlots_fr_CA.js'),
    'components/OnlineReservation/ExperienceDetailPage/StoresAvailability/locales/StoresAvailability_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/ExperienceDetailPage/StoresAvailability/locales/StoresAvailability_en_US.js'),
    'components/OnlineReservation/ExperienceDetailPage/StoresAvailability/locales/StoresAvailability_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/ExperienceDetailPage/StoresAvailability/locales/StoresAvailability_fr_CA.js'),
    'components/OnlineReservation/ExperienceDetailPage/locales/Confirmation_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/ExperienceDetailPage/locales/Confirmation_en_US.js'),
    'components/OnlineReservation/ExperienceDetailPage/locales/Confirmation_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/ExperienceDetailPage/locales/Confirmation_fr_CA.js'),
    'components/OnlineReservation/ExperienceDetailPage/locales/ExperienceInfo_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/ExperienceDetailPage/locales/ExperienceInfo_en_US.js'),
    'components/OnlineReservation/ExperienceDetailPage/locales/ExperienceInfo_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/ExperienceDetailPage/locales/ExperienceInfo_fr_CA.js'),
    'components/OnlineReservation/StoreHub/ActivityCard/locales/ActivityCard_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/StoreHub/ActivityCard/locales/ActivityCard_en_US.js'),
    'components/OnlineReservation/StoreHub/ActivityCard/locales/ActivityCard_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/StoreHub/ActivityCard/locales/ActivityCard_fr_CA.js'),
    'components/OnlineReservation/StoreHub/ActivityCarousel/locales/ActivityCarousel_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/StoreHub/ActivityCarousel/locales/ActivityCarousel_en_US.js'),
    'components/OnlineReservation/StoreHub/ActivityCarousel/locales/ActivityCarousel_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/StoreHub/ActivityCarousel/locales/ActivityCarousel_fr_CA.js'),
    'components/OnlineReservation/StoreHub/ActivityFilterBanner/FilterByDay/locales/FilterByDay_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/StoreHub/ActivityFilterBanner/FilterByDay/locales/FilterByDay_en_US.js'),
    'components/OnlineReservation/StoreHub/ActivityFilterBanner/FilterByDay/locales/FilterByDay_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/StoreHub/ActivityFilterBanner/FilterByDay/locales/FilterByDay_fr_CA.js'),
    'components/OnlineReservation/StoreHub/ActivityFilterBanner/FilterByLocation/locales/FilterByLocation_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/StoreHub/ActivityFilterBanner/FilterByLocation/locales/FilterByLocation_en_US.js'),
    'components/OnlineReservation/StoreHub/ActivityFilterBanner/FilterByLocation/locales/FilterByLocation_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/StoreHub/ActivityFilterBanner/FilterByLocation/locales/FilterByLocation_fr_CA.js'),
    'components/OnlineReservation/StoreHub/ActivityFilterBanner/FilterByStore/locales/FilterByStore_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/StoreHub/ActivityFilterBanner/FilterByStore/locales/FilterByStore_en_US.js'),
    'components/OnlineReservation/StoreHub/ActivityFilterBanner/FilterByStore/locales/FilterByStore_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/StoreHub/ActivityFilterBanner/FilterByStore/locales/FilterByStore_fr_CA.js'),
    'components/OnlineReservation/StoreHub/ActivityFilterBanner/locales/ActivityFilterBanner_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/StoreHub/ActivityFilterBanner/locales/ActivityFilterBanner_en_US.js'),
    'components/OnlineReservation/StoreHub/ActivityFilterBanner/locales/ActivityFilterBanner_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/StoreHub/ActivityFilterBanner/locales/ActivityFilterBanner_fr_CA.js'),
    'components/OnlineReservation/StoreHub/ActivityGridView/locales/ActivityGridView_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/StoreHub/ActivityGridView/locales/ActivityGridView_en_US.js'),
    'components/OnlineReservation/StoreHub/ActivityGridView/locales/ActivityGridView_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/StoreHub/ActivityGridView/locales/ActivityGridView_fr_CA.js'),
    'components/OnlineReservation/StoreHub/locales/StoreHubContent_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/StoreHub/locales/StoreHubContent_en_US.js'),
    'components/OnlineReservation/StoreHub/locales/StoreHubContent_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/StoreHub/locales/StoreHubContent_fr_CA.js'),
    'components/OnlineReservation/UpcomingReservations/locales/UpcomingReservations_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/UpcomingReservations/locales/UpcomingReservations_en_US.js'),
    'components/OnlineReservation/UpcomingReservations/locales/UpcomingReservations_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/UpcomingReservations/locales/UpcomingReservations_fr_CA.js'),
    'components/OnlineReservation/locales/ServicesBreadcrumbs_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/locales/ServicesBreadcrumbs_en_US.js'),
    'components/OnlineReservation/locales/ServicesBreadcrumbs_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/locales/ServicesBreadcrumbs_fr_CA.js'),
    'components/OnlineReservation/locales/ServicesFAQ_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/locales/ServicesFAQ_fr_CA.js'),
    'components/OnlineReservation/locales/activityConstants_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/locales/activityConstants_en_US.js'),
    'components/OnlineReservation/locales/activityConstants_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlineReservation/locales/activityConstants_fr_CA.js'),
    'components/OnlyFewLeftFlag/locales/OnlyFewLeftFlag_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlyFewLeftFlag/locales/OnlyFewLeftFlag_en_US.js'),
    'components/OnlyFewLeftFlag/locales/OnlyFewLeftFlag_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OnlyFewLeftFlag/locales/OnlyFewLeftFlag_fr_CA.js'),
    'components/OrderConfirmation/BeautyInsiderSection/MyBeautyBank/locales/MyBeautyBank_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OrderConfirmation/BeautyInsiderSection/MyBeautyBank/locales/MyBeautyBank_en_US.js'),
    'components/OrderConfirmation/BeautyInsiderSection/MyBeautyBank/locales/MyBeautyBank_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OrderConfirmation/BeautyInsiderSection/MyBeautyBank/locales/MyBeautyBank_fr_CA.js'),
    'components/OrderConfirmation/BeautyInsiderSection/locales/BeautyInsiderSection_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OrderConfirmation/BeautyInsiderSection/locales/BeautyInsiderSection_en_US.js'),
    'components/OrderConfirmation/BeautyInsiderSection/locales/BeautyInsiderSection_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OrderConfirmation/BeautyInsiderSection/locales/BeautyInsiderSection_fr_CA.js'),
    'components/OrderConfirmation/GuestCheckoutSection/locales/GuestCheckoutSection_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OrderConfirmation/GuestCheckoutSection/locales/GuestCheckoutSection_en_US.js'),
    'components/OrderConfirmation/GuestCheckoutSection/locales/GuestCheckoutSection_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OrderConfirmation/GuestCheckoutSection/locales/GuestCheckoutSection_fr_CA.js'),
    'components/OrderConfirmation/OrderItemList/OrderItem/locales/OrderItem_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OrderConfirmation/OrderItemList/OrderItem/locales/OrderItem_en_US.js'),
    'components/OrderConfirmation/OrderItemList/OrderItem/locales/OrderItem_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OrderConfirmation/OrderItemList/OrderItem/locales/OrderItem_fr_CA.js'),
    'components/OrderConfirmation/OrderItemList/locales/OrderItemList_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OrderConfirmation/OrderItemList/locales/OrderItemList_en_US.js'),
    'components/OrderConfirmation/OrderItemList/locales/OrderItemList_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OrderConfirmation/OrderItemList/locales/OrderItemList_fr_CA.js'),
    'components/OrderConfirmation/OrderTotal/locales/OrderTotal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OrderConfirmation/OrderTotal/locales/OrderTotal_en_US.js'),
    'components/OrderConfirmation/OrderTotal/locales/OrderTotal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OrderConfirmation/OrderTotal/locales/OrderTotal_fr_CA.js'),
    'components/OrderConfirmation/SDUBanner/locales/SDUBanner_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OrderConfirmation/SDUBanner/locales/SDUBanner_en_US.js'),
    'components/OrderConfirmation/SDUBanner/locales/SDUBanner_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OrderConfirmation/SDUBanner/locales/SDUBanner_fr_CA.js'),
    'components/OrderConfirmation/locales/OrderConfirmation_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OrderConfirmation/locales/OrderConfirmation_en_US.js'),
    'components/OrderConfirmation/locales/OrderConfirmation_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/OrderConfirmation/locales/OrderConfirmation_fr_CA.js'),
    'components/PanZoom/locales/PanZoom_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/PanZoom/locales/PanZoom_en_US.js'),
    'components/PanZoom/locales/PanZoom_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/PanZoom/locales/PanZoom_fr_CA.js'),
    'components/PayPalButton/locales/PayPalButton_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/PayPalButton/locales/PayPalButton_en_US.js'),
    'components/PayPalButton/locales/PayPalButton_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/PayPalButton/locales/PayPalButton_fr_CA.js'),
    'components/Paze/PazePaymentMethod/locales/PazePaymentMethod_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Paze/PazePaymentMethod/locales/PazePaymentMethod_en_US.js'),
    'components/Paze/PazePaymentMethod/locales/PazePaymentMethod_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Paze/PazePaymentMethod/locales/PazePaymentMethod_fr_CA.js'),
    'components/Preview/locale/Preview_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Preview/locale/Preview_en_US.js'),
    'components/Preview/locale/Preview_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Preview/locale/Preview_fr_CA.js'),
    'components/Product/LegacyUpperFunnelProductTiles/locales/UpperFunnelProductTiles_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/LegacyUpperFunnelProductTiles/locales/UpperFunnelProductTiles_fr_CA.js'),
    'components/Product/Price/locales/Price_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/Price/locales/Price_en_US.js'),
    'components/Product/Price/locales/Price_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/Price/locales/Price_fr_CA.js'),
    'components/Product/ProductBadges/locales/ProductBadges_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/ProductBadges/locales/ProductBadges_en_US.js'),
    'components/Product/ProductBadges/locales/ProductBadges_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/ProductBadges/locales/ProductBadges_fr_CA.js'),
    'components/Product/ProductCard/locales/ProductCard_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/ProductCard/locales/ProductCard_en_US.js'),
    'components/Product/ProductCard/locales/ProductCard_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/ProductCard/locales/ProductCard_fr_CA.js'),
    'components/Product/ProductItem/locales/ProductItem_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/ProductItem/locales/ProductItem_en_US.js'),
    'components/Product/ProductItem/locales/ProductItem_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/ProductItem/locales/ProductItem_fr_CA.js'),
    'components/Product/ProductListItem/locales/ProductListItem_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/ProductListItem/locales/ProductListItem_en_US.js'),
    'components/Product/ProductListItem/locales/ProductListItem_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/ProductListItem/locales/ProductListItem_fr_CA.js'),
    'components/Product/ProductLove/ProductLoveButton/locales/ProductLoveButton_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/ProductLove/ProductLoveButton/locales/ProductLoveButton_en_US.js'),
    'components/Product/ProductLove/ProductLoveButton/locales/ProductLoveButton_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/ProductLove/ProductLoveButton/locales/ProductLoveButton_fr_CA.js'),
    'components/Product/ProductLove/ProductLoveToggle/locales/ProductLoveToggle_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/ProductLove/ProductLoveToggle/locales/ProductLoveToggle_en_US.js'),
    'components/Product/ProductLove/ProductLoveToggle/locales/ProductLoveToggle_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/ProductLove/ProductLoveToggle/locales/ProductLoveToggle_fr_CA.js'),
    'components/Product/ProductLovesCount/locales/ProductLovesCount_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/ProductLovesCount/locales/ProductLovesCount_en_US.js'),
    'components/Product/ProductLovesCount/locales/ProductLovesCount_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/ProductLovesCount/locales/ProductLovesCount_fr_CA.js'),
    'components/Product/ProductQuicklook/locales/ProductQuicklook_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/ProductQuicklook/locales/ProductQuicklook_en_US.js'),
    'components/Product/ProductQuicklook/locales/ProductQuicklook_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/ProductQuicklook/locales/ProductQuicklook_fr_CA.js'),
    'components/Product/ProductSDU/locales/ProductSDU_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/ProductSDU/locales/ProductSDU_en_US.js'),
    'components/Product/ProductSDU/locales/ProductSDU_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/ProductSDU/locales/ProductSDU_fr_CA.js'),
    'components/Product/ProductSort/locales/ProductSortOptions_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/ProductSort/locales/ProductSortOptions_en_US.js'),
    'components/Product/ProductSort/locales/ProductSortOptions_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/ProductSort/locales/ProductSortOptions_fr_CA.js'),
    'components/Product/ProductSort/locales/ProductSort_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/ProductSort/locales/ProductSort_en_US.js'),
    'components/Product/ProductSort/locales/ProductSort_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/ProductSort/locales/ProductSort_fr_CA.js'),
    'components/Product/ProductVariation/locales/ProductVariation_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/ProductVariation/locales/ProductVariation_en_US.js'),
    'components/Product/ProductVariation/locales/ProductVariation_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/ProductVariation/locales/ProductVariation_fr_CA.js'),
    'components/Product/PromoItem/locales/PromoItem_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/PromoItem/locales/PromoItem_en_US.js'),
    'components/Product/PromoItem/locales/PromoItem_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/PromoItem/locales/PromoItem_fr_CA.js'),
    'components/Product/ReviewCount/locales/ReviewCount_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/ReviewCount/locales/ReviewCount_en_US.js'),
    'components/Product/ReviewCount/locales/ReviewCount_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/ReviewCount/locales/ReviewCount_fr_CA.js'),
    'components/Product/SampleItem/locales/SampleItem_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/SampleItem/locales/SampleItem_en_US.js'),
    'components/Product/SampleItem/locales/SampleItem_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/SampleItem/locales/SampleItem_fr_CA.js'),
    'components/Product/SizeAndItemNumber/locales/SizeAndItemNumber_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/SizeAndItemNumber/locales/SizeAndItemNumber_en_US.js'),
    'components/Product/SizeAndItemNumber/locales/SizeAndItemNumber_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/SizeAndItemNumber/locales/SizeAndItemNumber_fr_CA.js'),
    'components/Product/SkuQuantity/locales/SkuQuantity_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/SkuQuantity/locales/SkuQuantity_en_US.js'),
    'components/Product/SkuQuantity/locales/SkuQuantity_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Product/SkuQuantity/locales/SkuQuantity_fr_CA.js'),
    'components/ProductPage/AddStructure/locales/AddStructure_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/AddStructure/locales/AddStructure_fr_CA.js'),
    'components/ProductPage/BeautyMatchTooltip/locales/BeautyMatchTooltip_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/BeautyMatchTooltip/locales/BeautyMatchTooltip_en_US.js'),
    'components/ProductPage/BeautyMatchTooltip/locales/BeautyMatchTooltip_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/BeautyMatchTooltip/locales/BeautyMatchTooltip_fr_CA.js'),
    'components/ProductPage/CallToAction/locales/CallToAction_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/CallToAction/locales/CallToAction_en_US.js'),
    'components/ProductPage/CallToAction/locales/CallToAction_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/CallToAction/locales/CallToAction_fr_CA.js'),
    'components/ProductPage/CleanHighlightProducts/locales/CleanHighlightProducts_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/CleanHighlightProducts/locales/CleanHighlightProducts_en_US.js'),
    'components/ProductPage/CleanHighlightProducts/locales/CleanHighlightProducts_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/CleanHighlightProducts/locales/CleanHighlightProducts_fr_CA.js'),
    'components/ProductPage/ColorIQBadge/locales/ColorIQBadge_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/ColorIQBadge/locales/ColorIQBadge_en_US.js'),
    'components/ProductPage/ColorIQBadge/locales/ColorIQBadge_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/ColorIQBadge/locales/ColorIQBadge_fr_CA.js'),
    'components/ProductPage/DeliveryOptions/SDUBanner/locales/SDUBanner_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/DeliveryOptions/SDUBanner/locales/SDUBanner_en_US.js'),
    'components/ProductPage/DeliveryOptions/SDUBanner/locales/SDUBanner_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/DeliveryOptions/SDUBanner/locales/SDUBanner_fr_CA.js'),
    'components/ProductPage/DeliveryOptions/locales/DeliveryOptions_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/DeliveryOptions/locales/DeliveryOptions_en_US.js'),
    'components/ProductPage/DeliveryOptions/locales/DeliveryOptions_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/DeliveryOptions/locales/DeliveryOptions_fr_CA.js'),
    'components/ProductPage/Feedback/locales/Feedback_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/Feedback/locales/Feedback_en_US.js'),
    'components/ProductPage/Feedback/locales/Feedback_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/Feedback/locales/Feedback_fr_CA.js'),
    'components/ProductPage/Filters/Filter/locales/Filter_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/Filters/Filter/locales/Filter_en_US.js'),
    'components/ProductPage/Filters/Filter/locales/Filter_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/Filters/Filter/locales/Filter_fr_CA.js'),
    'components/ProductPage/Filters/FilterGroup/locales/FilterGroup_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/Filters/FilterGroup/locales/FilterGroup_fr_CA.js'),
    'components/ProductPage/FrequentlyBoughtTogether/locales/FrequentlyBoughtTogether_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/FrequentlyBoughtTogether/locales/FrequentlyBoughtTogether_en_US.js'),
    'components/ProductPage/FrequentlyBoughtTogether/locales/FrequentlyBoughtTogether_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/FrequentlyBoughtTogether/locales/FrequentlyBoughtTogether_fr_CA.js'),
    'components/ProductPage/GalleryCardCarousel/SeeMoreCard/locales/SeeMoreCard_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/GalleryCardCarousel/SeeMoreCard/locales/SeeMoreCard_en_US.js'),
    'components/ProductPage/GalleryCardCarousel/SeeMoreCard/locales/SeeMoreCard_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/GalleryCardCarousel/SeeMoreCard/locales/SeeMoreCard_fr_CA.js'),
    'components/ProductPage/GalleryCardCarousel/locales/GalleryCardCarousel_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/GalleryCardCarousel/locales/GalleryCardCarousel_en_US.js'),
    'components/ProductPage/GalleryCardCarousel/locales/GalleryCardCarousel_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/GalleryCardCarousel/locales/GalleryCardCarousel_fr_CA.js'),
    'components/ProductPage/IncentivizedBadge/locales/IncentivizedBadge_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/IncentivizedBadge/locales/IncentivizedBadge_en_US.js'),
    'components/ProductPage/IncentivizedBadge/locales/IncentivizedBadge_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/IncentivizedBadge/locales/IncentivizedBadge_fr_CA.js'),
    'components/ProductPage/LovesCount/locales/LovesCount_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/LovesCount/locales/LovesCount_en_US.js'),
    'components/ProductPage/LovesCount/locales/LovesCount_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/LovesCount/locales/LovesCount_fr_CA.js'),
    'components/ProductPage/ProductMediaCarousel/ThumbnailCarousel/locales/ThumbnailCarousel_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/ProductMediaCarousel/ThumbnailCarousel/locales/ThumbnailCarousel_en_US.js'),
    'components/ProductPage/ProductMediaCarousel/ThumbnailCarousel/locales/ThumbnailCarousel_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/ProductMediaCarousel/ThumbnailCarousel/locales/ThumbnailCarousel_fr_CA.js'),
    'components/ProductPage/ProductTable/ProductTableItem/locales/ProductTableItem_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/ProductTable/ProductTableItem/locales/ProductTableItem_en_US.js'),
    'components/ProductPage/ProductTable/ProductTableItem/locales/ProductTableItem_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/ProductTable/ProductTableItem/locales/ProductTableItem_fr_CA.js'),
    'components/ProductPage/QuestionsAndAnswers/locales/QuestionsAndAnswers_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/QuestionsAndAnswers/locales/QuestionsAndAnswers_en_US.js'),
    'components/ProductPage/QuestionsAndAnswers/locales/QuestionsAndAnswers_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/QuestionsAndAnswers/locales/QuestionsAndAnswers_fr_CA.js'),
    'components/ProductPage/RatingsAndReviews/GuidelinesModalLink/locales/GuidelinesModalLink_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RatingsAndReviews/GuidelinesModalLink/locales/GuidelinesModalLink_en_US.js'),
    'components/ProductPage/RatingsAndReviews/GuidelinesModalLink/locales/GuidelinesModalLink_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RatingsAndReviews/GuidelinesModalLink/locales/GuidelinesModalLink_fr_CA.js'),
    'components/ProductPage/RatingsAndReviews/HighlyRatedFor/SentimentModal/locales/HighlyRatedFor_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RatingsAndReviews/HighlyRatedFor/SentimentModal/locales/HighlyRatedFor_en_US.js'),
    'components/ProductPage/RatingsAndReviews/HighlyRatedFor/SentimentModal/locales/HighlyRatedFor_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RatingsAndReviews/HighlyRatedFor/SentimentModal/locales/HighlyRatedFor_fr_CA.js'),
    'components/ProductPage/RatingsAndReviews/HighlyRatedFor/SentimentModal/locales/SentimentModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RatingsAndReviews/HighlyRatedFor/SentimentModal/locales/SentimentModal_en_US.js'),
    'components/ProductPage/RatingsAndReviews/HighlyRatedFor/SentimentModal/locales/SentimentModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RatingsAndReviews/HighlyRatedFor/SentimentModal/locales/SentimentModal_fr_CA.js'),
    'components/ProductPage/RatingsAndReviews/HighlyRatedFor/locales/HighlyRatedFor_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RatingsAndReviews/HighlyRatedFor/locales/HighlyRatedFor_en_US.js'),
    'components/ProductPage/RatingsAndReviews/HighlyRatedFor/locales/HighlyRatedFor_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RatingsAndReviews/HighlyRatedFor/locales/HighlyRatedFor_fr_CA.js'),
    'components/ProductPage/RatingsAndReviews/Legal/locales/ReviewLegalText_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RatingsAndReviews/Legal/locales/ReviewLegalText_en_US.js'),
    'components/ProductPage/RatingsAndReviews/Legal/locales/ReviewLegalText_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RatingsAndReviews/Legal/locales/ReviewLegalText_fr_CA.js'),
    'components/ProductPage/RatingsAndReviews/ProsAndCons/locales/ProsAndCons_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RatingsAndReviews/ProsAndCons/locales/ProsAndCons_en_US.js'),
    'components/ProductPage/RatingsAndReviews/ProsAndCons/locales/ProsAndCons_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RatingsAndReviews/ProsAndCons/locales/ProsAndCons_fr_CA.js'),
    'components/ProductPage/RatingsAndReviews/Review/locales/Review_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RatingsAndReviews/Review/locales/Review_en_US.js'),
    'components/ProductPage/RatingsAndReviews/Review/locales/Review_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RatingsAndReviews/Review/locales/Review_fr_CA.js'),
    'components/ProductPage/RatingsAndReviews/ReviewImageModal/locales/ReviewImageModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RatingsAndReviews/ReviewImageModal/locales/ReviewImageModal_en_US.js'),
    'components/ProductPage/RatingsAndReviews/ReviewImageModal/locales/ReviewImageModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RatingsAndReviews/ReviewImageModal/locales/ReviewImageModal_fr_CA.js'),
    'components/ProductPage/RatingsAndReviews/ReviewsFilters/NonIncentivizedTooltip/locales/NonIncentivizedTooltip_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RatingsAndReviews/ReviewsFilters/NonIncentivizedTooltip/locales/NonIncentivizedTooltip_en_US.js'),
    'components/ProductPage/RatingsAndReviews/ReviewsFilters/NonIncentivizedTooltip/locales/NonIncentivizedTooltip_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RatingsAndReviews/ReviewsFilters/NonIncentivizedTooltip/locales/NonIncentivizedTooltip_fr_CA.js'),
    'components/ProductPage/RatingsAndReviews/ReviewsFilters/locales/ReviewsFilters_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RatingsAndReviews/ReviewsFilters/locales/ReviewsFilters_en_US.js'),
    'components/ProductPage/RatingsAndReviews/ReviewsFilters/locales/ReviewsFilters_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RatingsAndReviews/ReviewsFilters/locales/ReviewsFilters_fr_CA.js'),
    'components/ProductPage/RatingsAndReviews/ReviewsStats/locales/ReviewsStats_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RatingsAndReviews/ReviewsStats/locales/ReviewsStats_en_US.js'),
    'components/ProductPage/RatingsAndReviews/ReviewsStats/locales/ReviewsStats_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RatingsAndReviews/ReviewsStats/locales/ReviewsStats_fr_CA.js'),
    'components/ProductPage/RatingsAndReviews/Sentiments/locales/Sentiments_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RatingsAndReviews/Sentiments/locales/Sentiments_fr_CA.js'),
    'components/ProductPage/RatingsAndReviews/locales/RatingsAndReviews_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RatingsAndReviews/locales/RatingsAndReviews_en_US.js'),
    'components/ProductPage/RatingsAndReviews/locales/RatingsAndReviews_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RatingsAndReviews/locales/RatingsAndReviews_fr_CA.js'),
    'components/ProductPage/RelatedLinks/locales/RelatedLinks_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RelatedLinks/locales/RelatedLinks_en_US.js'),
    'components/ProductPage/RelatedLinks/locales/RelatedLinks_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/RelatedLinks/locales/RelatedLinks_fr_CA.js'),
    'components/ProductPage/ReviewsAnchor/locales/ReviewsAnchor_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/ReviewsAnchor/locales/ReviewsAnchor_en_US.js'),
    'components/ProductPage/ReviewsAnchor/locales/ReviewsAnchor_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/ReviewsAnchor/locales/ReviewsAnchor_fr_CA.js'),
    'components/ProductPage/ReviewsImageCarousel/locales/ReviewsImageCarousel_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/ReviewsImageCarousel/locales/ReviewsImageCarousel_en_US.js'),
    'components/ProductPage/ReviewsImageCarousel/locales/ReviewsImageCarousel_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/ReviewsImageCarousel/locales/ReviewsImageCarousel_fr_CA.js'),
    'components/ProductPage/ShadeFilter/locales/ShadeFilter_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/ShadeFilter/locales/ShadeFilter_en_US.js'),
    'components/ProductPage/ShadeFilter/locales/ShadeFilter_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/ShadeFilter/locales/ShadeFilter_fr_CA.js'),
    'components/ProductPage/ShadeFinderText/locales/ShadeFinderText_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/ShadeFinderText/locales/ShadeFinderText_en_US.js'),
    'components/ProductPage/ShadeFinderText/locales/ShadeFinderText_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/ShadeFinderText/locales/ShadeFinderText_fr_CA.js'),
    'components/ProductPage/Swatches/locales/Swatches_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/Swatches/locales/Swatches_en_US.js'),
    'components/ProductPage/Swatches/locales/Swatches_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/Swatches/locales/Swatches_fr_CA.js'),
    'components/ProductPage/Type/DigitalProduct/SameDayUnlimitedLandingPage/FeesAndFAQ/locales/FeesAndFAQ_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/Type/DigitalProduct/SameDayUnlimitedLandingPage/FeesAndFAQ/locales/FeesAndFAQ_en_US.js'),
    'components/ProductPage/Type/DigitalProduct/SameDayUnlimitedLandingPage/FeesAndFAQ/locales/FeesAndFAQ_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/Type/DigitalProduct/SameDayUnlimitedLandingPage/FeesAndFAQ/locales/FeesAndFAQ_fr_CA.js'),
    'components/ProductPage/Type/DigitalProduct/SameDayUnlimitedLandingPage/FreeTrial/locales/FreeTrial_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/Type/DigitalProduct/SameDayUnlimitedLandingPage/FreeTrial/locales/FreeTrial_en_US.js'),
    'components/ProductPage/Type/DigitalProduct/SameDayUnlimitedLandingPage/FreeTrial/locales/FreeTrial_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/Type/DigitalProduct/SameDayUnlimitedLandingPage/FreeTrial/locales/FreeTrial_fr_CA.js'),
    'components/ProductPage/Type/DigitalProduct/SameDayUnlimitedLandingPage/Header/locales/Header_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/Type/DigitalProduct/SameDayUnlimitedLandingPage/Header/locales/Header_en_US.js'),
    'components/ProductPage/Type/DigitalProduct/SameDayUnlimitedLandingPage/Header/locales/Header_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/Type/DigitalProduct/SameDayUnlimitedLandingPage/Header/locales/Header_fr_CA.js'),
    'components/ProductPage/Type/DigitalProduct/SameDayUnlimitedLandingPage/ZipCodeLocator/locales/ZipCodeLocator_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/Type/DigitalProduct/SameDayUnlimitedLandingPage/ZipCodeLocator/locales/ZipCodeLocator_en_US.js'),
    'components/ProductPage/Type/DigitalProduct/SameDayUnlimitedLandingPage/ZipCodeLocator/locales/ZipCodeLocator_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/Type/DigitalProduct/SameDayUnlimitedLandingPage/ZipCodeLocator/locales/ZipCodeLocator_fr_CA.js'),
    'components/ProductPage/Type/RewardProduct/RougeRewardButton/locales/RougeRewardButton_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/Type/RewardProduct/RougeRewardButton/locales/RougeRewardButton_en_US.js'),
    'components/ProductPage/Type/RewardProduct/RougeRewardButton/locales/RougeRewardButton_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/Type/RewardProduct/RougeRewardButton/locales/RougeRewardButton_fr_CA.js'),
    'components/ProductPage/Wizard/locales/Wizard_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/Wizard/locales/Wizard_en_US.js'),
    'components/ProductPage/Wizard/locales/Wizard_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/Wizard/locales/Wizard_fr_CA.js'),
    'components/ProductPage/locales/RwdProductPage_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/locales/RwdProductPage_en_US.js'),
    'components/ProductPage/locales/RwdProductPage_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProductPage/locales/RwdProductPage_fr_CA.js'),
    'components/ProfileBanner/locale/ProfileBanner_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProfileBanner/locale/ProfileBanner_en_US.js'),
    'components/ProfileBanner/locale/ProfileBanner_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ProfileBanner/locale/ProfileBanner_fr_CA.js'),
    'components/ReCaptchaText/locales/ReCaptchaText_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ReCaptchaText/locales/ReCaptchaText_en_US.js'),
    'components/ReCaptchaText/locales/ReCaptchaText_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ReCaptchaText/locales/ReCaptchaText_fr_CA.js'),
    'components/RelatedLinks/locales/RelatedLinks_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RelatedLinks/locales/RelatedLinks_en_US.js'),
    'components/RelatedLinks/locales/RelatedLinks_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RelatedLinks/locales/RelatedLinks_fr_CA.js'),
    'components/ResetPassword/locales/ResetPassword_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ResetPassword/locales/ResetPassword_en_US.js'),
    'components/ResetPassword/locales/ResetPassword_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ResetPassword/locales/ResetPassword_fr_CA.js'),
    'components/Reward/BiUnavailable/locales/BiUnavailable_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Reward/BiUnavailable/locales/BiUnavailable_en_US.js'),
    'components/Reward/BiUnavailable/locales/BiUnavailable_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Reward/BiUnavailable/locales/BiUnavailable_fr_CA.js'),
    'components/Reward/LoyaltyPromo/locales/LoyaltyPromo_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Reward/LoyaltyPromo/locales/LoyaltyPromo_en_US.js'),
    'components/Reward/LoyaltyPromo/locales/LoyaltyPromo_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Reward/LoyaltyPromo/locales/LoyaltyPromo_fr_CA.js'),
    'components/Reward/RewardItem/locales/RewardItem_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Reward/RewardItem/locales/RewardItem_en_US.js'),
    'components/Reward/RewardItem/locales/RewardItem_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Reward/RewardItem/locales/RewardItem_fr_CA.js'),
    'components/Reward/RrcPromo/locales/RrcPromo_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Reward/RrcPromo/locales/RrcPromo_en_US.js'),
    'components/Reward/RrcPromo/locales/RrcPromo_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Reward/RrcPromo/locales/RrcPromo_fr_CA.js'),
    'components/RewardsBazaar/locales/RewardsBazaar_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RewardsBazaar/locales/RewardsBazaar_en_US.js'),
    'components/RewardsBazaar/locales/RewardsBazaar_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RewardsBazaar/locales/RewardsBazaar_fr_CA.js'),
    'components/RichProfile/BeautyInsider/ActiveCampaign/locales/ActiveCampaign_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/ActiveCampaign/locales/ActiveCampaign_en_US.js'),
    'components/RichProfile/BeautyInsider/ActiveCampaign/locales/ActiveCampaign_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/ActiveCampaign/locales/ActiveCampaign_fr_CA.js'),
    'components/RichProfile/BeautyInsider/BeautyInsiderCash/locales/BeautyInsiderCash_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/BeautyInsiderCash/locales/BeautyInsiderCash_en_US.js'),
    'components/RichProfile/BeautyInsider/BeautyInsiderCash/locales/BeautyInsiderCash_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/BeautyInsiderCash/locales/BeautyInsiderCash_fr_CA.js'),
    'components/RichProfile/BeautyInsider/BeautyInsiderPointMultiplier/locales/BeautyInsiderPointMultiplier_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/BeautyInsiderPointMultiplier/locales/BeautyInsiderPointMultiplier_en_US.js'),
    'components/RichProfile/BeautyInsider/BeautyInsiderPointMultiplier/locales/BeautyInsiderPointMultiplier_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/BeautyInsiderPointMultiplier/locales/BeautyInsiderPointMultiplier_fr_CA.js'),
    'components/RichProfile/BeautyInsider/BiGamificationLink/locales/BiGamificationLink_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/BiGamificationLink/locales/BiGamificationLink_en_US.js'),
    'components/RichProfile/BeautyInsider/BiGamificationLink/locales/BiGamificationLink_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/BiGamificationLink/locales/BiGamificationLink_fr_CA.js'),
    'components/RichProfile/BeautyInsider/BiInfoCard/locales/BiInfoCard_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/BiInfoCard/locales/BiInfoCard_en_US.js'),
    'components/RichProfile/BeautyInsider/BiInfoCard/locales/BiInfoCard_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/BiInfoCard/locales/BiInfoCard_fr_CA.js'),
    'components/RichProfile/BeautyInsider/BiWelcomeCard/locales/BiWelcomeCard_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/BiWelcomeCard/locales/BiWelcomeCard_en_US.js'),
    'components/RichProfile/BeautyInsider/BiWelcomeCard/locales/BiWelcomeCard_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/BiWelcomeCard/locales/BiWelcomeCard_fr_CA.js'),
    'components/RichProfile/BeautyInsider/CreditCardRewards/locales/CreditCardRewards_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/CreditCardRewards/locales/CreditCardRewards_en_US.js'),
    'components/RichProfile/BeautyInsider/CreditCardRewards/locales/CreditCardRewards_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/CreditCardRewards/locales/CreditCardRewards_fr_CA.js'),
    'components/RichProfile/BeautyInsider/PointsForDiscount/locales/PointsForDiscount_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/PointsForDiscount/locales/PointsForDiscount_en_US.js'),
    'components/RichProfile/BeautyInsider/PointsForDiscount/locales/PointsForDiscount_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/PointsForDiscount/locales/PointsForDiscount_fr_CA.js'),
    'components/RichProfile/BeautyInsider/PointsNSpendBank/AllBankActivity/locales/AllBankActivity_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/PointsNSpendBank/AllBankActivity/locales/AllBankActivity_en_US.js'),
    'components/RichProfile/BeautyInsider/PointsNSpendBank/AllBankActivity/locales/AllBankActivity_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/PointsNSpendBank/AllBankActivity/locales/AllBankActivity_fr_CA.js'),
    'components/RichProfile/BeautyInsider/PointsNSpendBank/PointsNSpendGrid/locales/PointsNSpendGrid_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/PointsNSpendBank/PointsNSpendGrid/locales/PointsNSpendGrid_en_US.js'),
    'components/RichProfile/BeautyInsider/PointsNSpendBank/PointsNSpendGrid/locales/PointsNSpendGrid_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/PointsNSpendBank/PointsNSpendGrid/locales/PointsNSpendGrid_fr_CA.js'),
    'components/RichProfile/BeautyInsider/PointsNSpendBank/RecentBankActivity/locales/RecentBankActivity_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/PointsNSpendBank/RecentBankActivity/locales/RecentBankActivity_en_US.js'),
    'components/RichProfile/BeautyInsider/PointsNSpendBank/RecentBankActivity/locales/RecentBankActivity_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/PointsNSpendBank/RecentBankActivity/locales/RecentBankActivity_fr_CA.js'),
    'components/RichProfile/BeautyInsider/PointsNSpendBank/locales/BIPointsDisclaimer_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/PointsNSpendBank/locales/BIPointsDisclaimer_en_US.js'),
    'components/RichProfile/BeautyInsider/PointsNSpendBank/locales/BIPointsDisclaimer_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/PointsNSpendBank/locales/BIPointsDisclaimer_fr_CA.js'),
    'components/RichProfile/BeautyInsider/PointsNSpendBank/locales/BIPointsWarnings_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/PointsNSpendBank/locales/BIPointsWarnings_en_US.js'),
    'components/RichProfile/BeautyInsider/PointsNSpendBank/locales/BIPointsWarnings_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/PointsNSpendBank/locales/BIPointsWarnings_fr_CA.js'),
    'components/RichProfile/BeautyInsider/RecentPointsActivity/locales/RecentPointsActivity_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/RecentPointsActivity/locales/RecentPointsActivity_en_US.js'),
    'components/RichProfile/BeautyInsider/RecentPointsActivity/locales/RecentPointsActivity_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/RecentPointsActivity/locales/RecentPointsActivity_fr_CA.js'),
    'components/RichProfile/BeautyInsider/RecentRewardActivity/locales/RecentRewardActivity_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/RecentRewardActivity/locales/RecentRewardActivity_en_US.js'),
    'components/RichProfile/BeautyInsider/RecentRewardActivity/locales/RecentRewardActivity_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/RecentRewardActivity/locales/RecentRewardActivity_fr_CA.js'),
    'components/RichProfile/BeautyInsider/RewardsCarousel/locales/RewardsCarousel_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/RewardsCarousel/locales/RewardsCarousel_en_US.js'),
    'components/RichProfile/BeautyInsider/RewardsCarousel/locales/RewardsCarousel_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/RewardsCarousel/locales/RewardsCarousel_fr_CA.js'),
    'components/RichProfile/BeautyInsider/RougeRewards/locales/RougeRewards_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/RougeRewards/locales/RougeRewards_en_US.js'),
    'components/RichProfile/BeautyInsider/RougeRewards/locales/RougeRewards_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/RougeRewards/locales/RougeRewards_fr_CA.js'),
    'components/RichProfile/BeautyInsider/SignInSignUp/locales/SignInSignUp_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/SignInSignUp/locales/SignInSignUp_en_US.js'),
    'components/RichProfile/BeautyInsider/SignInSignUp/locales/SignInSignUp_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/SignInSignUp/locales/SignInSignUp_fr_CA.js'),
    'components/RichProfile/BeautyInsider/ValueChips/locales/ValueChips_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/ValueChips/locales/ValueChips_en_US.js'),
    'components/RichProfile/BeautyInsider/ValueChips/locales/ValueChips_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/ValueChips/locales/ValueChips_fr_CA.js'),
    'components/RichProfile/BeautyInsider/ValueTable/locales/ValueTable_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/ValueTable/locales/ValueTable_en_US.js'),
    'components/RichProfile/BeautyInsider/ValueTable/locales/ValueTable_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/ValueTable/locales/ValueTable_fr_CA.js'),
    'components/RichProfile/BeautyInsider/locales/BeautyInsider_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/locales/BeautyInsider_en_US.js'),
    'components/RichProfile/BeautyInsider/locales/BeautyInsider_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/BeautyInsider/locales/BeautyInsider_fr_CA.js'),
    'components/RichProfile/CommunityError/locales/CommunityError_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/CommunityError/locales/CommunityError_en_US.js'),
    'components/RichProfile/CommunityError/locales/CommunityError_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/CommunityError/locales/CommunityError_fr_CA.js'),
    'components/RichProfile/CreditCards/locales/CreditCards_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/CreditCards/locales/CreditCards_en_US.js'),
    'components/RichProfile/CreditCards/locales/CreditCards_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/CreditCards/locales/CreditCards_fr_CA.js'),
    'components/RichProfile/EditMyProfile/Content/BioProfile/locales/BioProfile_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/EditMyProfile/Content/BioProfile/locales/BioProfile_en_US.js'),
    'components/RichProfile/EditMyProfile/Content/BioProfile/locales/BioProfile_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/EditMyProfile/Content/BioProfile/locales/BioProfile_fr_CA.js'),
    'components/RichProfile/EditMyProfile/Content/Birthday/locales/Birthday_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/EditMyProfile/Content/Birthday/locales/Birthday_en_US.js'),
    'components/RichProfile/EditMyProfile/Content/Birthday/locales/Birthday_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/EditMyProfile/Content/Birthday/locales/Birthday_fr_CA.js'),
    'components/RichProfile/EditMyProfile/Content/ColorIQ/locales/ColorIQ_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/EditMyProfile/Content/ColorIQ/locales/ColorIQ_en_US.js'),
    'components/RichProfile/EditMyProfile/Content/ColorIQ/locales/ColorIQ_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/EditMyProfile/Content/ColorIQ/locales/ColorIQ_fr_CA.js'),
    'components/RichProfile/EditMyProfile/Content/Eyes/locales/Eyes_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/EditMyProfile/Content/Eyes/locales/Eyes_en_US.js'),
    'components/RichProfile/EditMyProfile/Content/Eyes/locales/Eyes_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/EditMyProfile/Content/Eyes/locales/Eyes_fr_CA.js'),
    'components/RichProfile/EditMyProfile/Content/Hair/locales/Hair_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/EditMyProfile/Content/Hair/locales/Hair_en_US.js'),
    'components/RichProfile/EditMyProfile/Content/Hair/locales/Hair_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/EditMyProfile/Content/Hair/locales/Hair_fr_CA.js'),
    'components/RichProfile/EditMyProfile/Content/Privacy/locales/Privacy_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/EditMyProfile/Content/Privacy/locales/Privacy_en_US.js'),
    'components/RichProfile/EditMyProfile/Content/Privacy/locales/Privacy_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/EditMyProfile/Content/Privacy/locales/Privacy_fr_CA.js'),
    'components/RichProfile/EditMyProfile/Content/Skin/locales/Skin_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/EditMyProfile/Content/Skin/locales/Skin_en_US.js'),
    'components/RichProfile/EditMyProfile/Content/Skin/locales/Skin_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/EditMyProfile/Content/Skin/locales/Skin_fr_CA.js'),
    'components/RichProfile/EditMyProfile/Content/locales/ContentHeading_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/EditMyProfile/Content/locales/ContentHeading_en_US.js'),
    'components/RichProfile/EditMyProfile/Content/locales/ContentHeading_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/EditMyProfile/Content/locales/ContentHeading_fr_CA.js'),
    'components/RichProfile/EditMyProfile/Modals/EditFlowModal/locales/EditFlowModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/EditMyProfile/Modals/EditFlowModal/locales/EditFlowModal_en_US.js'),
    'components/RichProfile/EditMyProfile/Modals/EditFlowModal/locales/EditFlowModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/EditMyProfile/Modals/EditFlowModal/locales/EditFlowModal_fr_CA.js'),
    'components/RichProfile/EditMyProfile/Modals/EditMyProfileModal/locales/EditMyProfileModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/EditMyProfile/Modals/EditMyProfileModal/locales/EditMyProfileModal_en_US.js'),
    'components/RichProfile/EditMyProfile/Modals/EditMyProfileModal/locales/EditMyProfileModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/EditMyProfile/Modals/EditMyProfileModal/locales/EditMyProfileModal_fr_CA.js'),
    'components/RichProfile/ListPageHeader/locales/ListPageHeader_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/ListPageHeader/locales/ListPageHeader_en_US.js'),
    'components/RichProfile/ListPageHeader/locales/ListPageHeader_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/ListPageHeader/locales/ListPageHeader_fr_CA.js'),
    'components/RichProfile/Lists/ListsLoves/locales/ListsLoves_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/Lists/ListsLoves/locales/ListsLoves_en_US.js'),
    'components/RichProfile/Lists/ListsLoves/locales/ListsLoves_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/Lists/ListsLoves/locales/ListsLoves_fr_CA.js'),
    'components/RichProfile/Lists/ListsStoreServices/locales/ListsStoreServices_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/Lists/ListsStoreServices/locales/ListsStoreServices_en_US.js'),
    'components/RichProfile/Lists/ListsStoreServices/locales/ListsStoreServices_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/Lists/ListsStoreServices/locales/ListsStoreServices_fr_CA.js'),
    'components/RichProfile/Lists/locales/ListsHeader_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/Lists/locales/ListsHeader_en_US.js'),
    'components/RichProfile/Lists/locales/ListsHeader_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/Lists/locales/ListsHeader_fr_CA.js'),
    'components/RichProfile/Lists/locales/Lists_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/Lists/locales/Lists_en_US.js'),
    'components/RichProfile/Lists/locales/Lists_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/Lists/locales/Lists_fr_CA.js'),
    'components/RichProfile/MyAccount/AccountInfo/AccountBirthday/locales/AccountBirthday_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/AccountInfo/AccountBirthday/locales/AccountBirthday_en_US.js'),
    'components/RichProfile/MyAccount/AccountInfo/AccountBirthday/locales/AccountBirthday_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/AccountInfo/AccountBirthday/locales/AccountBirthday_fr_CA.js'),
    'components/RichProfile/MyAccount/AccountInfo/AccountEmail/locales/AccountEmail_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/AccountInfo/AccountEmail/locales/AccountEmail_en_US.js'),
    'components/RichProfile/MyAccount/AccountInfo/AccountEmail/locales/AccountEmail_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/AccountInfo/AccountEmail/locales/AccountEmail_fr_CA.js'),
    'components/RichProfile/MyAccount/AccountInfo/AccountLinkedAccounts/locales/AccountLinkedAccounts_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/AccountInfo/AccountLinkedAccounts/locales/AccountLinkedAccounts_en_US.js'),
    'components/RichProfile/MyAccount/AccountInfo/AccountLinkedAccounts/locales/AccountLinkedAccounts_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/AccountInfo/AccountLinkedAccounts/locales/AccountLinkedAccounts_fr_CA.js'),
    'components/RichProfile/MyAccount/AccountInfo/AccountName/locales/AccountName_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/AccountInfo/AccountName/locales/AccountName_en_US.js'),
    'components/RichProfile/MyAccount/AccountInfo/AccountName/locales/AccountName_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/AccountInfo/AccountName/locales/AccountName_fr_CA.js'),
    'components/RichProfile/MyAccount/AccountInfo/AccountOwnership/locales/AccountOwnership_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/AccountInfo/AccountOwnership/locales/AccountOwnership_en_US.js'),
    'components/RichProfile/MyAccount/AccountInfo/AccountOwnership/locales/AccountOwnership_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/AccountInfo/AccountOwnership/locales/AccountOwnership_fr_CA.js'),
    'components/RichProfile/MyAccount/AccountInfo/AccountPassword/locales/AccountPassword_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/AccountInfo/AccountPassword/locales/AccountPassword_en_US.js'),
    'components/RichProfile/MyAccount/AccountInfo/AccountPassword/locales/AccountPassword_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/AccountInfo/AccountPassword/locales/AccountPassword_fr_CA.js'),
    'components/RichProfile/MyAccount/AccountInfo/locales/AccountInfo_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/AccountInfo/locales/AccountInfo_en_US.js'),
    'components/RichProfile/MyAccount/AccountInfo/locales/AccountInfo_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/AccountInfo/locales/AccountInfo_fr_CA.js'),
    'components/RichProfile/MyAccount/AccountLayout/locales/AccountNav_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/AccountLayout/locales/AccountNav_en_US.js'),
    'components/RichProfile/MyAccount/AccountLayout/locales/AccountNav_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/AccountLayout/locales/AccountNav_fr_CA.js'),
    'components/RichProfile/MyAccount/Addresses/AcctAddressForm/locales/AcctAddressForm_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/Addresses/AcctAddressForm/locales/AcctAddressForm_en_US.js'),
    'components/RichProfile/MyAccount/Addresses/AcctAddressForm/locales/AcctAddressForm_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/Addresses/AcctAddressForm/locales/AcctAddressForm_fr_CA.js'),
    'components/RichProfile/MyAccount/Addresses/locales/Addresses_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/Addresses/locales/Addresses_en_US.js'),
    'components/RichProfile/MyAccount/Addresses/locales/Addresses_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/Addresses/locales/Addresses_fr_CA.js'),
    'components/RichProfile/MyAccount/AutoReplenishment/locales/AutoReplenishment_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/AutoReplenishment/locales/AutoReplenishment_en_US.js'),
    'components/RichProfile/MyAccount/AutoReplenishment/locales/AutoReplenishment_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/AutoReplenishment/locales/AutoReplenishment_fr_CA.js'),
    'components/RichProfile/MyAccount/BopisSddSmsSignupButton/locales/BopisSddSmsSignupButton_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/BopisSddSmsSignupButton/locales/BopisSddSmsSignupButton_en_US.js'),
    'components/RichProfile/MyAccount/BopisSddSmsSignupButton/locales/BopisSddSmsSignupButton_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/BopisSddSmsSignupButton/locales/BopisSddSmsSignupButton_fr_CA.js'),
    'components/RichProfile/MyAccount/MailingPrefs/NotificationsAndRemindersPrefs/locales/NotificationsAndRemindersPrefs_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/MailingPrefs/NotificationsAndRemindersPrefs/locales/NotificationsAndRemindersPrefs_en_US.js'),
    'components/RichProfile/MyAccount/MailingPrefs/NotificationsAndRemindersPrefs/locales/NotificationsAndRemindersPrefs_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/MailingPrefs/NotificationsAndRemindersPrefs/locales/NotificationsAndRemindersPrefs_fr_CA.js'),
    'components/RichProfile/MyAccount/MailingPrefs/PostalMailPrefs/locales/PostalMailPrefs_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/MailingPrefs/PostalMailPrefs/locales/PostalMailPrefs_en_US.js'),
    'components/RichProfile/MyAccount/MailingPrefs/PostalMailPrefs/locales/PostalMailPrefs_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/MailingPrefs/PostalMailPrefs/locales/PostalMailPrefs_fr_CA.js'),
    'components/RichProfile/MyAccount/MailingPrefs/PromotionalEmailsPrefs/locales/PromotionalEmailsPrefs_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/MailingPrefs/PromotionalEmailsPrefs/locales/PromotionalEmailsPrefs_en_US.js'),
    'components/RichProfile/MyAccount/MailingPrefs/PromotionalEmailsPrefs/locales/PromotionalEmailsPrefs_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/MailingPrefs/PromotionalEmailsPrefs/locales/PromotionalEmailsPrefs_fr_CA.js'),
    'components/RichProfile/MyAccount/MailingPrefs/locales/MailingPrefs_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/MailingPrefs/locales/MailingPrefs_en_US.js'),
    'components/RichProfile/MyAccount/MailingPrefs/locales/MailingPrefs_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/MailingPrefs/locales/MailingPrefs_fr_CA.js'),
    'components/RichProfile/MyAccount/OrderDetail/PickupOrderStatus/locales/PickupOrderStatus_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/OrderDetail/PickupOrderStatus/locales/PickupOrderStatus_en_US.js'),
    'components/RichProfile/MyAccount/OrderDetail/PickupOrderStatus/locales/PickupOrderStatus_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/OrderDetail/PickupOrderStatus/locales/PickupOrderStatus_fr_CA.js'),
    'components/RichProfile/MyAccount/OrderDetail/StandardAndSddOrderSummary/locales/StandardAndSddOrderSummary_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/OrderDetail/StandardAndSddOrderSummary/locales/StandardAndSddOrderSummary_en_US.js'),
    'components/RichProfile/MyAccount/OrderDetail/StandardAndSddOrderSummary/locales/StandardAndSddOrderSummary_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/OrderDetail/StandardAndSddOrderSummary/locales/StandardAndSddOrderSummary_fr_CA.js'),
    'components/RichProfile/MyAccount/OrderDetail/locales/OrderDetail_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/OrderDetail/locales/OrderDetail_en_US.js'),
    'components/RichProfile/MyAccount/OrderDetail/locales/OrderDetail_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/OrderDetail/locales/OrderDetail_fr_CA.js'),
    'components/RichProfile/MyAccount/OrderDetail/locales/OrderShippingGroups_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/OrderDetail/locales/OrderShippingGroups_en_US.js'),
    'components/RichProfile/MyAccount/OrderDetail/locales/OrderShippingGroups_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/OrderDetail/locales/OrderShippingGroups_fr_CA.js'),
    'components/RichProfile/MyAccount/OrderStatusLookup/locales/OrderStatusLookup_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/OrderStatusLookup/locales/OrderStatusLookup_en_US.js'),
    'components/RichProfile/MyAccount/OrderStatusLookup/locales/OrderStatusLookup_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/OrderStatusLookup/locales/OrderStatusLookup_fr_CA.js'),
    'components/RichProfile/MyAccount/Payments/CreditCards/CreditCardForm/locales/CreditCardForm_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/Payments/CreditCards/CreditCardForm/locales/CreditCardForm_en_US.js'),
    'components/RichProfile/MyAccount/Payments/CreditCards/CreditCardForm/locales/CreditCardForm_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/Payments/CreditCards/CreditCardForm/locales/CreditCardForm_fr_CA.js'),
    'components/RichProfile/MyAccount/Payments/CreditCards/locales/CreditCards_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/Payments/CreditCards/locales/CreditCards_en_US.js'),
    'components/RichProfile/MyAccount/Payments/CreditCards/locales/CreditCards_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/Payments/CreditCards/locales/CreditCards_fr_CA.js'),
    'components/RichProfile/MyAccount/Payments/DefaultPayment/locales/DefaultPayment_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/Payments/DefaultPayment/locales/DefaultPayment_en_US.js'),
    'components/RichProfile/MyAccount/Payments/DefaultPayment/locales/DefaultPayment_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/Payments/DefaultPayment/locales/DefaultPayment_fr_CA.js'),
    'components/RichProfile/MyAccount/Payments/OtherPayments/locales/OtherPayments_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/Payments/OtherPayments/locales/OtherPayments_en_US.js'),
    'components/RichProfile/MyAccount/Payments/OtherPayments/locales/OtherPayments_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/Payments/OtherPayments/locales/OtherPayments_fr_CA.js'),
    'components/RichProfile/MyAccount/Payments/locales/Payments_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/Payments/locales/Payments_en_US.js'),
    'components/RichProfile/MyAccount/Payments/locales/Payments_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/Payments/locales/Payments_fr_CA.js'),
    'components/RichProfile/MyAccount/PleaseSignIn/locales/PleaseSignIn_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/PleaseSignIn/locales/PleaseSignIn_en_US.js'),
    'components/RichProfile/MyAccount/PleaseSignIn/locales/PleaseSignIn_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/PleaseSignIn/locales/PleaseSignIn_fr_CA.js'),
    'components/RichProfile/MyAccount/RecentOrders/locales/RecentOrders_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/RecentOrders/locales/RecentOrders_en_US.js'),
    'components/RichProfile/MyAccount/RecentOrders/locales/RecentOrders_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/RecentOrders/locales/RecentOrders_fr_CA.js'),
    'components/RichProfile/MyAccount/ReplacementOrder/locales/ReplacementOrder_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/ReplacementOrder/locales/ReplacementOrder_en_US.js'),
    'components/RichProfile/MyAccount/ReplacementOrder/locales/ReplacementOrder_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/ReplacementOrder/locales/ReplacementOrder_fr_CA.js'),
    'components/RichProfile/MyAccount/ReplacementOrderStatus/locales/ReplacementOrderStatus_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/ReplacementOrderStatus/locales/ReplacementOrderStatus_en_US.js'),
    'components/RichProfile/MyAccount/ReplacementOrderStatus/locales/ReplacementOrderStatus_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/ReplacementOrderStatus/locales/ReplacementOrderStatus_fr_CA.js'),
    'components/RichProfile/MyAccount/ReturnLink/locales/ReturnLink_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/ReturnLink/locales/ReturnLink_en_US.js'),
    'components/RichProfile/MyAccount/ReturnLink/locales/ReturnLink_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/ReturnLink/locales/ReturnLink_fr_CA.js'),
    'components/RichProfile/MyAccount/Subscriptions/SameDayUnlimited/locales/SameDayUnlimited_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/Subscriptions/SameDayUnlimited/locales/SameDayUnlimited_en_US.js'),
    'components/RichProfile/MyAccount/Subscriptions/SameDayUnlimited/locales/SameDayUnlimited_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/Subscriptions/SameDayUnlimited/locales/SameDayUnlimited_fr_CA.js'),
    'components/RichProfile/MyAccount/TaxClaim/locales/TaxClaim_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/TaxClaim/locales/TaxClaim_en_US.js'),
    'components/RichProfile/MyAccount/TaxClaim/locales/TaxClaim_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/TaxClaim/locales/TaxClaim_fr_CA.js'),
    'components/RichProfile/MyAccount/TrackOrderButton/locales/TrackOrderButton_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/TrackOrderButton/locales/TrackOrderButton_en_US.js'),
    'components/RichProfile/MyAccount/TrackOrderButton/locales/TrackOrderButton_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/MyAccount/TrackOrderButton/locales/TrackOrderButton_fr_CA.js'),
    'components/RichProfile/PurchaseHistoryList/PurchasedGroups/locales/PurchasedGroups_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/PurchaseHistoryList/PurchasedGroups/locales/PurchasedGroups_en_US.js'),
    'components/RichProfile/PurchaseHistoryList/PurchasedGroups/locales/PurchasedGroups_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/PurchaseHistoryList/PurchasedGroups/locales/PurchasedGroups_fr_CA.js'),
    'components/RichProfile/PurchaseHistoryList/PurchasesFilter/locales/PurchasesFilterOptions_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/PurchaseHistoryList/PurchasesFilter/locales/PurchasesFilterOptions_en_US.js'),
    'components/RichProfile/PurchaseHistoryList/PurchasesFilter/locales/PurchasesFilterOptions_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/PurchaseHistoryList/PurchasesFilter/locales/PurchasesFilterOptions_fr_CA.js'),
    'components/RichProfile/PurchaseHistoryList/PurchasesFilter/locales/PurchasesFilter_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/PurchaseHistoryList/PurchasesFilter/locales/PurchasesFilter_en_US.js'),
    'components/RichProfile/PurchaseHistoryList/PurchasesFilter/locales/PurchasesFilter_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/PurchaseHistoryList/PurchasesFilter/locales/PurchasesFilter_fr_CA.js'),
    'components/RichProfile/PurchaseHistoryList/locales/PurchaseHistoryList_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/PurchaseHistoryList/locales/PurchaseHistoryList_en_US.js'),
    'components/RichProfile/PurchaseHistoryList/locales/PurchaseHistoryList_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/PurchaseHistoryList/locales/PurchaseHistoryList_fr_CA.js'),
    'components/RichProfile/StoreServices/EmptyService/locales/EmptyService_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/StoreServices/EmptyService/locales/EmptyService_en_US.js'),
    'components/RichProfile/StoreServices/EmptyService/locales/EmptyService_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/StoreServices/EmptyService/locales/EmptyService_fr_CA.js'),
    'components/RichProfile/StoreServices/Service/locales/Service_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/StoreServices/Service/locales/Service_en_US.js'),
    'components/RichProfile/StoreServices/Service/locales/Service_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/StoreServices/Service/locales/Service_fr_CA.js'),
    'components/RichProfile/StoreServices/locales/AllStoreServices_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/StoreServices/locales/AllStoreServices_en_US.js'),
    'components/RichProfile/StoreServices/locales/AllStoreServices_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/StoreServices/locales/AllStoreServices_fr_CA.js'),
    'components/RichProfile/UserProfile/MyProfile/locales/MyProfile_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/MyProfile/locales/MyProfile_en_US.js'),
    'components/RichProfile/UserProfile/MyProfile/locales/MyProfile_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/MyProfile/locales/MyProfile_fr_CA.js'),
    'components/RichProfile/UserProfile/PrivateProfile/locales/PrivateProfile_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/PrivateProfile/locales/PrivateProfile_en_US.js'),
    'components/RichProfile/UserProfile/PrivateProfile/locales/PrivateProfile_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/PrivateProfile/locales/PrivateProfile_fr_CA.js'),
    'components/RichProfile/UserProfile/PublicProfile/locales/PublicProfile_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/PublicProfile/locales/PublicProfile_en_US.js'),
    'components/RichProfile/UserProfile/PublicProfile/locales/PublicProfile_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/PublicProfile/locales/PublicProfile_fr_CA.js'),
    'components/RichProfile/UserProfile/UserProfile_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/UserProfile_en_US.js'),
    'components/RichProfile/UserProfile/UserProfile_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/UserProfile_fr_CA.js'),
    'components/RichProfile/UserProfile/common/AboutMeSlideshow/AboutMeSlide/locales/AboutMeSlide_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/AboutMeSlideshow/AboutMeSlide/locales/AboutMeSlide_en_US.js'),
    'components/RichProfile/UserProfile/common/AboutMeSlideshow/AboutMeSlide/locales/AboutMeSlide_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/AboutMeSlideshow/AboutMeSlide/locales/AboutMeSlide_fr_CA.js'),
    'components/RichProfile/UserProfile/common/AboutMeSlideshow/BiProfileSlide/locales/BiProfileSlide_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/AboutMeSlideshow/BiProfileSlide/locales/BiProfileSlide_en_US.js'),
    'components/RichProfile/UserProfile/common/AboutMeSlideshow/BiProfileSlide/locales/BiProfileSlide_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/AboutMeSlideshow/BiProfileSlide/locales/BiProfileSlide_fr_CA.js'),
    'components/RichProfile/UserProfile/common/AboutMeSlideshow/SocialSlide/locales/SocialSlide_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/AboutMeSlideshow/SocialSlide/locales/SocialSlide_en_US.js'),
    'components/RichProfile/UserProfile/common/AboutMeSlideshow/SocialSlide/locales/SocialSlide_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/AboutMeSlideshow/SocialSlide/locales/SocialSlide_fr_CA.js'),
    'components/RichProfile/UserProfile/common/AboutMeSlideshow/locales/AboutMeSlideshow_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/AboutMeSlideshow/locales/AboutMeSlideshow_en_US.js'),
    'components/RichProfile/UserProfile/common/AboutMeSlideshow/locales/AboutMeSlideshow_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/AboutMeSlideshow/locales/AboutMeSlideshow_fr_CA.js'),
    'components/RichProfile/UserProfile/common/CommunityRibbon/locales/CommunityRibbon_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/CommunityRibbon/locales/CommunityRibbon_en_US.js'),
    'components/RichProfile/UserProfile/common/CommunityRibbon/locales/CommunityRibbon_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/CommunityRibbon/locales/CommunityRibbon_fr_CA.js'),
    'components/RichProfile/UserProfile/common/Conversations/locales/Conversations_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/Conversations/locales/Conversations_en_US.js'),
    'components/RichProfile/UserProfile/common/Conversations/locales/Conversations_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/Conversations/locales/Conversations_fr_CA.js'),
    'components/RichProfile/UserProfile/common/FollowList/locales/FollowList_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/FollowList/locales/FollowList_en_US.js'),
    'components/RichProfile/UserProfile/common/FollowList/locales/FollowList_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/FollowList/locales/FollowList_fr_CA.js'),
    'components/RichProfile/UserProfile/common/Groups/Group/locales/Group_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/Groups/Group/locales/Group_en_US.js'),
    'components/RichProfile/UserProfile/common/Groups/Group/locales/Group_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/Groups/Group/locales/Group_fr_CA.js'),
    'components/RichProfile/UserProfile/common/Groups/locales/Groups_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/Groups/locales/Groups_en_US.js'),
    'components/RichProfile/UserProfile/common/Groups/locales/Groups_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/Groups/locales/Groups_fr_CA.js'),
    'components/RichProfile/UserProfile/common/JoinCommunity/locales/JoinCommunity_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/JoinCommunity/locales/JoinCommunity_en_US.js'),
    'components/RichProfile/UserProfile/common/JoinCommunity/locales/JoinCommunity_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/JoinCommunity/locales/JoinCommunity_fr_CA.js'),
    'components/RichProfile/UserProfile/common/RecentPurchases/locales/RecentPurchases_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/RecentPurchases/locales/RecentPurchases_en_US.js'),
    'components/RichProfile/UserProfile/common/RecentPurchases/locales/RecentPurchases_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/RecentPurchases/locales/RecentPurchases_fr_CA.js'),
    'components/RichProfile/UserProfile/common/RecentReviews/locales/RecentReviews_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/RecentReviews/locales/RecentReviews_en_US.js'),
    'components/RichProfile/UserProfile/common/RecentReviews/locales/RecentReviews_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/RecentReviews/locales/RecentReviews_fr_CA.js'),
    'components/RichProfile/UserProfile/common/SectionContainer/locales/SectionContainer_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/SectionContainer/locales/SectionContainer_en_US.js'),
    'components/RichProfile/UserProfile/common/SectionContainer/locales/SectionContainer_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/SectionContainer/locales/SectionContainer_fr_CA.js'),
    'components/RichProfile/UserProfile/common/locales/Looks_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/locales/Looks_en_US.js'),
    'components/RichProfile/UserProfile/common/locales/Looks_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/common/locales/Looks_fr_CA.js'),
    'components/RichProfile/UserProfile/locales/PleaseSignInProfile_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/locales/PleaseSignInProfile_en_US.js'),
    'components/RichProfile/UserProfile/locales/PleaseSignInProfile_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/UserProfile/locales/PleaseSignInProfile_fr_CA.js'),
    'components/RichProfile/ViewAllLoves/locales/ViewAllLoves_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/ViewAllLoves/locales/ViewAllLoves_en_US.js'),
    'components/RichProfile/ViewAllLoves/locales/ViewAllLoves_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RichProfile/ViewAllLoves/locales/ViewAllLoves_fr_CA.js'),
    'components/Rmn/locales/RmnBanner_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Rmn/locales/RmnBanner_en_US.js'),
    'components/Rmn/locales/RmnBanner_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Rmn/locales/RmnBanner_fr_CA.js'),
    'components/RougeRewardsCarousel/locales/RougeRewards_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RougeRewardsCarousel/locales/RougeRewards_en_US.js'),
    'components/RougeRewardsCarousel/locales/RougeRewards_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RougeRewardsCarousel/locales/RougeRewards_fr_CA.js'),
    'components/RwdBasket/Banners/SDU/locales/SDURougeInsentiveBanner_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Banners/SDU/locales/SDURougeInsentiveBanner_en_US.js'),
    'components/RwdBasket/Banners/SDU/locales/SDURougeInsentiveBanner_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Banners/SDU/locales/SDURougeInsentiveBanner_fr_CA.js'),
    'components/RwdBasket/Carts/AutoreplenishCart/BelowProductPrice/locales/AcceleratedPromotionTerms_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/AutoreplenishCart/BelowProductPrice/locales/AcceleratedPromotionTerms_en_US.js'),
    'components/RwdBasket/Carts/AutoreplenishCart/BelowProductPrice/locales/AcceleratedPromotionTerms_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/AutoreplenishCart/BelowProductPrice/locales/AcceleratedPromotionTerms_fr_CA.js'),
    'components/RwdBasket/Carts/AutoreplenishCart/BelowProductPrice/locales/DeliveryFrequency_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/AutoreplenishCart/BelowProductPrice/locales/DeliveryFrequency_en_US.js'),
    'components/RwdBasket/Carts/AutoreplenishCart/BelowProductPrice/locales/DeliveryFrequency_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/AutoreplenishCart/BelowProductPrice/locales/DeliveryFrequency_fr_CA.js'),
    'components/RwdBasket/Carts/AutoreplenishCart/TextZone/locales/TextZone_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/AutoreplenishCart/TextZone/locales/TextZone_en_US.js'),
    'components/RwdBasket/Carts/AutoreplenishCart/TextZone/locales/TextZone_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/AutoreplenishCart/TextZone/locales/TextZone_fr_CA.js'),
    'components/RwdBasket/Carts/AutoreplenishCart/locales/AutoreplenishCart_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/AutoreplenishCart/locales/AutoreplenishCart_en_US.js'),
    'components/RwdBasket/Carts/AutoreplenishCart/locales/AutoreplenishCart_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/AutoreplenishCart/locales/AutoreplenishCart_fr_CA.js'),
    'components/RwdBasket/Carts/BOPISCart/locales/BOPISCart_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/BOPISCart/locales/BOPISCart_en_US.js'),
    'components/RwdBasket/Carts/BOPISCart/locales/BOPISCart_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/BOPISCart/locales/BOPISCart_fr_CA.js'),
    'components/RwdBasket/Carts/CartLayout/ConfirmationBox/locales/ConfirmationBox_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/CartLayout/ConfirmationBox/locales/ConfirmationBox_en_US.js'),
    'components/RwdBasket/Carts/CartLayout/ConfirmationBox/locales/ConfirmationBox_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/CartLayout/ConfirmationBox/locales/ConfirmationBox_fr_CA.js'),
    'components/RwdBasket/Carts/CartLayout/SkuItem/ChangeMethod/locales/ChangeMethod_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/CartLayout/SkuItem/ChangeMethod/locales/ChangeMethod_en_US.js'),
    'components/RwdBasket/Carts/CartLayout/SkuItem/ChangeMethod/locales/ChangeMethod_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/CartLayout/SkuItem/ChangeMethod/locales/ChangeMethod_fr_CA.js'),
    'components/RwdBasket/Carts/CartLayout/locales/CartLayout_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/CartLayout/locales/CartLayout_en_US.js'),
    'components/RwdBasket/Carts/CartLayout/locales/CartLayout_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/CartLayout/locales/CartLayout_fr_CA.js'),
    'components/RwdBasket/Carts/EmptyCart/locales/EmptyCart_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/EmptyCart/locales/EmptyCart_en_US.js'),
    'components/RwdBasket/Carts/EmptyCart/locales/EmptyCart_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/EmptyCart/locales/EmptyCart_fr_CA.js'),
    'components/RwdBasket/Carts/SDDCart/TextZone/locales/SDDRougePromoText_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/SDDCart/TextZone/locales/SDDRougePromoText_en_US.js'),
    'components/RwdBasket/Carts/SDDCart/TextZone/locales/SDDRougePromoText_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/SDDCart/TextZone/locales/SDDRougePromoText_fr_CA.js'),
    'components/RwdBasket/Carts/SDDCart/TextZone/locales/SDUPromoBanner_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/SDDCart/TextZone/locales/SDUPromoBanner_en_US.js'),
    'components/RwdBasket/Carts/SDDCart/TextZone/locales/SDUPromoBanner_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/SDDCart/TextZone/locales/SDUPromoBanner_fr_CA.js'),
    'components/RwdBasket/Carts/SDDCart/locales/SDDCart_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/SDDCart/locales/SDDCart_en_US.js'),
    'components/RwdBasket/Carts/SDDCart/locales/SDDCart_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/SDDCart/locales/SDDCart_fr_CA.js'),
    'components/RwdBasket/Carts/StandardCart/TextZone/locales/TextZone_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/StandardCart/TextZone/locales/TextZone_en_US.js'),
    'components/RwdBasket/Carts/StandardCart/TextZone/locales/TextZone_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/StandardCart/TextZone/locales/TextZone_fr_CA.js'),
    'components/RwdBasket/Carts/StandardCart/locales/StandardCart_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/StandardCart/locales/StandardCart_en_US.js'),
    'components/RwdBasket/Carts/StandardCart/locales/StandardCart_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Carts/StandardCart/locales/StandardCart_fr_CA.js'),
    'components/RwdBasket/DeliveryInfo/SDD/locales/SDDDeliveryInfo_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/DeliveryInfo/SDD/locales/SDDDeliveryInfo_en_US.js'),
    'components/RwdBasket/DeliveryInfo/SDD/locales/SDDDeliveryInfo_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/DeliveryInfo/SDD/locales/SDDDeliveryInfo_fr_CA.js'),
    'components/RwdBasket/Messages/BiFreeShipping/locales/BiFreeShipping_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Messages/BiFreeShipping/locales/BiFreeShipping_en_US.js'),
    'components/RwdBasket/Messages/BiFreeShipping/locales/BiFreeShipping_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/Messages/BiFreeShipping/locales/BiFreeShipping_fr_CA.js'),
    'components/RwdBasket/RwdBasketLayout/BIBenefits/BIBenefitsRewards/locales/BIBenefitsRewards_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/BIBenefits/BIBenefitsRewards/locales/BIBenefitsRewards_en_US.js'),
    'components/RwdBasket/RwdBasketLayout/BIBenefits/BIBenefitsRewards/locales/BIBenefitsRewards_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/BIBenefits/BIBenefitsRewards/locales/BIBenefitsRewards_fr_CA.js'),
    'components/RwdBasket/RwdBasketLayout/BIBenefits/CCRewards/MultipleCCRewardsModal/locales/MultipleCCRewardsModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/BIBenefits/CCRewards/MultipleCCRewardsModal/locales/MultipleCCRewardsModal_en_US.js'),
    'components/RwdBasket/RwdBasketLayout/BIBenefits/CCRewards/MultipleCCRewardsModal/locales/MultipleCCRewardsModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/BIBenefits/CCRewards/MultipleCCRewardsModal/locales/MultipleCCRewardsModal_fr_CA.js'),
    'components/RwdBasket/RwdBasketLayout/BIBenefits/CCRewards/locales/CCRewards_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/BIBenefits/CCRewards/locales/CCRewards_en_US.js'),
    'components/RwdBasket/RwdBasketLayout/BIBenefits/CCRewards/locales/CCRewards_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/BIBenefits/CCRewards/locales/CCRewards_fr_CA.js'),
    'components/RwdBasket/RwdBasketLayout/BIBenefits/CashBackRewards/locales/CashBackRewards_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/BIBenefits/CashBackRewards/locales/CashBackRewards_en_US.js'),
    'components/RwdBasket/RwdBasketLayout/BIBenefits/CashBackRewards/locales/CashBackRewards_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/BIBenefits/CashBackRewards/locales/CashBackRewards_fr_CA.js'),
    'components/RwdBasket/RwdBasketLayout/BIBenefits/FeaturedOffers/locales/FeaturedOffers_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/BIBenefits/FeaturedOffers/locales/FeaturedOffers_en_US.js'),
    'components/RwdBasket/RwdBasketLayout/BIBenefits/FeaturedOffers/locales/FeaturedOffers_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/BIBenefits/FeaturedOffers/locales/FeaturedOffers_fr_CA.js'),
    'components/RwdBasket/RwdBasketLayout/BIBenefits/FreeSamples/locales/FreeSamples_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/BIBenefits/FreeSamples/locales/FreeSamples_en_US.js'),
    'components/RwdBasket/RwdBasketLayout/BIBenefits/FreeSamples/locales/FreeSamples_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/BIBenefits/FreeSamples/locales/FreeSamples_fr_CA.js'),
    'components/RwdBasket/RwdBasketLayout/BIBenefits/RewardsBazaar/locales/RewardsBazaar_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/BIBenefits/RewardsBazaar/locales/RewardsBazaar_en_US.js'),
    'components/RwdBasket/RwdBasketLayout/BIBenefits/RewardsBazaar/locales/RewardsBazaar_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/BIBenefits/RewardsBazaar/locales/RewardsBazaar_fr_CA.js'),
    'components/RwdBasket/RwdBasketLayout/BIBenefits/locales/BIBenefits_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/BIBenefits/locales/BIBenefits_en_US.js'),
    'components/RwdBasket/RwdBasketLayout/BIBenefits/locales/BIBenefits_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/BIBenefits/locales/BIBenefits_fr_CA.js'),
    'components/RwdBasket/RwdBasketLayout/CostSummary/Buttons/locales/CheckoutButton_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/CostSummary/Buttons/locales/CheckoutButton_en_US.js'),
    'components/RwdBasket/RwdBasketLayout/CostSummary/Buttons/locales/CheckoutButton_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/CostSummary/Buttons/locales/CheckoutButton_fr_CA.js'),
    'components/RwdBasket/RwdBasketLayout/CostSummary/CostSummaryBreakdown/locales/CostSummaryBreakdown_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/CostSummary/CostSummaryBreakdown/locales/CostSummaryBreakdown_en_US.js'),
    'components/RwdBasket/RwdBasketLayout/CostSummary/CostSummaryBreakdown/locales/CostSummaryBreakdown_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/CostSummary/CostSummaryBreakdown/locales/CostSummaryBreakdown_fr_CA.js'),
    'components/RwdBasket/RwdBasketLayout/CostSummary/locales/CostSummary_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/CostSummary/locales/CostSummary_en_US.js'),
    'components/RwdBasket/RwdBasketLayout/CostSummary/locales/CostSummary_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/CostSummary/locales/CostSummary_fr_CA.js'),
    'components/RwdBasket/RwdBasketLayout/GiftCardQuickAdd/locales/GiftCardQuickAdd_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/GiftCardQuickAdd/locales/GiftCardQuickAdd_en_US.js'),
    'components/RwdBasket/RwdBasketLayout/GiftCardQuickAdd/locales/GiftCardQuickAdd_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/GiftCardQuickAdd/locales/GiftCardQuickAdd_fr_CA.js'),
    'components/RwdBasket/RwdBasketLayout/TopContentMessages/locales/TopContentFreeReturns_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/TopContentMessages/locales/TopContentFreeReturns_en_US.js'),
    'components/RwdBasket/RwdBasketLayout/TopContentMessages/locales/TopContentFreeReturns_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/TopContentMessages/locales/TopContentFreeReturns_fr_CA.js'),
    'components/RwdBasket/RwdBasketLayout/TopContentMessages/locales/TopContentFreeShipping_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/TopContentMessages/locales/TopContentFreeShipping_en_US.js'),
    'components/RwdBasket/RwdBasketLayout/TopContentMessages/locales/TopContentFreeShipping_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/TopContentMessages/locales/TopContentFreeShipping_fr_CA.js'),
    'components/RwdBasket/RwdBasketLayout/locales/RwdBasketLayout_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/locales/RwdBasketLayout_en_US.js'),
    'components/RwdBasket/RwdBasketLayout/locales/RwdBasketLayout_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketLayout/locales/RwdBasketLayout_fr_CA.js'),
    'components/RwdBasket/RwdBasketMain/locales/RwdBasketMain_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketMain/locales/RwdBasketMain_en_US.js'),
    'components/RwdBasket/RwdBasketMain/locales/RwdBasketMain_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdBasketMain/locales/RwdBasketMain_fr_CA.js'),
    'components/RwdBasket/RwdPreBasket/CartHeaders/AutoReplenishment/locales/AutoReplenishmentHeader_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdPreBasket/CartHeaders/AutoReplenishment/locales/AutoReplenishmentHeader_en_US.js'),
    'components/RwdBasket/RwdPreBasket/CartHeaders/AutoReplenishment/locales/AutoReplenishmentHeader_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdPreBasket/CartHeaders/AutoReplenishment/locales/AutoReplenishmentHeader_fr_CA.js'),
    'components/RwdBasket/RwdPreBasket/CartHeaders/BOPIS/locales/BOPISHeader_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdPreBasket/CartHeaders/BOPIS/locales/BOPISHeader_en_US.js'),
    'components/RwdBasket/RwdPreBasket/CartHeaders/BOPIS/locales/BOPISHeader_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdPreBasket/CartHeaders/BOPIS/locales/BOPISHeader_fr_CA.js'),
    'components/RwdBasket/RwdPreBasket/CartHeaders/SameDay/locales/SameDayHeader_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdPreBasket/CartHeaders/SameDay/locales/SameDayHeader_en_US.js'),
    'components/RwdBasket/RwdPreBasket/CartHeaders/SameDay/locales/SameDayHeader_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdPreBasket/CartHeaders/SameDay/locales/SameDayHeader_fr_CA.js'),
    'components/RwdBasket/RwdPreBasket/CartHeaders/Standard/locales/StandardHeader_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdPreBasket/CartHeaders/Standard/locales/StandardHeader_en_US.js'),
    'components/RwdBasket/RwdPreBasket/CartHeaders/Standard/locales/StandardHeader_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdPreBasket/CartHeaders/Standard/locales/StandardHeader_fr_CA.js'),
    'components/RwdBasket/RwdPreBasket/PreBasketCartLayout/locales/PreBasketCartLayout_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdPreBasket/PreBasketCartLayout/locales/PreBasketCartLayout_en_US.js'),
    'components/RwdBasket/RwdPreBasket/PreBasketCartLayout/locales/PreBasketCartLayout_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdPreBasket/PreBasketCartLayout/locales/PreBasketCartLayout_fr_CA.js'),
    'components/RwdBasket/RwdPreBasket/locales/RwdPreBasket_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdPreBasket/locales/RwdPreBasket_en_US.js'),
    'components/RwdBasket/RwdPreBasket/locales/RwdPreBasket_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/RwdBasket/RwdPreBasket/locales/RwdPreBasket_fr_CA.js'),
    'components/SMSSignUp/locales/SMSSignUp_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SMSSignUp/locales/SMSSignUp_en_US.js'),
    'components/SMSSignUp/locales/SMSSignUp_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SMSSignUp/locales/SMSSignUp_fr_CA.js'),
    'components/SaleFlag/locales/SaleFlag_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SaleFlag/locales/SaleFlag_en_US.js'),
    'components/SaleFlag/locales/SaleFlag_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SaleFlag/locales/SaleFlag_fr_CA.js'),
    'components/SamplesPage/locales/SamplesPage_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SamplesPage/locales/SamplesPage_en_US.js'),
    'components/SamplesPage/locales/SamplesPage_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SamplesPage/locales/SamplesPage_fr_CA.js'),
    'components/Search/NullSearch/locales/NullSearch_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Search/NullSearch/locales/NullSearch_en_US.js'),
    'components/Search/NullSearch/locales/NullSearch_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Search/NullSearch/locales/NullSearch_fr_CA.js'),
    'components/Search/locales/Search_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Search/locales/Search_en_US.js'),
    'components/Search/locales/Search_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Search/locales/Search_fr_CA.js'),
    'components/SeeProductDetails/locales/SeeProductDetails_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SeeProductDetails/locales/SeeProductDetails_en_US.js'),
    'components/SeeProductDetails/locales/SeeProductDetails_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SeeProductDetails/locales/SeeProductDetails_fr_CA.js'),
    'components/ServicesAndEvents/GenericError/locales/GenericError_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ServicesAndEvents/GenericError/locales/GenericError_en_US.js'),
    'components/ServicesAndEvents/GenericError/locales/GenericError_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ServicesAndEvents/GenericError/locales/GenericError_fr_CA.js'),
    'components/ShadeFinder/BrandsList/locales/BrandsList_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ShadeFinder/BrandsList/locales/BrandsList_en_US.js'),
    'components/ShadeFinder/BrandsList/locales/BrandsList_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ShadeFinder/BrandsList/locales/BrandsList_fr_CA.js'),
    'components/ShadeFinder/ProductSelection/ProductSelection_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ShadeFinder/ProductSelection/ProductSelection_fr_CA.js'),
    'components/ShadeFinder/ProductSelection/locales/ProductSelection_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ShadeFinder/ProductSelection/locales/ProductSelection_en_US.js'),
    'components/ShadeFinder/ProductSelection/locales/ProductSelection_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ShadeFinder/ProductSelection/locales/ProductSelection_fr_CA.js'),
    'components/ShadeFinder/ResultsScreen/CapturedColorIQBox/locales/CapturedColorIQBox_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ShadeFinder/ResultsScreen/CapturedColorIQBox/locales/CapturedColorIQBox_en_US.js'),
    'components/ShadeFinder/ResultsScreen/CapturedColorIQBox/locales/CapturedColorIQBox_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ShadeFinder/ResultsScreen/CapturedColorIQBox/locales/CapturedColorIQBox_fr_CA.js'),
    'components/ShadeFinder/ResultsScreen/locales/ResultScreen_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ShadeFinder/ResultsScreen/locales/ResultScreen_en_US.js'),
    'components/ShadeFinder/ResultsScreen/locales/ResultScreen_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ShadeFinder/ResultsScreen/locales/ResultScreen_fr_CA.js'),
    'components/ShadeFinder/SkuSelection/locales/SkuSelection_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ShadeFinder/SkuSelection/locales/SkuSelection_en_US.js'),
    'components/ShadeFinder/SkuSelection/locales/SkuSelection_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ShadeFinder/SkuSelection/locales/SkuSelection_fr_CA.js'),
    'components/ShadeFinder/StartScreen/locales/StartScreen_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ShadeFinder/StartScreen/locales/StartScreen_en_US.js'),
    'components/ShadeFinder/StartScreen/locales/StartScreen_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ShadeFinder/StartScreen/locales/StartScreen_fr_CA.js'),
    'components/ShadeFinder/locales/ShadeFinderQuiz_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ShadeFinder/locales/ShadeFinderQuiz_en_US.js'),
    'components/ShadeFinder/locales/ShadeFinderQuiz_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/ShadeFinder/locales/ShadeFinderQuiz_fr_CA.js'),
    'components/SharedComponents/AccessPoint/FetchLocationHours/locales/FetchLocationHours_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SharedComponents/AccessPoint/FetchLocationHours/locales/FetchLocationHours_en_US.js'),
    'components/SharedComponents/AccessPoint/FetchLocationHours/locales/FetchLocationHours_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SharedComponents/AccessPoint/FetchLocationHours/locales/FetchLocationHours_fr_CA.js'),
    'components/SharedComponents/AccessPoint/PickupPerson/locales/PickupPerson_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SharedComponents/AccessPoint/PickupPerson/locales/PickupPerson_en_US.js'),
    'components/SharedComponents/AccessPoint/PickupPerson/locales/PickupPerson_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SharedComponents/AccessPoint/PickupPerson/locales/PickupPerson_fr_CA.js'),
    'components/SharedComponents/BopisKohlsItem/locales/BopisKohlsItem_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SharedComponents/BopisKohlsItem/locales/BopisKohlsItem_en_US.js'),
    'components/SharedComponents/BopisKohlsItem/locales/BopisKohlsItem_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SharedComponents/BopisKohlsItem/locales/BopisKohlsItem_fr_CA.js'),
    'components/SharedComponents/FinalSaleItem/locales/FinalSaleItem_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SharedComponents/FinalSaleItem/locales/FinalSaleItem_en_US.js'),
    'components/SharedComponents/FinalSaleItem/locales/FinalSaleItem_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SharedComponents/FinalSaleItem/locales/FinalSaleItem_fr_CA.js'),
    'components/SharedComponents/OcuppationalTaxItem/locales/OccupationalTaxItem_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SharedComponents/OcuppationalTaxItem/locales/OccupationalTaxItem_en_US.js'),
    'components/SharedComponents/OcuppationalTaxItem/locales/OccupationalTaxItem_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SharedComponents/OcuppationalTaxItem/locales/OccupationalTaxItem_fr_CA.js'),
    'components/SharedComponents/RetailDeliveryFeeItem/locales/RetailDeliveryFeeItem_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SharedComponents/RetailDeliveryFeeItem/locales/RetailDeliveryFeeItem_en_US.js'),
    'components/SharedComponents/RetailDeliveryFeeItem/locales/RetailDeliveryFeeItem_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SharedComponents/RetailDeliveryFeeItem/locales/RetailDeliveryFeeItem_fr_CA.js'),
    'components/SharedComponents/Stores/NoStores/locales/NoStores_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SharedComponents/Stores/NoStores/locales/NoStores_en_US.js'),
    'components/SharedComponents/Stores/NoStores/locales/NoStores_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SharedComponents/Stores/NoStores/locales/NoStores_fr_CA.js'),
    'components/SiteSearch/locales/SiteSearch_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SiteSearch/locales/SiteSearch_en_US.js'),
    'components/SiteSearch/locales/SiteSearch_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SiteSearch/locales/SiteSearch_fr_CA.js'),
    'components/SmartChat/ChatLink/locales/ChatLink_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SmartChat/ChatLink/locales/ChatLink_en_US.js'),
    'components/SmartChat/ChatLink/locales/ChatLink_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SmartChat/ChatLink/locales/ChatLink_fr_CA.js'),
    'components/SmartSkinScan/locales/SmartSkinScan_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SmartSkinScan/locales/SmartSkinScan_en_US.js'),
    'components/SmartSkinScan/locales/SmartSkinScan_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SmartSkinScan/locales/SmartSkinScan_fr_CA.js'),
    'components/SmsOptInBanner/locales/SmsOptInBanner_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SmsOptInBanner/locales/SmsOptInBanner_en_US.js'),
    'components/SmsOptInBanner/locales/SmsOptInBanner_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SmsOptInBanner/locales/SmsOptInBanner_fr_CA.js'),
    'components/Stores/StoreDetails/locales/CombinedHoursListing_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Stores/StoreDetails/locales/CombinedHoursListing_en_US.js'),
    'components/Stores/StoreDetails/locales/CombinedHoursListing_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Stores/StoreDetails/locales/CombinedHoursListing_fr_CA.js'),
    'components/Stores/StoreDetails/locales/StoreDetails_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Stores/StoreDetails/locales/StoreDetails_en_US.js'),
    'components/Stores/StoreDetails/locales/StoreDetails_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Stores/StoreDetails/locales/StoreDetails_fr_CA.js'),
    'components/Stores/StoreDetails/locales/StoreHours_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Stores/StoreDetails/locales/StoreHours_en_US.js'),
    'components/Stores/StoreDetails/locales/StoreHours_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Stores/StoreDetails/locales/StoreHours_fr_CA.js'),
    'components/Stores/StoreList/locales/StoreList_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Stores/StoreList/locales/StoreList_en_US.js'),
    'components/Stores/StoreList/locales/StoreList_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Stores/StoreList/locales/StoreList_fr_CA.js'),
    'components/Stores/StoreLocator/locales/StoreLocatorContent_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Stores/StoreLocator/locales/StoreLocatorContent_en_US.js'),
    'components/Stores/StoreLocator/locales/StoreLocatorContent_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Stores/StoreLocator/locales/StoreLocatorContent_fr_CA.js'),
    'components/SubscribeEmail/locales/SubscribeEmail_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SubscribeEmail/locales/SubscribeEmail_en_US.js'),
    'components/SubscribeEmail/locales/SubscribeEmail_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/SubscribeEmail/locales/SubscribeEmail_fr_CA.js'),
    'components/TextAlertsLogin/locales/TextAlertsLogin_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/TextAlertsLogin/locales/TextAlertsLogin_en_US.js'),
    'components/TextAlertsLogin/locales/TextAlertsLogin_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/TextAlertsLogin/locales/TextAlertsLogin_fr_CA.js'),
    'components/Tooltip/locales/Tooltip_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Tooltip/locales/Tooltip_en_US.js'),
    'components/Tooltip/locales/Tooltip_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Tooltip/locales/Tooltip_fr_CA.js'),
    'components/VendorLoginPage/locales/VendorLoginPage_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/VendorLoginPage/locales/VendorLoginPage_en_US.js'),
    'components/VendorLoginPage/locales/VendorLoginPage_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/VendorLoginPage/locales/VendorLoginPage_fr_CA.js'),
    'components/WelcomePopup/MediaPopupMobile/locales/MediaPopupMobile_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/WelcomePopup/MediaPopupMobile/locales/MediaPopupMobile_fr_CA.js'),
    'components/Wizard/locales/Wizard_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Wizard/locales/Wizard_en_US.js'),
    'components/Wizard/locales/Wizard_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/Wizard/locales/Wizard_fr_CA.js'),
    'components/general_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/general_en_US.js'),
    'components/general_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/components/general_fr_CA.js'),
    'constants/locales/Address_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/constants/locales/Address_en_US.js'),
    'constants/locales/Address_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/constants/locales/Address_fr_CA.js'),
    'constants/locales/Basket_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/constants/locales/Basket_en_US.js'),
    'constants/locales/Basket_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/constants/locales/Basket_fr_CA.js'),
    'constants/locales/Constants_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/constants/locales/Constants_en_US.js'),
    'constants/locales/Constants_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/constants/locales/Constants_fr_CA.js'),
    'constants/locales/TermsOfUse_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/constants/locales/TermsOfUse_en_US.js'),
    'constants/locales/TermsOfUse_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/constants/locales/TermsOfUse_fr_CA.js'),
    'pages/Basket/locales/Basketpage_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/pages/Basket/locales/Basketpage_fr_CA.js'),
    'pages/Brands/locales/BrandNthCategory_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/pages/Brands/locales/BrandNthCategory_en_US.js'),
    'pages/Brands/locales/BrandNthCategory_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/pages/Brands/locales/BrandNthCategory_fr_CA.js'),
    'pages/Category/locales/Category_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/pages/Category/locales/Category_en_US.js'),
    'pages/Category/locales/Category_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/pages/Category/locales/Category_fr_CA.js'),
    'pages/Community/RichProfile/MyAccount/CurbsidePickupCheckinModal/locales/CurbsidePickupCheckinModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/pages/Community/RichProfile/MyAccount/CurbsidePickupCheckinModal/locales/CurbsidePickupCheckinModal_en_US.js'),
    'pages/Community/RichProfile/MyAccount/CurbsidePickupCheckinModal/locales/CurbsidePickupCheckinModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/pages/Community/RichProfile/MyAccount/CurbsidePickupCheckinModal/locales/CurbsidePickupCheckinModal_fr_CA.js'),
    'pages/Product/locales/ProductPage_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/pages/Product/locales/ProductPage_en_US.js'),
    'pages/Product/locales/ProductPage_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/pages/Product/locales/ProductPage_fr_CA.js'),
    'pages/Store/locales/StoreDetail_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/pages/Store/locales/StoreDetail_en_US.js'),
    'pages/Store/locales/StoreDetail_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/pages/Store/locales/StoreDetail_fr_CA.js'),
    'services/api/thirdparty/locales/messages_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/services/api/thirdparty/locales/messages_en_US.js'),
    'services/api/thirdparty/locales/messages_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/services/api/thirdparty/locales/messages_fr_CA.js'),
    'utils/locales/Basket_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/Basket_en_US.js'),
    'utils/locales/Basket_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/Basket_fr_CA.js'),
    'utils/locales/Checkout_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/Checkout_en_US.js'),
    'utils/locales/Checkout_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/Checkout_fr_CA.js'),
    'utils/locales/CreditCard_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/CreditCard_en_US.js'),
    'utils/locales/CreditCard_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/CreditCard_fr_CA.js'),
    'utils/locales/Date_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/Date_en_US.js'),
    'utils/locales/Date_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/Date_fr_CA.js'),
    'utils/locales/ErrorConstants_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/ErrorConstants_en_US.js'),
    'utils/locales/ErrorConstants_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/ErrorConstants_fr_CA.js'),
    'utils/locales/Errors_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/Errors_en_US.js'),
    'utils/locales/Errors_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/Errors_fr_CA.js'),
    'utils/locales/ExperienceDetails_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/ExperienceDetails_en_US.js'),
    'utils/locales/ExperienceDetails_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/ExperienceDetails_fr_CA.js'),
    'utils/locales/Filters/Filters_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/Filters/Filters_en_US.js'),
    'utils/locales/Filters/Filters_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/Filters/Filters_fr_CA.js'),
    'utils/locales/Filters/HappeningFilters_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/Filters/HappeningFilters_en_US.js'),
    'utils/locales/Filters/HappeningFilters_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/Filters/HappeningFilters_fr_CA.js'),
    'utils/locales/Klarna_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/Klarna_en_US.js'),
    'utils/locales/Klarna_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/Klarna_fr_CA.js'),
    'utils/locales/LanguageLocale_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/LanguageLocale_en_US.js'),
    'utils/locales/LanguageLocale_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/LanguageLocale_fr_CA.js'),
    'utils/locales/Location_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/Location_en_US.js'),
    'utils/locales/Location_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/Location_fr_CA.js'),
    'utils/locales/MarketingFlags_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/MarketingFlags_en_US.js'),
    'utils/locales/MarketingFlags_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/MarketingFlags_fr_CA.js'),
    'utils/locales/Promos_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/Promos_en_US.js'),
    'utils/locales/Promos_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/Promos_fr_CA.js'),
    'utils/locales/RwdBasketModals_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/RwdBasketModals_en_US.js'),
    'utils/locales/RwdBasketModals_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/RwdBasketModals_fr_CA.js'),
    'utils/locales/SDDRougeTestV2InfoModal_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/SDDRougeTestV2InfoModal_en_US.js'),
    'utils/locales/SDDRougeTestV2InfoModal_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/SDDRougeTestV2InfoModal_fr_CA.js'),
    'utils/locales/Search/Search_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/Search/Search_en_US.js'),
    'utils/locales/Search/Search_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/Search/Search_fr_CA.js'),
    'utils/locales/Sku_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/Sku_en_US.js'),
    'utils/locales/Sku_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/Sku_fr_CA.js'),
    'utils/locales/Swatch_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/Swatch_en_US.js'),
    'utils/locales/Swatch_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/Swatch_fr_CA.js'),
    'utils/locales/TargetedLandingPromotion/TargetedLandingPromotion_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/TargetedLandingPromotion/TargetedLandingPromotion_en_US.js'),
    'utils/locales/TargetedLandingPromotion/TargetedLandingPromotion_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/TargetedLandingPromotion/TargetedLandingPromotion_fr_CA.js'),
    'utils/locales/User/User_en_US': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/User/User_en_US.js'),
    'utils/locales/User/User_fr_CA': require('/var/jenkins_home/workspace/github-release-code-build-ufe/public_ufe/js/utils/locales/User/User_fr_CA.js'),
};

module.exports = localization;
