export default function getResource(label, vars=[]){
    const resources = {
        payment: 'Paiement',
        estimatedCost: 'Coût estimé',
        taxesHit: '*taxes fédérales et provinciales non affichées',
        paymentHold: 'Mode de paiement en attente',
        paymentUsed: 'Payment Method used',
        paymentMethodsUsed: 'Payment Methods used',
        servicePayment: 'Service Payment',
        noShowFee: 'No Show Fee',
        lateCancellationFee: 'Late Cancellation Fee',
        tip: 'Tip',
        viewPolicies: 'View Policies'
    };
    return resources[label];
}
