export default function getResource(label, vars = []) {
    const resources = {
        pickAStore: 'Pick A Store',
        showMap: 'Show map',
        hideMap: 'Hide map',
        showMoreLocations: 'Show more locations',
        continueToPickArtist: 'Continue to Pick an Artist, Date, and Time',
        noResultsFound: 'No Results Found',
        noResultsMessage: 'We couldn\'t find any stores matching your search. Please refine your search criteria and try again.',
        changeCountry: 'Change Country',
        changeCountryMessage: `In order to search for stores in ${vars[0]}, please go to the ${vars[1]} and change your country to ${vars[0]}.`,
        switchCountryBasketMessage: `Once you switch, any ${vars[0]}-restricted and/or Reserve & Pickup items will be removed from your basket.`,
        bottomOfTheSite: 'bottom of the site',
        changeCountryMessage2: '',
        ok: 'Ok',
        noStoresErrorMessage: 'We’re sorry, we couldn’t find any stores within 50 miles that offer this service. Please adjust your search criteria and try again.'
    };

    return resources[label];
}
