const GIFT_CARD_APPLIED = 'GIFT_CARD_APPLIED';
const ORDER_ERRORS = 'ORDER_ERRORS';
const ORDER_REVIEW_TOGGLE = 'ORDER_REVIEW_TOGGLE';
const PAYMENT_CARD_NUMBER_CHANGED = 'PAYMENT_CARD_NUMBER_CHANGED';
const PAYMENT_CARDS_DETECTED = 'PAYMENT_CARDS_DETECTED';
const SECTION_SAVED = 'SECTION_SAVED';
const SET_PLACE_ORDER_PRE_HOOK = 'SET_PLACE_ORDER_PRE_HOOK';
const SUBMITTED_ORDER = 'SUBMITTED_ORDER';
const TOGGLE_CVC_INFO_MODAL = 'TOGGLE_CVC_INFO_MODAL';
const TOGGLE_PLACE_ORDER = 'TOGGLE_PLACE_ORDER';
const UPDATE_DELIVERY_INSTRUCTIONS = 'UPDATE_DELIVERY_INSTRUCTIONS';
const UPDATE_ORDER = 'UPDATE_ORDER';
const UPDATE_SHIPPING_METHODS = 'UPDATE_SHIPPING_METHODS';
const VALIDATE_ADDRESS = 'VALIDATE_ADDRESS';
const CREATE_DRAFT_HAL_ADDRESS = 'CREATE_DRAFT_HAL_ADDRESS';
const REMOVE_HAL_ADDRESS = 'REMOVE_HAL_ADDRESS';
const SHOW_SCHEDULED_DELIVERY_UNAVAILABLE = 'SHOW_SCHEDULED_DELIVERY_UNAVAILABLE';
const UPDATE_CURRENT_HAL_ADDRESS = 'UPDATE_CURRENT_HAL_ADDRESS';
const UPDATE_ADDRESS_LIST_WITH_HAL_ADDRESS = 'UPDATE_ADDRESS_LIST_WITH_HAL_ADDRESS';
const UPDATE_SEPHORA_TERMS = 'UPDATE_SEPHORA_TERMS';
const UPDATE_AUTOREPLENISH_TERMS = 'UPDATE_AUTOREPLENISH_TERMS';
const UPDATE_SDU_TERMS = 'UPDATE_SDU_TERMS';
const UPDATE_AGENT_AWARE_TERMS = 'UPDATE_AGENT_AWARE_TERMS';
const SET_DELIVERY_ISSUES = 'SET_DELIVERY_ISSUES';
const SET_SELECTED_DELIVERY_ISSUE = 'SET_SELECTED_DELIVERY_ISSUE';
const SET_DELIVERY_ISSUE_MODAL_SCREEN = 'SET_DELIVERY_ISSUE_MODAL_SCREEN';
const SET_RETURN_ELIGIBILITY = 'SET_RETURN_ELIGIBILITY';
const SET_DELIVERY_ISSUE_ERROR = 'SET_DELIVERY_ISSUE_ERROR';
const SET_LAST_SHIPPING_ADDRESS_ID = 'SET_LAST_SHIPPING_ADDRESS_ID';
const UPDATE_ORDER_HEADER = 'UPDATE_ORDER_HEADER';
const UPDATE_WAIVE_SHIPPING = 'UPDATE_WAIVE_SHIPPING';

export {
    GIFT_CARD_APPLIED,
    ORDER_ERRORS,
    ORDER_REVIEW_TOGGLE,
    PAYMENT_CARD_NUMBER_CHANGED,
    PAYMENT_CARDS_DETECTED,
    SECTION_SAVED,
    SET_PLACE_ORDER_PRE_HOOK,
    SUBMITTED_ORDER,
    TOGGLE_CVC_INFO_MODAL,
    TOGGLE_PLACE_ORDER,
    UPDATE_DELIVERY_INSTRUCTIONS,
    UPDATE_ORDER,
    UPDATE_SHIPPING_METHODS,
    VALIDATE_ADDRESS,
    CREATE_DRAFT_HAL_ADDRESS,
    REMOVE_HAL_ADDRESS,
    SHOW_SCHEDULED_DELIVERY_UNAVAILABLE,
    UPDATE_CURRENT_HAL_ADDRESS,
    UPDATE_ADDRESS_LIST_WITH_HAL_ADDRESS,
    UPDATE_SEPHORA_TERMS,
    UPDATE_AUTOREPLENISH_TERMS,
    UPDATE_SDU_TERMS,
    UPDATE_AGENT_AWARE_TERMS,
    SET_DELIVERY_ISSUES,
    SET_SELECTED_DELIVERY_ISSUE,
    SET_DELIVERY_ISSUE_MODAL_SCREEN,
    SET_RETURN_ELIGIBILITY,
    SET_DELIVERY_ISSUE_ERROR,
    SET_LAST_SHIPPING_ADDRESS_ID,
    UPDATE_ORDER_HEADER,
    UPDATE_WAIVE_SHIPPING
};
