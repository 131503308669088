export default function getResource(label, vars = []) {
    const resources = {
        requiredInformationLabel: '* Renseignements nécessaires',
        recognizedRegisteredEmailMessage: 'Il semble que vous vous êtes déjà inscrit(e) au programme Beauty Insider dans un magasin Sephora, car votre adresse courriel a été reconnue',
        fillInformationMessage: 'Veuillez ajouter les renseignements ci-dessous pour compléter votre profil.',
        notYouMessage: 'Ce n’est pas vous? Pour effacer les informations d’inscription et recommencer,',
        notYouClickHereClearLink: 'cliquez ici',
        firstNameLabel: 'Prénom',
        lastNameLabel: 'Nom de famille',
        emailAddresLabel: 'Adresse de courriel',
        passwordLabel: `Mot de passe (${vars[0]} à ${vars[1]} caractères)`,
        showPasswordLinkAriaLabel: 'Montrer le mot de passe',
        hidePasswordLinkAriaLabel: 'Cacher le mot de passe',
        phoneNumber: 'Numéro de téléphone',
        staySignedInLabel: 'Garder ma session ouverte',
        joinSephoraLabel: 'Devenir membre Sephora Beauty Insider',
        joinSephoraDisclaimer: 'En rejoignant notre programme Beauty Insider, vous cumulerez des points à chaque achat.',
        zipCodeLabel: 'Code postal (facultatif)',
        createAccountButtonLabel: 'Créer un compte',
        registerButtonLabel: 'S’inscrire',
        joinNow: 'S’inscrire',
        completeProfile: 'Remplir le profil',
        verified: 'vérifié',
        justOneMoreStep: 'Une étape de plus! Créez votre mot de passe et entrez vos renseignements pour compléter votre profil.',
        joinBiFreeShip: 'Joignez-vous au programme de fidélisation Beauty Insider. Accumulez des points, profitez de *l’expédition standard GRATUITE*, échangez des récompenses et plus encore.',
        useYourPhoneLabel: 'Utilisez votre numéro de téléphone pour une recherche de compte plus rapide en magasin.',
        staySignedInTooltip: 'En sélectionnant cette option, vous n’aurez pas à ouvrir de session aussi souvent sur cet appareil. Pour votre sécurité, nous vous recommandons de le faire uniquement sur vos appareils personnels.',
        continue: 'Continuer',
        registrationComplete: 'Inscription complétée',
        confirmMessageBI: 'Félicitations! Vous êtes désormais membre Beauty Insider',
        confirmMessage: 'Nous vous remercions de vous être inscrit(e) sur Sephora',
        byClicking: `En cliquant sur « ${vars[0]} », vous reconnaissez être résident du Canada ou des États-Unis et (1) avoir lu la`,
        privacyPolicy: 'Politique de confidentialité',
        noticeFinancialIncentive: 'Avis de prime financière',
        agreeTo: ' acceptez ',
        termsOfUse: 'CONDITIONS D’UTILISATION',
        and: ' et la ',
        biTerms: 'MODALITÉS BEAUTY INSIDER',
        marketingCheckbox: 'Inscrivez-moi aux alertes marketing par texto de Sephora.',
        disclosure: 'Divulgation des alertes par texto Sephora :',
        byEntering: ' En entrant votre numéro de téléphone, vérifiez les',
        signMeUp: ' Inscrivez-moi aux alertes marketing par texto Sephora,',
        clicking: ` En cliquant sur le bouton « ${vars[0]} » et en confirmant votre inscription, vous consentez à recevoir des messages publicitaires automatiques récurrents, y compris des rappels de panier abandonné, et confirmez que vous avez lu et accepté les`,
        textTerm: ' MODALITÉS DES TEXTOS.',
        message: ' La fréquence des messages varie. Le consentement n’est pas une condition d’achat. Des frais de messagerie texte et de données peuvent s’appliquer. Consultez notre ',
        privacy: 'POLITIQUE DE CONFIDENTIALITÉ',
        noticeOf: 'AVIS DE PRIME FINANCIÈRE',
        textStop: ' Textez ARRET pour annuler en tout temps. AIDE pour obtenir de l’aide.',
        textStopAddress: ' Sephora : 600, boul. de Maisonneuve Ouest, bureau 2400, Montréal (Québec)  H3A 3J2, Canada 1-877-737-4672.',
        receiveOffers: ', et vous acceptez de recevoir automatiquement les offres Beauty Insider par courriel.',
        signInHere: 'Ouvrir une session ici'
    };

    return resources[label];
}
