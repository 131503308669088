/*eslint quotes: 0*/
export default function getResource(label, vars = []) {
    const resources = {
        callStore: 'Appeler le magasin',
        cancelOrderButton: 'Annuler votre commande',
        certonaTitle: 'Les gens ont aussi acheté',
        confirmEmail: 'courriel de confirmation ',
        contactInfo: 'Coordonnées',
        contactMessage1: 'Veuillez avoir en main votre ',
        continueShoppingButton: 'Continuer à magasiner',
        curbsidePickup: 'Je suis ici pour le ramassage',
        curbsidePickUpFirstLine: 'Si vous voulez le ramassage côté trottoir,',
        curbsidePickUpParagraphPrefix: 'aidez-nous à vous trouver en décrivant votre véhicule à votre arrivée au magasin.',
        curbsidePickUpParagraphSufix: 'pour les instructions de stationnement et les heures d’ouverture.',
        curbsideConciergeInstructionsTitle: 'Instructions : concierge pour ramassage à l’extérieur',
        conciergePickupPrefix: 'Stationnez-vous à l’endroit ',
        conciergePickupSuffix: ', puis enregistrez votre localisation et les informations de votre véhicule',
        describedBelow: 'décrit ci-dessous',
        curbsideConciergePickupRequirement: 'Veuillez avoir en main votre courriel de confirmation ou votre pièce d’identité avec photo',
        deliverTo: 'Livrer à',
        deliveryOn: 'Livraison le',
        emailUpdatesText: 'Vous recevrez des mises à jour sur l’état de votre commande par courriel.',
        estimatedDelivery: 'Livraison estimée',
        or: 'ou ',
        orderedMessageText: 'C’est commandé. Consultez votre boîte de réception d’ici peu pour voir le courriel de confirmation.',
        orderNumberText: 'Numéro de commande',
        photoId: 'une pièce d’identité avec photo ',
        pickupBarcodeMsg: 'Montrez le code à barres de la commande à ramasser. Vous pouvez le trouver dans votre courriel ou en ouvrant cette page sur votre appareil mobile.',
        pickupLocation: 'Lieu du ramassage',
        pickupOrderedMessageText: 'Vous recevrez un courriel de confirmation avec les détails de la réservation au',
        pickupOrderNumberText: 'Numéro de la commande à ramasser',
        pickupPerson: 'Personne qui ramassera la commande',
        ready: 'au moment du ramassage de votre commande.',
        sameDayConfirmationText: `Vous recevrez un courriel de confirmation au`,
        seeBelow: 'Voir ci-dessous',
        seeFAQ: 'Voir la FAQ',
        seeCurbsideConciergeLocation: 'Voir la localisation du concierge pour le ramassage à l’extérieur',
        shipmentText: `Expédition ${vars[0]}`,
        shipTo: 'Expédier à',
        standardShipping: 'Expédition standard',
        storeDetails: 'Détails du magasin',
        thankYouHeader: 'Merci!',
        trackYourOrder: 'Suivre votre commande',
        viewBasketButton: 'Voir le panier',
        viewOrderLink: 'Détails de la commande.',
        viewOrderText: 'Pour consulter ou annuler votre commande, rendez-vous sur ',
        weGotItHeader: 'Nous l’avons!',
        whatToExpectText: 'À quoi s’attendre',
        canceledItems: 'Un ou plusieurs articles n’étaient plus disponibles, mais les autres sont en route. Voir ci-dessous pour connaître les détails.',
        shipToFeDexLocation: 'Expédier à un lieu de ramassage FedEx',
        shipToPickupLocation: 'Expédier à un lieu de ramassage',
        viewOrderTextForSDU: 'Pour consulter votre commande, rendez-vous sur ',
        giftMessageMsg: 'Votre message cadeau et un reçu-cadeau seront envoyés à',
        giftMessageStandard: 'lorsque la commande est expédiée',
        giftMessageSdd: 'lorsque la commande est en rupture de stock',
        mixedBasketGiftMessageStandard1: 'Nous enverrons une copie de votre message cadeau et un reçu-cadeau pour vos articles livrés par expédition standard à',
        mixedBasketGiftMessageStandard2: 'lorsque la commande est en cours de livraison.',
        mixedBasketGiftMessageSdd1: 'Nous enverrons une copie de votre message cadeau et un reçu-cadeau pour vos articles livrés par expédition le jour même à',
        mixedBasketGiftMessageSdd2: 'lorsque la commande est en cours de livraison.'
    };

    return resources[label];
}
