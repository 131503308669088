const resources = {
    usName: 'United States',
    caName: 'Canada',
    firstName: 'First Name',
    lastName: 'Last Name',
    address2Label: 'Apt / Unit / Floor (optional)',
    add: 'Add',
    country: 'Country',
    streetAddress: 'Street Address',
    city: 'City',
    phone: 'Phone',
    postalCode: 'Postal Code',
    zipPostalCode: 'ZIP/Postal Code',
    emailAddress: 'Email Address',
    enterZipCodeText: 'ZIP/ Postal Code',
    region: 'Region',
    stateRegion: 'State/Region',
    province: 'Province',
    emailRequiredText: 'Your email address is required for shipping notifications and order tracking.'
};

export default function getResource(label) {
    return resources[label];
}
