import ufeApi from 'services/api/ufeApi';

// https://jira.sephora.com/wiki/display/ILLUMINATE/Logout+API

function logout() {
    const url = '/api/auth/logout';

    return ufeApi.makeRequest(url, { method: 'POST' }).then(data => (data.errorCode ? Promise.reject(data) : data));
}

export default logout;
