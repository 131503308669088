export default function getResource(label, vars = []) {
    const resources = {
        youAreBookedTitle: 'Vous avez réservé!',
        emailConfirmation: 'Vous recevrez un courriel de confirmation au',
        artist: 'Artiste',
        confirmationNumber: 'Numéro de confirmation',
        addToCalendarButton: 'Ajouter au calendrier',
        rescheduleButton: 'Changer la date',
        cancelButton: 'Annuler',
        viewAllReservations: 'Voir toutes les réservations',
        areYouSure: 'Souhaitez-vous vraiment annuler?',
        areYouSureWithin24Hours: `Souhaitez-vous vraiment annuler? Une annulation dans les 24 heures précédant votre rendez-vous entraînera des frais de ${vars[0]}.`,
        seePolicies: 'Consulter les politiques',
        moreDetails: 'pour plus de détails.',
        cancelService: 'Annuler le service',
        no: 'Non',
        yes: 'Oui'
    };

    return resources[label];
}
