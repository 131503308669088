export default function getResource(label, vars = []) {
    const resources = {
        UPCOMING: 'À venir',
        PAST: 'Passé',
        CANCELLED: 'Annulé',
        CANCELLED_BY_STORE: 'Annulé par le magasin',
        LATE_CANCELLATION: `Frais d’annulation tardive de ${vars[0]}`,
        NO_SHOW: `Frais d’absence de ${vars[0]}`,
        addToCalendar: 'Ajouter au calendrier',
        reschedule: 'Changer la date',
        cancel: 'Annuler',
        bookAgain: 'Réserver à nouveau',
        productRecs: 'Afficher les recommandations',
        serviceDescription: 'Description du service',
        confirmation: 'Confirmation',
        location: 'Emplacement',
        details: 'Détails',
        selectedFeature: 'Caractéristique sélectionnée',
        reservationDetails: 'Détails de la réservation',
        dateAndTime: 'Date et heure',
        eventDescription: 'Description de l’événement'
    };

    return resources[label];
}
