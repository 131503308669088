const resources = {
    useItWithTitle: 'Use It With',
    similarProductsTitle: 'Similar Products',
    youMayAlsoLikeTitle: 'You May Also Like',
    frequentlyBoughtTogether: 'Frequently Bought Together',
    recentlyViewedLabel: 'Recently Viewed',
    highlights: 'Highlights',
    ingredients: 'Ingredients',
    faq: 'FAQ',
    howToUse: 'How to Use',
    aboutTheBrand: 'About the Brand',
    shopAll: 'Shop all',
    aboutTheProduct: 'About the Product',
    item: 'Item',
    showMore: 'Show more',
    showLess: 'Show less',
    availableOnlineOnly: 'Available online only',
    free: 'FREE',
    viewAsList: 'View as list',
    reserveNotAvailable: 'Reserve & Pick Up Not Available',
    pickUpNotAvailable: 'Buy Online & Pick Up Not Available',
    pickUpNotOfferedForItem: 'Pickup not offered for this item',
    downloadOpenApp: 'Download or open the Sephora app to purchase.',
    remove: 'Remove',
    viewMoreCleanBeauty: 'View more Clean Beauty',
    askAQuestion: 'Ask a question',
    gotIt: 'Got It',
    addAllToBasket: 'Add All to Basket',
    finalSaleProductFlag: 'FINAL SALE',
    finalSaleModalInfoTitle: 'Final Sale',
    finalSaleNoReturns: 'Final Sale: No returns or exchanges',
    learnMore: 'Learn more',
    showMoreProducts: 'Show more products',
    compareSimilarProducts: 'Compare Similar Products',
    quantityWarningTitle: 'Quantity Limited to 1',
    quantityWarningMessage: 'Auto-Replenish item quantity is limited to 1.',
    quantityWarningButtonText: 'Got It',
    freeShipping: 'FREE Shipping',
    addToLoves: 'Add to Loves',
    rougeBadge: 'ROUGE EXCLUSIVE'
};

export default function getResource(label) {
    return resources[label];
}
