export default function getResource(label, vars = []) {
    const resources = {
        title: 'From Rewards Bazaar',
        titleRouge: 'More Great Rewards',
        viewAll: 'View all',
        add: 'Add',
        rougeBadge: 'ROUGE EXCLUSIVE'
    };
    return resources[label];
}
