const resources = {
    useItWithTitle: 'Utiliser avec',
    similarProductsTitle: 'Produits similaires',
    youMayAlsoLikeTitle: 'Vous pourriez aussi aimer',
    frequentlyBoughtTogether: 'Souvent achetés ensemble',
    recentlyViewedLabel: 'Récemment consulté',
    highlights: 'Avantages',
    ingredients: 'Ingrédients',
    faq: 'FAQ',
    howToUse: 'Mode d’emploi',
    aboutTheBrand: 'À propos de la marque',
    shopAll: 'Découvrir',
    aboutTheProduct: 'À propos du produit',
    item: 'Article',
    showMore: 'Afficher plus',
    showLess: 'Afficher moins',
    availableOnlineOnly: 'En ligne seulement',
    free: 'GRATUIT',
    viewAsList: 'Voir en tant que liste',
    reserveNotAvailable: 'Option Réservation et ramassage non offerte',
    pickUpNotAvailable: 'Option Achetez en ligne et Ramassez en magasin non offerte',
    pickUpNotOfferedForItem: 'Le ramassage n’est pas offert pour cet article',
    downloadOpenApp: 'Pour l’acheter, téléchargez ou ouvrez l’appli Sephora.',
    remove: 'Retirer',
    viewMoreCleanBeauty: 'Voir plus de produits purs et sains',
    askAQuestion: 'Poser une question',
    gotIt: 'Compris',
    addAllToBasket: 'Tout ajouter au panier',
    finalSaleProductFlag: 'VENTE FINALE',
    finalSaleModalInfoTitle: 'Vente finale',
    finalSaleNoReturns: 'Vente finale : Aucun retour ni échange',
    learnMore: 'En savoir plus',
    showMoreProducts: 'Afficher plus de produits',
    compareSimilarProducts: 'Comparer des produits similaires',
    quantityWarningTitle: 'Quantités limitées à 1',
    quantityWarningMessage: 'La quantité d’articles à réapprovisionner automatiquement est limitée à 1.',
    quantityWarningButtonText: 'Compris',
    freeShipping: 'Livraison GRATUITE',
    addToLoves: 'Ajouter aux favoris',
    rougeBadge: 'EXCLUSIVITÉ ROUGE'
};

export default function getResource(label) {
    return resources[label];
}
