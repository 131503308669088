import LOCAL_STORAGE from 'utils/localStorage/Constants';
import Storage from 'utils/localStorage/Storage';

const authenticationUtils = {};

/*
 * Show the signin modal and apply postponed promo after Login if an apple pay session
 *
 * Note: this is needed only on basket and checkout but needs to be on all ways of logging in on
 * those pages, thus its presence here as a universal decorator
 */
authenticationUtils.showSignInModalWithPromoDecoration = function (resolve, reject, isNewUserFlow = false, analyticsData = {}, source = '') {
    // Required on the method level bc/ of a circular dependency otherwise.
    const Actions = require('Actions').default;
    const storeLocal = require('Store').default;

    const decoratedResolve = function (data) {
        const applePaySession = storeLocal.getState().applePaySession;

        // TODO: Remove isActive ApplePay check when you need to enable it for any signIn
        if (applePaySession.isActive) {
            const promo = storeLocal.getState().promo;

            if (promo.afterLogin && promo.afterLogin.length) {
                const promoUtils = require('utils/Promos').default;
                promoUtils.applyPromo(...promo.afterLogin).then(() => {
                    resolve(data);
                });
            } else {
                resolve(data);
            }
        } else {
            resolve(data);
        }
    };

    storeLocal.dispatch(
        Actions.showSignInModal({
            isOpen: true,
            callback: decoratedResolve,
            isNewUserFlow,
            analyticsData,
            errback: reject,
            source
        })
    );
};

/**
 * Renders the sign-in / register overlay if needed.  then redirects/calls the desired function
 *
 * Note: only checks for signed in/recognized versus anonymous, does not differentiate between
 * signed in and recognized (API call return status will handle that accordingly)
 *
 * @param {String} redirect        *TODO* Optional: path to redirect,
 *                                  else redirected to same page or home
 * @param {object} objdata         *TODO* Optional: object for storing the temporary data required
 *                                  by functionality which needs
 *                                 to be performed after registration is done
 *                                 objdata format should be : {
 *                                      sActionType : 'Loves',
 *                                      oActionData : {}
 *                                 }
 **/
// TODO: support redirect, and objdata
authenticationUtils.requireAuthentication = function (requiredForRecognized = false, isNewUserFlow, analyticsData, source) {
    //const store = require('Store').default;
    const validateUserStatus = require('utils/User').default.validateUserStatus;

    return new Promise(function (resolve, reject) {
        validateUserStatus().then(user => {
            var profileStatus = user.profileStatus;

            if (profileStatus === 0 || (profileStatus === 2 && requiredForRecognized)) {
                authenticationUtils.showSignInModalWithPromoDecoration(resolve, reject, isNewUserFlow, analyticsData, source);
            } else {
                resolve();
            }
        });
    });
};

// TODO THOMAS 17.4:
//  refactor various files that are using the above requireAuthentication function
//  so that those files use new decorator that calls this promise function
//  files include: ProductQuickLookMessage.c.js, AccountGreeting.c.js, InlineBasket.c.js,
//  ProductLove.c.js, RewardItem.c.js, PleaseSignIn.c.js
authenticationUtils.requireRecognizedAuthentication = function () {
    const validateUserStatus = require('utils/User').default.validateUserStatus;

    return new Promise((resolve, reject) => {
        validateUserStatus().then(user => {
            var profileStatus = user.profileStatus;

            if (profileStatus === 0) {
                authenticationUtils.showSignInModalWithPromoDecoration(resolve, reject);
            } else {
                resolve();
            }
        });
    });
};

authenticationUtils.requireLoggedInAuthentication = function () {
    const userUtils = require('utils/User').default;
    const ssiApi = require('services/api/ssi').default;

    return new Promise((resolve, reject) => {
        require.ensure(
            [],
            function (require) {
                const { setupFingerprint } = require('services/Fingerprint').default;
                setupFingerprint(deviceFingerprint => {
                    userUtils.validateUserStatus().then(user => {
                        var profileStatus = user.profileStatus;

                        if (profileStatus === 0) {
                            authenticationUtils.showSignInModalWithPromoDecoration(resolve, reject);
                        } else if (profileStatus === 2) {
                            if (Sephora.configurationSettings.isOptInSSIMWebEnabled) {
                                ssiApi
                                    .autoLogin({ deviceFingerprint })
                                    .then(() => resolve())
                                    .catch(() => authenticationUtils.showSignInModalWithPromoDecoration(resolve, reject));
                            } else {
                                authenticationUtils.showSignInModalWithPromoDecoration(resolve, reject);
                            }
                        } else {
                            resolve();
                        }
                    });
                });
            },
            'components'
        );
    });
};

authenticationUtils.isAuthServiceEnabled = () => {
    const isKillSwitchEnabled = Sephora.configurationSettings?.isAuthServiceEnabled || false;

    return isKillSwitchEnabled;
};

authenticationUtils.isAuthServiceUrl = url => {
    const authServiceUrlRegExp = /(\/api\/auth\/v1\/)/;

    return authServiceUrlRegExp.test(url);
};

authenticationUtils.storeAuthTokens = (accessToken, refreshToken, email) => {
    if (accessToken && refreshToken) {
        Storage.local.setItem(LOCAL_STORAGE.AUTH_ACCESS_TOKEN, accessToken);
        Storage.local.setItem(LOCAL_STORAGE.AUTH_REFRESH_TOKEN, refreshToken);

        if (email) {
            Storage.local.setItem(LOCAL_STORAGE.USER_EMAIL, email);
        }
    }
};

authenticationUtils.SIGN_IN_SOURCES = {
    ACCOUNT_GREETING: 'account-greeting',
    PAGE_LOAD: 'page-load'
};

export default authenticationUtils;
