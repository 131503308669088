export default function getResource(label) {
    const resources = {
        creditLimit: 'Credit Limit:',
        viewDetails: 'View Details',
        certonaCarouselTitle: 'certona carousel',
        continueShoppingButton: 'Continue Shopping',
        certonaSubHead: 'Give these a try...'
    };

    return resources[label];
}
