export default function getResource(label, vars = []) {
    const resources = {
        pickAStore: 'Choisir un magasin',
        showMap: 'Afficher la carte',
        hideMap: 'Masquer la carte',
        showMoreLocations: 'Afficher plus d’emplacements',
        continueToPickArtist: 'Continuer pour choisir un(e) artiste, une date et une heure',
        noResultsFound: 'Aucun résultat trouvé',
        noResultsMessage: 'Nous n’avons pas trouvé de magasin correspondant à votre recherche. Veuillez préciser vos critères de recherche et réessayer.',
        changeCountry: 'Changer de pays',
        changeCountryMessage: `Pour sélectionner un magasin au Canada, allez dans vos ${vars[0]} et changez votre pays pour le Canada.`,
        switchCountryBasketMessage: 'Une fois le pays modifié, tous les articles dont la vente est restreinte au Canada ou les articles Réservation et ramassage seront retirés de votre panier.',
        bottomOfTheSite: 'au bas de la page',
        changeCountryMessage2: '',
        ok: 'OK',
        noStoresErrorMessage: 'We’re sorry, we couldn’t find any stores within 50 miles that offer this service. Please adjust your search criteria and try again.'
    };

    return resources[label];
}
