import InflateComponents from 'utils/framework/InflateComponents';
import withRootComponentProps from 'utils/framework/withRootComponentProps';
import withReduxProvider from 'utils/framework/wrapWithReduxProvider';

Sephora.Util.InflatorSPA = {};
const HOCWrappingIsDisabled = Sephora.configurationSettings.core.disableHOCWrapping === true;

const createDisplayNameAsForSSR = component => {
    if (HOCWrappingIsDisabled) {
        const { displayName } = component;
        const ssrDisplayName = `ReduxProvider(${displayName})`;

        return ssrDisplayName;
    } else {
        const { displayName: ssrDisplayName } = withReduxProvider(component);

        return ssrDisplayName;
    }
};

const renderRootComponent = component => {
    const displayName = createDisplayNameAsForSSR(component);
    const element = document.body.querySelector(`[rootid="${displayName}"]`);

    if (element) {
        const componentWithProps = withReduxProvider(withRootComponentProps(component, displayName));
        InflateComponents.render(componentWithProps, null, element);
    }
};

// require.ensure is used to package nested requires in components.chunk.js
// rather than priority.bundle.js in order to keep priority.bundle.js lean
const renderRootComponents = () =>
    require.ensure(
        [],
        require => {
            const SpaUtils = require('utils/Spa').default;
            const Header = require('components/Header').default;
            const GlobalModalsWrapper = require('components/GlobalModals/GlobalModalsWrapper').default;
            const GlobalContent = require('components/GlobalContent/GlobalContent').default;
            const isSpaPage = SpaUtils.isSpaTemplate(Sephora.renderedData.template);
            let Page;

            if (isSpaPage) {
                Page = require('../../SpaApplication').default;
            } else {
                const allPages = require('pages/index').default;
                Page = allPages[Sephora.renderedData.template];
            }

            renderRootComponent(Header);
            renderRootComponent(Page);
            renderRootComponent(GlobalModalsWrapper);
            renderRootComponent(GlobalContent);

            Sephora.Util.InflatorComps.services.loadEvents.HydrationFinished = true;
            const { HydrationFinished } = require('utils/framework/Events').default;
            window.dispatchEvent(new CustomEvent(HydrationFinished));
            Sephora.Util.Perf.report(HydrationFinished);
        },
        'components'
    );

// require.ensure is used to package nested requires in postload.chunk.js
// rather than priority.bundle.js or components.chunk.js in order to keep them lean
const renderPostLoadRootComponents = () =>
    require.ensure(
        [],
        () => {
            Sephora.Util.Perf.report('PostLoad Ready');

            const Footer = require('components/Footer').default;
            const { PostloadedGAdTagList } = require('components/GAdTag').default;
            const { PostLoad } = require('utils/framework/Events').default;

            InflateComponents.services.loadEvents[PostLoad] = true;
            window.dispatchEvent(new CustomEvent(PostLoad));

            renderRootComponent(Footer);
            renderRootComponent(PostloadedGAdTagList);

            Sephora.Util.Perf.report('Post Load Rendered');
        },
        'postload'
    );

export default {
    renderPostLoadRootComponents,
    renderRootComponents
};
