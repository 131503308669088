import Storage from 'utils/localStorage/Storage';
import Location from 'utils/Location';

const getQuizName = () => {
    const path = Location.getLocation().pathname;

    return path.substring(path.lastIndexOf('/') + 1);
};

const cacheQuizResults = (results, resultsLayoutType) => {
    Storage.session.setItem(`quizState-${getQuizName()}`, JSON.stringify({ results, resultsLayoutType }));
};

const retrieveQuizResults = () => {
    return Storage.session.getItem(`quizState-${getQuizName()}`) && JSON.parse(Storage.session.getItem(`quizState-${getQuizName()}`));
};

const deleteQuizResultsCache = () => {
    Storage.session.removeAllBy(key => key.startsWith('quizState-'));
};

export default {
    cacheQuizResults,
    retrieveQuizResults,
    deleteQuizResultsCache
};
