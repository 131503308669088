import loadScriptUtils from 'utils/LoadScripts';
import localeUtils from 'utils/LanguageLocale';
import userUtils from 'utils/User';
import Location from 'utils/Location';
import basketUtils from 'utils/Basket';
import serviceUtils from 'utils/Services';
import certonaConstants from 'utils/certonaConstants';
import skuUtils from 'utils/Sku';
import BCCUtils from 'utils/BCC';
import { breakpoints } from 'style/config';
import frameworkUtils from 'utils/framework';
import { EventType, Certona } from 'constants/events';

const { buildServiceUrl } = serviceUtils;
const { loadScripts } = loadScriptUtils;
const { Application } = frameworkUtils;
const { COMPONENT_NAMES } = BCCUtils;
const PAGE_TYPES = certonaConstants.PAGE_TYPES;
const CERTONA_EVENTS = certonaConstants.CERTONA_EVENTS;

/**
 * return pagetype string
 */

const getPageType = () => {
    let pagetype;

    if (Location.isBasketPage()) {
        pagetype = PAGE_TYPES.PICK_UP_CART; // UTS-1314 [CRM] [Certona] Display a BOPIS Store Inventory Certona Carousel in Basket
    } else if (Location.isHomepage()) {
        pagetype = PAGE_TYPES.HOME;
    } else if (Location.isProductPage()) {
        pagetype = PAGE_TYPES.PRODUCT;
    } else if (Location.isRootCategoryPage()) {
        pagetype = PAGE_TYPES.CATEGORY;
    } else if (Location.isMyProfilePage()) {
        pagetype = PAGE_TYPES.PROFILE;
    } else if (Location.isOrderConfirmationPage()) {
        pagetype = PAGE_TYPES.PURCHASE;
    } else if (Location.isContentStorePage()) {
        const getContentPage = () => {
            let contentType = PAGE_TYPES.CONTENT;

            if (Location.isHolidayPage()) {
                contentType = PAGE_TYPES.HOLIDAY;
            } else if (Location.isPickupDeliveryOptionsPage()) {
                contentType = PAGE_TYPES.CONVENIENCEHUB;
            } else if (Location.isSavingsEventPage()) {
                contentType = PAGE_TYPES.SAVINGSEVENT;
            }

            return contentType;
        };

        pagetype = getContentPage();
    } else if (Location.isSearchPage()) {
        pagetype = PAGE_TYPES.NO_SEARCH;
    } else if (Location.isCreditCardApplyPage()) {
        pagetype = PAGE_TYPES.LOYALTY;
    } else if (Location.isCreditCardTabPage()) {
        pagetype = PAGE_TYPES.FPTAB;
    } else if (Location.isLovesListPage()) {
        pagetype = PAGE_TYPES.WISH_LIST;
    } else if (Location.isBrandNthCategoryPage()) {
        pagetype = PAGE_TYPES.BRANDS;
    } else if (Location.isNthCategoryPage()) {
        pagetype = PAGE_TYPES.NTH_CATEGORY;
    } else if (Location.isTargetedLandingPage()) {
        pagetype = PAGE_TYPES.LANDING_PROMO;
    } else if (Location.isPurchaseHistoryPage()) {
        pagetype = PAGE_TYPES.PURCHASE_HISTORY;
    } else if (Location.isMySephoraPage()) {
        pagetype = PAGE_TYPES.MYSEPHORA;
    } else if (Location.isCommunityGalleryPage()) {
        // there is no support to directly access certona on community pages.
        // this is just to send the correct page type to certona.
        // this will help in getting certona carousel on add to basket modal on these pages.
        pagetype = PAGE_TYPES.COMMUNITY;
    } else if (Location.isRwdCreditCardPage()) {
        pagetype = PAGE_TYPES.FPTAB;
    }

    return pagetype;
};

const shouldSetCustomerId = function () {
    return (
        Location.isLovesListPage() ||
        Location.isMyProfilePage() ||
        Location.isBasketPage() ||
        Location.isOrderConfirmationPage() ||
        Location.isTargetedLandingPage() ||
        Location.isPurchaseHistoryPage()
    );
};

//TODO: add isPurchaseHistoryPage() here to prevent recommendation data from
// being returned on initial page load
const pageHasRecommendations = function () {
    return !(
        (Location.isLovesListPage() || Location.isBrandNthCategoryPage() || Location.isNthCategoryPage() || Location.isRwdContentStorePage()) // TODO: remove that once certona will be configured for RwdContentStore template
    );
};

const getMarketingSegment = function () {
    const { AFFILIATE, EMAIL, PAID, ORGANIC } = certonaConstants.PDP_SEGMENTS;
    // prettier-ignore
    const SEGMENT_VALUES = {
        aff: AFFILIATE,
        ret: EMAIL,
        tr: EMAIL,
        ppc: PAID,
        'us_search': PAID,
        'ca_search': PAID,
        google: PAID,
        ytsrch: PAID,
        gsp: PAID,
        esv: PAID
    };
    const searchEngines = ['https://www.google.com/', 'https://search.yahoo.com/', 'https://www.bing.com/'];
    const urlUtils = require('utils/Url').default;

    const queryParams = Location.getLocation().search;
    const marketingParam = urlUtils.getParamsByName('om_mmc', queryParams) || [];
    let marketingSegment = '';

    if (marketingParam.length) {
        // If om_mmc param exists in url, return its corresponding marketing segment/channel
        const segment = marketingParam[0].split('-', 1)[0];
        marketingSegment = SEGMENT_VALUES[segment];
    } else if (searchEngines.indexOf(urlUtils.getDocumentReferrer()) !== -1) {
        // Return organic search if no om_mmc param in url but the document referrer is
        // one of the 3 engines specified above
        marketingSegment = ORGANIC;
    }

    return marketingSegment;
};

// Returns a string containing the SkuId of hard good products in basket
// separated with a semi colon
const getSkuIdsInBasket = function (basketItems) {
    return basketItems
        .filter(li => skuUtils.isHardGood(li.sku))
        .map(li => li.sku.skuId)
        .join(';');
};

const sendCertona = function (event, config = {}) {
    const store = require('Store').default;
    const categoryId = Location.isRootCategoryPage() && store.getState().category.currentCategory;
    let catPageType;
    let recommendedProductIDs = [];

    const pageType = getPageType();

    // if (Location.isMySephoraPage()) {
    //     return;
    // }

    if (Location.isNthCategoryPage()) {
        // Need to send correct page type if user switched between top and nth level cats
        catPageType = config.pageType || store.getState().productRecs.certonaObject.certonaPageType;
    }

    if (config.recommendedProductIDs) {
        recommendedProductIDs = config.recommendedProductIDs.split(';');
    }

    const basketProducts = recommendedProductIDs.length ? recommendedProductIDs : basketUtils.getAllBasketItems();
    Application.events.dispatchEvent(event, {
        detail: {
            pageType: catPageType || pageType,
            customerId: userUtils.getProfileId(),
            basketProducts: basketProducts,
            itemId: config.itemId,
            categoryId: categoryId,
            ...config
        }
    });

    if (certonaConstants.EVENTS[event] && typeof window !== 'undefined' && window.callCertona) {
        const certonaObject = {
            itemid: config.itemId,
            storeid: config.storeid,
            event: certonaConstants.EVENTS[event],
            environment: '',
            recommendations: ['addtocart', 'productcleanhighlight', 'ropisaddtocart'].includes(certonaConstants.EVENTS[event])
        };

        window.callCertona(certonaObject);

        return;
    }

    if (typeof window !== 'undefined' && window.callCertona) {
        window.callCertona();
    }
};

// This function will inject resonance.js dynamically. It should only be used on pages
// which don't include certona on page load (those in pageHasCertona() at Services.js)
// and which we can't antecipate that will or not have certona during page load.
//
// PS: at this moment we don't know if it works to load resonance.js both from <head>
// and later on dynamically (a page that needs certona both on page load + ProductFinderModal).
//
// So we might need to revisit this approach if that scenario ever comes up,
// because we don't even know at this point if certona would expect us to fire
// the event INIT_LOAD twice or once (calls are from initCertona() from Certona.js service
// during the page load and later on again from ProductFinderModal).
const injectCertona = function (shouldInitializeCertona = false) {
    if (!shouldInitializeCertona) {
        return;
    }

    const script = buildServiceUrl.certona();
    loadScripts([script], () => {
        Application.events.dispatchServiceEvent(Certona, EventType.Loaded);
        shouldInitializeCertona && initCertona();
    });
};

// This function assumes the page has already loaded resonance.js.
// Please double check if the page you are working on already have that script,
// otherwise you'll need to add your page either on <head> (by changing
// pageHasCertona() at Services.js) or calling injectCertona to load dynamically.
const initCertona = function () {
    // We'll have resonance.js send us the recommendation data when this event fires
    const store = require('Store').default;
    const watch = require('redux-watch');

    const basket = store.getState().basket;

    if (basket.isInitialized) {
        sendCertona(CERTONA_EVENTS.INIT_LOAD);
    } else {
        const unsubscribe = store.subscribe(
            watch(
                store.getState,
                'basket'
            )(newVal => {
                if (newVal.isInitialized) {
                    sendCertona(CERTONA_EVENTS.INIT_LOAD);
                    unsubscribe();
                }
            }),
            { ignoreAutoUnsubscribe: true }
        );
    }
};

const getApplicationId = function () {
    let appId;

    if (localeUtils.isUS()) {
        appId = Sephora.isDesktop() ? 'Sephora01' : 'Sephora03';
    } else {
        appId = Sephora.isDesktop() ? 'Sephora02' : 'Sephora04';
    }

    return appId;
};

const fireCertonaReady = function () {
    Application.events.dispatchServiceEvent(Certona, EventType.Ready);
};

const fireCertonaFailed = function () {
    Application.events.dispatchServiceEvent(Certona, EventType.Failed);
};

const getCertonaBccItems = function (items) {
    const certonaItems = [];

    for (const key in items) {
        if (Object.prototype.hasOwnProperty.call(items, key)) {
            const item = items[key];
            item.certonaPageType && item.componentType === COMPONENT_NAMES.SKU_GRID && certonaItems.push(item);
        }
    }

    return certonaItems;
};

const splitCertonaContainerName = function (certonaContainerName = '') {
    const certonaContainers = certonaContainerName.split(',');

    return certonaContainers[window.matchMedia(breakpoints.xsMax).matches ? 0 : 1];
};

export default {
    getPageType,
    injectCertona,
    initCertona,
    getApplicationId,
    fireCertonaReady,
    fireCertonaFailed,
    sendCertona,
    getMarketingSegment,
    getSkuIdsInBasket,
    getCertonaBccItems,
    shouldSetCustomerId,
    pageHasRecommendations,
    splitCertonaContainerName
};
