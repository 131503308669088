import utils from 'analytics/utils';
import locationUtils from 'utils/Location';
import anaConsts from 'analytics/constants';
import deepExtend from 'utils/deepExtend';

/* eslint-disable-next-line complexity */
export default function (data) {
    const currentEvent = utils.getMostRecentEvent(anaConsts.LINK_TRACKING_EVENT);
    const isHomepageRewardCarousel = locationUtils.isHomepage() && data?.analyticsData?.containerTitle === anaConsts.CAROUSEL_NAMES.REWARD_BAZAAR;
    const rootContainerName = (data.sku.rootContainerName || data.analyticsData.rootContainerName)?.toLowerCase();
    const isNotUseItWith = rootContainerName?.toLowerCase() !== anaConsts.CONTEXT.USE_IT_WITH;
    const multiProductStrings =
        Array.isArray(data.analyticsData.sku) && data.analyticsData.sku.map(item => `;${item.skuId};;;;eVar26=${item.skuId}`).join(',');
    let linkName = `Add To Basket${data.analyticsData.isPickup ? ' For Store Pickup' : data.analyticsData.isSameDay ? ' For Same Day Delivery' : ''}`;
    linkName = data.analyticsData.isSameDay ? linkName.toLowerCase() : linkName;
    linkName = data.isGalleryLightBox ? `${anaConsts.PAGE_TYPES.GALLERY}:${anaConsts.LinkData.GALLERY_ADD_TO_BASKET}` : linkName;
    let actionInfoData =
        rootContainerName && !data.analyticsData.isBIRBPageRewardModal && isNotUseItWith
            ? data.context !== anaConsts.CONTEXT.QUICK_LOOK
                ? `${rootContainerName}:add to basket`
                : 'add to basket'
            : linkName;

    const isCustomSet = data?.sku?.configurableOptions && isNotUseItWith;
    actionInfoData = isCustomSet ? anaConsts.LinkData.ADD_TO_BASKET_CUSTOM_SET : actionInfoData;
    actionInfoData = data.analyticsData.isGiftCardQuickAdd
        ? `${anaConsts.LinkData.GIFT_CARD}:${anaConsts.ACTION_INFO.ADD_TO_BASKET}`
        : actionInfoData;
    actionInfoData = data.isGalleryLightBox ? `${anaConsts.PAGE_TYPES.GALLERY}:${anaConsts.LinkData.GALLERY_ADD_TO_BASKET}` : actionInfoData;

    const eventStrings = [anaConsts.Event.SC_ADD];

    if (data?.isGalleryLightBox) {
        eventStrings.push(anaConsts.Event.GALLERY_COMPONENT_INTERACTION);
    }

    const attributes = {
        linkName: data.analyticsData.isAddFullSize ? 'Add Full Size' : isCustomSet ? anaConsts.LinkData.ADD_TO_BASKET_CUSTOM_SET : linkName,
        actionInfo: actionInfoData,
        eventStrings,
        biRewardType: data.sku.biType,
        skuId: data.sku.skuId,
        certonaContainerName: currentEvent.eventInfo.attributes.certonaContainerName
    };

    if (isHomepageRewardCarousel) {
        attributes.previousPage = `${anaConsts.PAGE_TYPES.HOMEPAGE}:${anaConsts.PAGE_NAMES.HOMEPAGE}:n/a:*`;
    }

    if (data.analyticsData.origin) {
        attributes.actionInfo = `${data.analyticsData.origin}:add to basket`;
    }

    if (data.previousPage && data.previousPage.includes('basket')) {
        attributes.actionInfo = 'add to basket';
    }

    if (data.context === anaConsts.CONTEXT.FREQUENTLY_BOUGHT_TOGETHER || data.context === anaConsts.CONTEXT.CLEAN_AT_SEPHORA) {
        attributes.eventStrings = [anaConsts.Event.SC_ADD];
    }

    if (data.context === anaConsts.CONTEXT.FREQUENTLY_BOUGHT_TOGETHER && data.analyticsData.isMultiProductsAdd) {
        attributes.actionInfo = `${anaConsts.CONTEXT.FREQUENTLY_BOUGHT_TOGETHER}:add all to basket`;
        attributes.productStrings = multiProductStrings;
        attributes.eventStrings = [anaConsts.Event.SC_ADD];
    }

    /* ILLUPH-119839
     * According to JIRA and business, requirement is to have prop55 populate with
     * actionCampaignString listed below only for the products within the
     * loves, purchases, and services carousels on the profile lists page.
     * On other pages we will continue to populate eVar75 with actionCampaignString.
     */
    if (rootContainerName) {
        const productId = data.sku.productId || data.analyticsData.productId;

        if (
            rootContainerName === anaConsts.CAROUSEL_NAMES.LOVES ||
            rootContainerName === anaConsts.CAROUSEL_NAMES.PURCHASES ||
            rootContainerName === anaConsts.CAROUSEL_NAMES.SERVICES ||
            rootContainerName === anaConsts.CAROUSEL_NAMES.USER_PROFILE
        ) {
            if (data.analyticsData.isAddFullSize) {
                attributes.actionInfo = `${rootContainerName}:${productId}:add full size`;
            } else {
                attributes.actionInfo = `${rootContainerName}:Add To Basket`;
            }
        } else if (data.context === anaConsts.CONTEXT.FREQUENTLY_BOUGHT_TOGETHER && data.analyticsData.isMultiProductsAdd) {
            const skuIds = Array.isArray(data.analyticsData.sku) && data.analyticsData.sku.map(item => item.productId);
            attributes.internalCampaign = `${anaConsts.CONTEXT.FREQUENTLY_BOUGHT_TOGETHER}:${skuIds.join('-')}:add-all-to-basket`;
        } else {
            attributes.internalCampaign = `${rootContainerName}:${productId}:add-to-basket`;
        }
    } else if (data.isGalleryLightBox) {
        attributes.internalCampaign = `${anaConsts.PAGE_TYPES.GALLERY}:${anaConsts.CONTEXT.QUICK_LOOK}:${anaConsts.LinkData.GALLERY_ADD_TO_BASKET}`;
        attributes.previousPage = `${anaConsts.PAGE_TYPES.COMMUNITY}:${anaConsts.PAGE_NAMES.GALLERY_LIGHTBOX}:n/a:*`;
        attributes.pageDetail = anaConsts.PAGE_NAMES.COMMUNITY_PRODUCT_MODAL;
    }

    // UTS-1071 Fix for pageDetail
    if (digitalData.page?.attributes?.sephoraPageInfo?.pageName && typeof data.pageDetail === 'undefined') {
        attributes.pageDetail = digitalData.page.attributes.sephoraPageInfo.pageName?.split(':')?.[1];
    }

    if (locationUtils.isRwdCreditCardPage()) {
        attributes.productStrings = data?.analyticsData?.productStrings?.[0] + `|eVar72= ${anaConsts.PAGE_NAMES.CREDIT_CARD}`;
    } else if (locationUtils.isBIPage()) {
        attributes.productStrings = data?.analyticsData?.productStrings?.[0] + `|eVar72= ${anaConsts.PAGE_NAMES.BEAUTY_INSIDER}`;
    } else if (locationUtils.isBasketPage()) {
        attributes.productStrings = data?.analyticsData?.productStrings?.[0] + `|eVar72=${anaConsts.PAGE_NAMES.BASKET}`;
    } else if (locationUtils.isBIRBPage()) {
        attributes.productStrings = data?.analyticsData?.productStrings?.[0] + `|eVar72=${anaConsts.PAGE_NAMES.REWARD_BAZAAR}`;
        attributes.eventStrings = [anaConsts.Event.SC_ADD, anaConsts.Event.EVENT_71];
        attributes.actionInfo = `${anaConsts.CAROUSEL_NAMES.REWARD_BAZAAR}:${anaConsts.CAMPAIGN_STRINGS.ADD_TO_BASKET}`;
    }

    deepExtend(currentEvent, { eventInfo: { attributes: attributes } });
}
