export default function getResource(label, vars = []) {
    const resources = {
        FIRST_NAME: 'First name required. Please enter your first name.',
        FIRST_NAME_ADS_LONG: 'Max of 15 characters.',
        FIRST_NAME_LONG: 'Max of 33 characters.',
        LAST_NAME: 'Last name required. Please enter your last name.',
        LAST_NAME_ADS_LONG: 'Max of 20 characters.',
        LAST_NAME_LONG: 'Max of 33 characters.',
        ADDRESS1_ADS_LONG: 'Max of 30 characters.',
        ADDRESS1_LONG: 'Max of 35 characters.',
        ADDRESS1: 'Address Required. Please enter your street address.',
        ZIP_CODE_US: 'ZIP Code Required. Please enter a 5 digit ZIP code.',
        ZIP_CODE_NON_US: 'Zip/Postal Code Required. Please enter a valid Zip/Postal Code.',
        INVALID_ZIP_CODE: 'Please enter a valid zipcode.',
        ZIPCODE_ADS_LONG: 'Max of 5 characters.',
        CITY: 'City required. Please enter your city.',
        CITY_LONG: '30 character max.',
        CITY_ADS_LONG: '20 character max.',
        TIME: 'Please enter a time for your preview.',
        STATE_A: 'Province required.',
        STATE_B: 'State/region required.',
        PHONE_NUMBER: 'Phone number required. Please enter your phone number.',
        PHONE_NUMBER_INVALID: 'Enter a valid phone number.',
        MOBILE_NUMBER: 'One phone number required. Please enter a phone number.',
        MOBILE_NUMBER_INVALID: 'Enter a valid mobile number.',
        ALTERNATIVE_NUMBER_INVALID: 'Enter a valid alternate number.',
        CREDIT_CARD_NUMBER: 'Credit card number required.',
        CREDIT_CARD_PIN: 'PIN required.',
        CREDIT_CARD_EXP_MONTH: 'Expiration month required.',
        CREDIT_CARD_EXP_YEAR: 'Expiration year required.',
        CREDIT_CARD_EXP_MONTH_INVALID: 'Invalid month.',
        CREDIT_CARD_EXP_DATE: 'Expired credit card.',
        CREDIT_CARD_SECURITY_CODE: 'Please enter your security code.',
        CREDIT_CARD_CVV_VALIDATION: 'No payment is required. Please verify your CVV/CVC for security purposes.',
        CREDIT_CARD_SECURITY_CODE_LENGTH: 'Please enter a valid security code.',
        PASSWORD: 'Please enter a password between 6-12 characters (no spaces).',
        CAPTCHA: 'You did not enter the characters. Please enter the correct code.',
        JOIN_BI: 'You need to agree to Terms & Conditions for BI',
        JOIN_BI_BIRTHDAY: 'Please select your birth date',
        JOIN_BI_MONTH: 'Please enter your birth month',
        JOIN_BI_DATE: 'Please enter your birth date',
        AGE_LIMIT_18: 'You must be 18 years or older to apply for a Sephora Credit Card',
        EDIT_GIFT_CARD_IS_NOT_EMPTY: 'You are about to navigate away from this section and have not applied your Gift Card yet. Please hit “APPLY” before continuing.',
        EMAIL_EMPTY: 'Please enter your email address.',
        EMAIL_INVALID: 'Please enter an e-mail address in the format username@domain.com.',
        EMAIL_LONG: 'Max of 60 characters.',
        EMAIL_ADS_LONG: 'Max of 50 characters.',
        IN_STORE_USER: 'We think you already registered for Beauty Insider in a Sephora store because we recognize your email address',
        checkoutPaymentIncomplete: 'Please select a payment method to continue.',
        checkoutPaymentGiftCardNotEnough: 'Your Gift Card credit is less than the order total. Please add another payment method.',
        checkoutPaymentStoreCreditNotEnough: 'Your Store Credit is less than the order total. Please add another payment method.',
        checkoutPaymentGiftCardNotApplied: 'You are about to navigate away from this section and have not applied your Gift Card yet. Please hit “APPLY” before continuing.',
        socialSecurityInvalid: 'Please enter the last 4 digits of your Social Security Number',
        SOCIAL_SECURITY_ZEROS: 'Please enter a valid Social Security Number',
        annualIncome: 'Annual Income is required',
        ANNUAL_INCOME_ADS_LONG: 'Max of 7 characters.',
        orderIdEmpty: 'Please enter an order number.',
        orderIdGeneric: 'Something went wrong. Please try again.',
        orderIdInvalid: 'Enter a valid order number.',
        EMAIL_SUBJECT_REQUIRED: 'Subject is required.',
        gcNumber: 'Gift card number required.',
        gcPin: 'Gift card pin number required.',
        APPLY_FORM_SECTION: 'Please correct the error(s) in the highlighted fields below.',
        error: 'Error',
        shippingRestriction: 'Shipping Restriction',
        addressInUse: 'Delete Address',
        cardInUse: 'Delete Payment Method',
        MARKETING_PHONE_NUMBER: 'Please enter your phone number to signup for Sephora marketing text alerts.',
        giftCardNumber: 'Gift card number is required.',
        giftCardPIN: 'Gift card PIN is required.'
    };
    return resources[label];
}
