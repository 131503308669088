import ufeApi from 'services/api/ufeApi';

const getServiceBookingSlots = (token, {
    bookingId, storeId, resourceIds, startDateTime, endDateTime
}) => {
    const sdnDomain = Sephora.configurationSettings.sdnApiHost;
    const resourceIdsParam = resourceIds ? `&resourceIds=${Array.isArray(resourceIds) ? resourceIds.join() : resourceIds}` : '';

    const url = `${sdnDomain}/v2/happening/services/${bookingId}/availability/slots?locationId=${storeId}${resourceIdsParam}&startDateTime=${startDateTime}&endDateTime=${endDateTime}`;

    return ufeApi.makeRequest(url, {
        method: 'GET',
        headers: {
            'x-requested-source': 'rwd',
            authorization: `Bearer ${token}`
        }
    });
};

export default getServiceBookingSlots;
