export default function getResource(label, vars = []) {
    const resources = {
        UPCOMING: 'Upcoming',
        PAST: 'Past',
        CANCELLED: 'Canceled',
        CANCELLED_BY_STORE: 'Canceled By Store',
        LATE_CANCELLATION: 'Late Cancelation',
        NO_SHOW: 'No Show',
        reschedule: 'Reschedule',
        cancel: 'Cancel',
        bookAgain: 'Book Again',
        viewProduct: 'View Product Recs',
        cancelRSVP: 'Cancel RSVP',
        rsvp: 'RSVP',
        free: 'FREE',
        noArtist: 'Any Available Artist',
        fee: `${vars[0]} Fee`
    };

    return resources[label];
}
