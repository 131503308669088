export default function getResource(label, vars = []) {
    const resources = {
        shopNow: 'Magasiner',
        signIn: 'Ouvrir une session',
        createAccount: 'Créer un compte',
        joinNow: 'S’inscrire',
        errorCampaignHasntStarted: 'Désolé, la promotion saisie n’a pas encore commencé.',
        errorCampaignExpired: 'Désolé, la promotion saisie a expiré.',
        errorMaxCount1: 'Oups! Un problème est survenu.',
        errorMaxCount2: 'Veuillez communiquer avec la personne qui vous a envoyé le lien.',
        errorInvalidCampaign: 'Cette promotion n’existe pas.',
        errorInvalidCountry: 'Cette promotion n’est pas offerte dans ce pays.',
        selfRegistrationNotAllowedLine1: 'Oups! Un problème est survenu.',
        selfRegistrationNotAllowedLine2: 'Veuillez copier et coller le lien de nouveau pour le partager avec votre ami.',
        errorAlreadyBI: 'Cette promotion n’est pas offerte aux membres Beauty Insider actuels.',
        errorAdvocacyDown: 'Oups! Le service n’est pas disponible pour le moment.',
        errorBiDown: 'Beauty Insider n’est pas disponible pour le moment.',
        errorGenericDescription: 'Nous tentons de le remettre en ligne. Veuillez réessayer plus tard. D’ici là, profitez-en pour vous inspirer en explorant notre site.',
        valid: 'Valide',
        yourPurchase: 'votre achat'
    };

    return resources[label];
}
