const ACTION_TYPES = {
    SET_CONTENTFUL_CERTONA_QUIZ_DATA: 'SET_CONTENTFUL_CERTONA_QUIZ_DATA'
};

const initialState = {
    contentfulCertonaQuizData: {}
};

const reducer = function (state = initialState, { type, payload }) {
    switch (type) {
        case ACTION_TYPES.SET_CONTENTFUL_CERTONA_QUIZ_DATA:
            return Object.assign({}, state, {
                contentfulCertonaQuizData: payload
            });
        default:
            return state;
    }
};

reducer.ACTION_TYPES = ACTION_TYPES;

export default reducer;
