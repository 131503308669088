export default function getResource(label, vars = []) {
    const resources = {
        orderDetails: 'Détails de la commande',
        orderHistory: 'Afficher l’historique complet des commandes',
        orderNumber: 'Numéro de commande',
        pickUpOrderNumber: 'Numéro de la commande à ramasser',
        pickUpOrder: 'Commande à ramasser',
        cancelOrderButton: 'Annuler votre commande',
        showBarcode: 'Montrer le code à barres de la commande à ramasser',
        scan: 'Balayez pour le ramassage',
        show: 'Montrez le code à barres de la commande à ramasser à un conseiller beauté.',
        continueShopping: 'Continuer à magasiner',
        originalReservationTitle: 'Réservation initiale',
        originalReservationText: 'Veuillez consulter votre reçu de magasin ou votre ',
        purchaseHistory: 'historique des achats',
        originalReservationText2: ' pour voir l’achat final de cette commande à ramasser.',
        needToReturn: 'Besoin d’effectuer un retour?',
        returnsText: 'Les retours peuvent être effectués dans tous les magasins avec un reçu valide.',
        seeReturnsPolicy: 'Voir la politique de retour',
        seefaq: 'Voir la FAQ',
        buyItAgainLinkText: 'Page Acheter de nouveau',
        buyItAgainText1: 'Les articles ajoutés en magasin apparaîtront comme une transaction séparée dans votre ',
        buyItAgainText2: ' et auront un reçu de magasin distinct.',
        changedYourMind: 'Vous avez changé d’avis?',
        cancelYourOrder: 'Annulez votre commande',
        statusLabel: 'Statut',
        deliverTo: 'Livrer à',
        deliveryInstructions: 'Instructions de livraison',
        billingInfo: 'Infos de facturation',
        sameDayDelivery: 'Livraison le jour même',
        sameDayUnlimited: 'Livraison le jour même illimitée',
        manageSubscription: 'Gérer l’abonnement',
        standardOrder: 'Commande standard',
        selfCancel: 'Vous avez annulé votre commande',
        faqs: 'Voir la FAQ',
        paypalAccount: 'Compte PayPal',
        deliveryWindow: 'Période de livraison',
        paidWith: 'Payé avec',
        autoReplenishOrder: 'Standard, réapprovisionnement automatique',
        inStorePickupNotAvailable: 'Le ramassage en magasin n’est pas disponible pour les commandes avec concierge pour ramassage à l’extérieur, mais nos conseillers beauté sont disponibles si vous voulez magasiner d’autres articles en magasin',
        curbsideConciergeNotAvailable: 'Concierge pour ramassage à l’extérieur n’est pas disponible pour les commandes à ramasser en magasin. Demandez à un conseiller beauté d’en savoir plus sur le concierge pour ramassage à l’extérieur et essayez-le avec votre prochaine commande!',
        call188: ' Composez le 1-877-SEPHORA ou ',
        chatWith: 'clavardez avec un représentant en ligne',
        toCancel: ' pour annuler votre commande.',
        needToReturnSomething: 'Vous devez retourner quelque chose?',
        deliveryIssue: 'Vous rencontrez un problème de livraison?',
        reportIssue: 'Signaler le problème',
        applePay: 'Apple Pay',
        returnSddText: 'You can start a return as soon as your Same-Day Delivery items are on their way.',
        returnsNoLongerAvailable: 'Returns are no longer available for this order. See ',
        returnsNoLongerAvailable2: ' to learn more.',
        returnFAQs: 'Return FAQs'
    };

    return resources[label];
}
