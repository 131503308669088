import ErrorConstants from 'utils/ErrorConstants';
import UI from 'utils/UI';
import RegEx from 'components/Markdown/RegEx';

const FormValidator = {
    FIELD_LENGTHS: {
        name: 30,
        firstNameAdsRestricted: 15,
        lastNameAdsRestricted: 20,
        passwordMin: 6,
        passwordMax: 12,
        zipCode: 10,
        zipCodeAdsRestricted: 5,
        postalCode: 7,
        email: 60,
        emailAdsRestricted: 50,
        address: 35,
        addressAdsRestricted: 30,
        city: 30,
        cityAdsRestricted: 20,
        annualIncomeAdsRestricted: 10, // including 2 symbols of '$' and ','
        phone: 10,
        formattedPhone: 14,
        internationalPhone: 15,
        creditCard: 19,
        creditCardExpiration: 2,
        creditCardSpaces: 4,
        securityCode: 3,
        securityCodeAmex: 4,
        giftCardNumber: 16,
        giftCardPin: 8,
        stateRegion: 20,
        biNumber: 16,
        orderId: 11,
        taxOrderId: 13,
        timeEntry: 8,
        formattedTimeEntry: 8,
        recipientName: 31,
        creditCardExpirationDate: 5
    },

    isEmpty: function (value) {
        const isEmpty = !value || value.trim() === '';

        return isEmpty || value === ErrorConstants.TEXT_INPUT_MESSAGE;
    },

    isValidLength: function (value, minLength, maxLength) {
        return (
            typeof value !== 'undefined' && value !== null && (!minLength || value.length >= minLength) && (!maxLength || value.length <= maxLength)
        );
    },

    /**
     * Note that our test for validity is much stricter than RFC-5322's definition
     * @param el
     * @returns {boolean}
     */
    isValidEmailAddress: function (login) {
        var pattern = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/i;

        return pattern.test(login);
    },

    hasEmptySpaces: function (value) {
        return value.indexOf(' ') > -1;
    },

    isAlphaNumeric: function (value) {
        const pattern = /^[a-z0-9]+$/gi;

        return pattern.test(value);
    },
    isAlphabetsOnly: function (value) {
        const pattern = /^[a-zA-Z ]+$/gi;

        return pattern.test(value);
    },
    isNumeric: function (value) {
        const pattern = /^[0-9]+$/gi;

        return pattern.test(value);
    },

    //has any non printable character except for newline
    hasNonPrintableCharacter: function (value) {
        const pattern = /(?!\n)[^ -~‘’“”]+/g;

        return pattern.test(value);
    },

    getErrors: function (fieldComps) {
        const errors = {
            fields: [],
            messages: []
        };
        let firstErrorIndex = null;
        let index = 1;

        fieldComps.forEach(function (comp) {
            if (comp && comp.validateError) {
                const message = comp.validateError();

                if (message) {
                    if (!firstErrorIndex) {
                        firstErrorIndex = index;
                    }

                    errors.fields.push(comp.props.name);
                    errors.messages.push(message);
                }
            }

            index++;
        });

        if (firstErrorIndex) {
            errors.errorIndex = firstErrorIndex;
        }

        return errors;
    },

    replaceDotSeparator: function (value, ref) {
        let outputValue = value;

        if (UI.isAndroid()) {
            outputValue = outputValue.replace('.', '');
            ref.setValue(outputValue);
        }

        return outputValue;
    },

    inputAcceptOnlyNumbers: function (e) {
        // Del, Backspace, Tab, Escape, Enter
        const validKeys = [46, 8, 9, 27, 13];

        // Arrow keys => e.keyCode >= 35 <= 40
        if (!e.metaKey && validKeys.indexOf(e.keyCode) === -1 && !parseInt(e.key) && !(e.keyCode >= 35 && e.keyCode <= 40) && e.key !== '0') {
            e.preventDefault();

            return false;
        }

        return true;
    },

    pasteAcceptOnlyNumbers: function (event) {
        // Get pasted data via clipboard API
        const clipboardData = event.clipboardData || global.clipboardData;
        const pastedData = clipboardData.getData('Text');

        if (pastedData.match(/^\d*$/g) === null) {
            event.stopPropagation();
            event.preventDefault();

            return false;
        }

        return true;
    },

    isValidZipCode: function (value, locale) {
        const US_RE = /^\d{5}(-\d{4})?$/;
        const CA_RE = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/i;

        if (locale === 'CA') {
            return CA_RE.test(value);
        } else {
            return US_RE.test(value);
        }
    },

    isValidCreditCardMonth: function (value) {
        const num = parseInt(value, 10);

        return num > 0 && num <= 12;
    },

    getFormattedPhoneNumber: function (rawValue) {
        if (rawValue && rawValue.length > 3 && rawValue.length <= 6) {
            // add parentheses if 4-6 characters have been entered
            return `(${rawValue.slice(0, 3)}) ${rawValue.slice(3)}`;
        } else if (rawValue && rawValue.length > 6) {
            // add hyphen if character count is greater than 6
            return `(${rawValue.slice(0, 3)}) ${rawValue.slice(3, 6)}-${rawValue.slice(6)}`;
        }

        // remove parantheses if 3 or fewer characters
        return '';
    },

    getRawPhoneNumber(formattedPhoneNumber) {
        return formattedPhoneNumber.replace(RegEx.matchPhoneSpecialChars, '');
    },

    isValidPhoneNumber: function (phoneNumber) {
        return (
            !RegEx.matchIfCharsAreTheSame.test(this.getRawPhoneNumber(phoneNumber)) &&
            phoneNumber.length === FormValidator.FIELD_LENGTHS.formattedPhone
        );
    },

    isValidName: function (value) {
        const pattern = /^[a-zA-ZÀ-ÖØ-öø-ÿ \-'—'–'"`'‘’“”]+$/u;

        return pattern.test(value);
    },

    validateOrderNumber: function (orderNumber, useGenericError = false) {
        if (!orderNumber) {
            return ErrorConstants.ERROR_CODES.ORDER_ID_EMPTY;
            // Tax feature allows 11-13 characters
        } else if (orderNumber.length < 11 || orderNumber.length > 13) {
            return ErrorConstants.ERROR_CODES.ORDER_ID_INVALID; // Error for invalid length
        } else if (useGenericError) {
            return ErrorConstants.ERROR_CODES.ORDER_ID_GENERIC; // Generic error
        } else {
            return null; // No error
        }
    }
};

export default FormValidator;
