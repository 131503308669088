export default function getResource(label, vars = []) {
    const resources = {
        shopNow: 'Shop Now',
        signIn: 'Sign In',
        createAccount: 'Create Account',
        joinNow: 'Join Now',
        errorCampaignHasntStarted: 'Sorry, the promotion you entered has not started.',
        errorCampaignExpired: 'Sorry, the promotion you entered has expired.',
        errorMaxCount1: 'Oops! Something went wrong.',
        errorMaxCount2: 'Please reach out to the person who sent you the link.',
        errorInvalidCampaign: 'This promotion does not exist.',
        errorInvalidCountry: 'This promotion is not available for this country.',
        selfRegistrationNotAllowedLine1: 'Oops! Something went wrong.',
        selfRegistrationNotAllowedLine2: 'Please copy & paste the link again to share with your friend.',
        errorAlreadyBI: 'This promotion is not available to existing Beauty Insider members.',
        errorAdvocacyDown: 'Oops! The service is temporarily unavailable.',
        errorBiDown: 'Beauty Insider is temporarily unavailable.',
        errorGenericDescription: 'We’re working on getting it back online. Please try again later. In the meantime, explore our site for inspiration.',
        valid: 'Valid',
        yourPurchase: 'your purchase'
    };

    return resources[label];
}
