import userUtils from 'utils/User';
import skuUtils from 'utils/Sku';
const isRougeExclusiveEnabled = Sephora.configurationSettings.isRougeExclusiveEnabled || false;

const isRougeExclusive = () => {
    return isRougeExclusiveEnabled && userUtils.isRouge();
};

const updateRougeExclusiveSkus = (skus, basket) => {
    if (!isRougeExclusive() || !skus || !basket) {
        return skus;
    }

    const { netBeautyBankPointsAvailable } = basket;

    const newSkus = skus
        .filter(sku => !sku.isOutOfStock)
        .map(s => {
            const isInBasket = skuUtils.isInBasket(s.skuId);

            if (s.rewardPoints >= netBeautyBankPointsAvailable && !isInBasket) {
                s.hasNotEnoughPointsToRedeem = true;
            }

            return s;
        });

    return newSkus;
};

const updateRougeExclusiveBiRewardGroups = (biRewardGroups, basket) => {
    if (!isRougeExclusive() || !biRewardGroups || !basket) {
        return biRewardGroups;
    }

    Object.entries(biRewardGroups).forEach(([key, value]) => {
        biRewardGroups[key] = updateRougeExclusiveSkus(value, basket);
    });

    return biRewardGroups;
};

export default {
    isRougeExclusiveEnabled,
    isRougeExclusive,
    updateRougeExclusiveSkus,
    updateRougeExclusiveBiRewardGroups
};
