const ACTION_TYPES = {
    UPDATE_CAROUSEL: 'UPDATE_CAROUSEL',
    PRODUCT_FINDER_DATA: 'PRODUCT_FINDER_DATA',
    IS_QUIZ_SUBMITTED: 'IS_QUIZ_SUBMITTED',
    UPDATE_CERTONA_OBJECT: 'UPDATE_CERTONA_OBJECT',
    SET_QUIZ_CACHE_RESULTS: 'SET_QUIZ_CACHE_RESULTS',
    RESET_CERTONA: 'RESET_CERTONA',
    RESET_CERTONA_QUIZ: 'RESET_CERTONA_QUIZ'
};

const initialState = {
    skuGroups: [],
    quizResults: [],
    isQuizSubmitted: false,
    quizName: '',
    externalRecommendations: null,
    certonaObject: {},
    certonaScriptLoadFailed: null
};

//const CERTONA_APPEND_PAGE_TYPES = ['BestSellersGrid', 'JustArrivedGrid'];  - INFL - 1590

const reducer = function (state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_CAROUSEL: {
            if (state.skuGroups.length > 0) {
                let data = [];

                const index = state.skuGroups.findIndex(list => list.name === action.recs[0]?.name);

                if (index > -1) {
                    data = [...state.skuGroups];
                    data[index].data = [...state.skuGroups[index].data, ...action.recs[0].data];
                } else {
                    data = [...state.skuGroups, ...action.recs];
                }

                return Object.assign({}, state, { skuGroups: data, certonaScriptLoadFailed: false });
            }

            return Object.assign({}, state, { skuGroups: action.recs });
        }
        case ACTION_TYPES.PRODUCT_FINDER_DATA:
            return Object.assign({}, state, {
                quizResults: action.productFinderData.data,
                certonaObject: { certonaContainerName: action.productFinderData.name }
            });
        case ACTION_TYPES.IS_QUIZ_SUBMITTED:
            return Object.assign({}, state, { isQuizSubmitted: action.isQuizSubmitted });
        case ACTION_TYPES.UPDATE_CERTONA_OBJECT:
            return Object.assign({}, state, { certonaObject: action.certonaObject });
        case ACTION_TYPES.SET_QUIZ_CACHE_RESULTS:
            return Object.assign({}, state, { ...action.cache });
        case ACTION_TYPES.RESET_CERTONA:
            return Object.assign({}, state, { certonaScriptLoadFailed: null, certonaObject: {}, skuGroups: [] });
        case ACTION_TYPES.RESET_CERTONA_QUIZ:
            return Object.assign({}, state, { isQuizSubmitted: false, skuGroups: [], quizResults: [], quizName: '' });
        default:
            return state;
    }
};

reducer.ACTION_TYPES = ACTION_TYPES;

export default reducer;
