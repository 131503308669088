export default function getResource(label, vars = []) {
    const resources = {
        title: 'De la foire aux récompenses Rewards Bazaar®',
        titleRouge: 'Encore plus de récompenses',
        viewAll: 'Tout afficher',
        add: 'Ajouter',
        rougeBadge: 'EXCLUSIVITÉ ROUGE'
    };
    return resources[label];
}
