export default function getResource(label, vars = []) {
    const resources = {
        transactionTaxRefunds: 'Remboursement des taxes sur la transaction',
        taxExemptionCategorySublabel: 'Veuillez sélectionner une catégorie.',
        categoryStepTitle: 'Tax Exemption Category',
        categoryStepDisplayName: 'Option to Select',
        categoryStepSubtitle: 'Please choose a category',
        uploadStepTitle: 'Upload Document(s)',
        uploadStepDisplayName: 'Documents',
        orderNumberInputStepTitle: 'Order Number',
        orderNumberInputStepDisplayName: 'Order Number',
        orderNumberInputStepPlaceholder: 'Order #',
        taxExemptionInfoStepTitle: 'Tax-Exemption Account Info',
        taxExemptionInfoStepDisplayName: 'Tax Exemption Information',
        generalInfo: 'Renseignements généraux avant de commencer',
        uploadAction: 'Téléverser',
        nextAction: 'Suivant',
        editAction: 'Edit',
        maxDocumentsHint: 'Documents (3 max.)',
        documentsNote: 'Veuillez noter que si votre document d’exemption est incomplet (p. ex. : signature ou date manquante, cases applicables non cochées), vous devrez soumettre de nouveau le document avec tous les renseignements nécessaires.',
        documentsContrains: 'Veuillez téléverser un fichier .jpeg, .jpg, .png, .img ou .pdf dont la taille n’excède pas 4 Mo. ',
        documentsIndications: 'Veuillez téléverser le recto et le verso du document si celui-ci est recto verso.',
        invalidFile: 'Le fichier n’a pas pu être téléversé. Veuillez vérifier la taille du fichier.',
        additionalCommentsStepTitle: 'Additional Comments',
        additionalCommentsStepDisplayName: 'Additional Comments',
        missingFreightForwarder: 'Please select one of the following',
        categoryTitleForO: 'Autre',
        categoryTitleForFNM: 'First Nation Members',
        categoryTitleForIA: 'Communauté autochtone',
        categoryTitleForR: 'Distributeur',
        categoryTitleForNPRCO: 'Organismes sans but lucratif, religieux ou de bienfaisance',
        categoryTitleForDVIFO: 'Anciens combattants handicapés et famille immédiate en Oklahoma',
        categoryTitleForESFFF: 'Vente d’exportation par transitaire de la Floride',
        categoryTitleForSLGEI: 'Gouvernements d’États et locaux et établissements d’enseignement',
        startApplication: 'Start Application'
    };

    return resources[label];
}
