const PAGE_TYPES = {
    CART: 'CART',
    HOME: 'HOME',
    PRODUCT: 'PRODUCT',
    CATEGORY: 'CATEGORY',
    PROFILE: 'PROFILE',
    PURCHASE: 'PURCHASE',
    CONTENT: 'CONTENT',
    LANDING_PROMO: 'PROMOLANDING',
    NO_SEARCH: 'NOSEARCH',
    LOYALTY: 'LOYALTY',
    SEARCH: 'SEARCH',
    FPTAB: 'FPTAB',
    WISH_LIST: 'WISHLIST',
    BRANDS: 'BRANDS',
    NTH_CATEGORY: 'NLEVELCATEGORY',
    TOP_CATEGORY: 'TOPCATEGORY',
    HOLIDAY: 'HOLIDAYHUB',
    PURCHASE_HISTORY: 'PURCHASEHISTORY',
    PICK_UP_CART: 'ROPISCART',
    CONVENIENCEHUB: 'CONVENIENCEHUB',
    PRODUCTCLEANHIGHLIGHT: 'PRODUCTCLEANHIGHLIGHT',
    MYSEPHORA: 'MYSEPHORA',
    SAVINGSEVENT: 'SAVINGSEVENT',
    COMMUNITY: 'COMMUNITY'
};

const CERTONA_EVENTS = {
    ADD_TO_BASKET: 'CertonaRecs.AddToBasket',
    REMOVE_FROM_BASKET: 'CertonaRecs.RemoveFromBasket',
    LOVES: 'CertonaRecs.Loves',
    QUICK_LOOK_BUTTON: 'CertonaRecs.QuickLookButton',
    USER_CHANGED: 'CertonaRecs.UserChanged',
    INIT_LOAD: 'CertonaRecs.InitLoad',
    OPEN_BASKET: 'CertonaRecs.OpenBasket',
    SHOW_SIMILAR_PRODUCTS: 'CertonaRecs.ShowSimilarProducts',
    SHOW_CLEAN_HIGHLIGHT_PRODUCTS: 'CertonaRecs.ShowCleanHighlightProducts',
    YOU_MAY_ALSO_LIKE_PRODUCTS: 'CertonaRecs.PurchasedProductSelected',
    ROPIS_ADD_TO_BASKET: 'CertonaRecs.ROPISAddToBasket'
};

const EVENTS = {
    'CertonaRecs.AddToBasket': 'addtocart',
    'CertonaRecs.RemoveFromBasket': 'cartremove',
    'CertonaRecs.QuickLookButton': 'quickview',
    'CertonaRecs.ShowSimilarProducts': 'similar',
    'CertonaRecs.ShowCleanHighlightProducts': 'productcleanhighlight',
    'CertonaRecs.ROPISAddToBasket': 'ropisaddtocart',
    'CertonaRecs.PurchasedProductSelected': 'purchasehistory'
};

const CERTONA_PAGE_TYPES = {
    JUST_ARRIVED_GRID: 'JustArrivedGrid',
    BESTSELLERS_GRID: 'BestSellersGrid'
};

const CERTONA_CONTAINER_NAMES = {
    PRODUCT1: 'product1_rr',
    PRODUCT2: 'product2_rr',
    PRODUCT4: 'product4_rr',
    PROFILE: 'profile_rr',
    BASKET: 'basket1_rr',
    BASKET2: 'basket2_rr',
    PURCHASE: 'orderconfirm1_rr',
    JUST_ARRIVED: 'content1_rr',
    BESTSELLERS: 'content2_rr',
    SIMILAR_PRODUCTS: 'content5_rr',
    NO_SEARCH1: 'nosearch1_rr',
    NO_SEARCH2: 'nosearch2_rr',
    LOYALTY: 'loyalty_rr',
    FPTAB: 'fptab_rr',
    YOU_MAY_ALSO_LIKE: 'purchasehistory1_rr',
    ROPIS1: 'ropis1_rr',
    BOPIS1: 'ropis2_rr',
    HOME1: 'home1_rr',
    HOME2: 'home2_rr',
    HOME4: 'home4_rr',
    CONVENIENCE_HUB: 'conveniencehub1_rr',
    PRODUCT6: 'product6_rr',
    MYSEPHORA1: 'mysephora1_rr',
    HOLIDAY_SAVINGS1: 'holidaysavings1_rr',
    HOLIDAY_SAVINGS2: 'holidaysavings2_rr'
};

const PDP_SEGMENTS = {
    AFFILIATE: 'AFFILIATE',
    EMAIL: 'EMAIL',
    ORGANIC: 'ORGANIC',
    PAID: 'PAID'
};

export default {
    CERTONA_EVENTS,
    CERTONA_PAGE_TYPES,
    CERTONA_CONTAINER_NAMES,
    PAGE_TYPES,
    PDP_SEGMENTS,
    EVENTS
};
