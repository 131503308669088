export default function getResource(label, vars = []) {
    const resources = {
        clientDetails: 'Renseignements sur le ou la membre de la clientèle',
        firstName: 'Prénom',
        lastName: 'Nom de famille',
        emailAddress: 'Adresse de courriel',
        textNotifications: 'Notifications par textos',
        phoneNumber: 'Numéro de téléphone',
        smsReminder: 'Oui, veuillez m’envoyer des rappels par message texte au sujet de mon rendez-vous.',
        iAgree: 'J’accepte les',
        textTerms: 'MODALITÉS DES TEXTOS',
        iAgreeText: 'et j’autorise Sephora à utiliser un système automatisé, un composeur automatique, un système automatisé pour la sélection ou la composition de numéros de téléphone, un système téléphonique de composition automatique ou tout autre type de système, de logiciel, de matériel ou de machine (peu importe la classification) qui peut utiliser une procédure ou un processus automatisé pour envoyer des messages textes concernant mon rendez-vous au numéro de téléphone cellulaire fourni. Le consentement n’est pas une condition d’achat. Des frais de messagerie texte et de données peuvent s’appliquer. Consultez notre',
        privacyPolicy: 'Politique de confidentialité',
        bookNow: 'Réserver',
        byCheckingTheBox: 'En cochant la case, je reconnais avoir lu attentivement et bien compris toutes les dispositions de la renonciation de droits et de l’acceptation des risques',
        waiverOfRights: 'Renonciation de droits et acceptation des risques',
        assumptionOfRisk: 'et j’assume librement et sciemment le risque et renonce à mes droits, comme décrit.',
        bookingForMinor: 'Je prends rendez-vous pour une personne âgée de moins de 18 ans.',
        validCreditCard: 'Pour garantir cette réservation, une carte de crédit valable est requise. Le paiement complet, y compris les taxes, sera effectué en magasin en utilisant votre mode de paiement préféré. Le pourboire peut être ajouté après le service. En passant cette commande, j’accepte la',
        paymentPolicy: 'Politique relative au paiement',
        defaultMethod: 'Méthode par défaut',
        securityCode: 'Code de sécurité',
        weRequireAWaiver: 'Nous avons besoin d’une renonciation de droits',
        iAcknowledge: 'Je reconnais et accepte la',
        onTimePolicy: 'Politique relative à la ponctualité',
        and: 'et la',
        cancellationPolicy: 'Politique d’annulation',
        chooseOne: 'Choisissez-en une',
        iAmTheParent: 'En cochant cette case, je reconnais être le parent ou le tuteur légal d’une personne mineure qui reçoit les services de Sephora. Je reconnais avoir lu attentivement et bien compris toutes les dispositions de la',
        waiverCheckboxesErrorText: 'Pour réserver, veuillez confirmer et cocher l’option appropriée ci-dessous.',
        policiesCheckboxErrorText: 'Pour réserver, veuillez confirmer et cocher les politiques ci-dessous.',
        confirm: 'Confirmer',
        payment: 'Paiement',
        change: 'Modifier',
        invalidPhoneNumberError: 'Veuillez saisir un numéro de téléphone cellulaire valable.',
        phoneInfoText: 'Au cas où nous aurions besoin de communiquer avec vous au sujet de votre service.',
        securityCodeInputError: 'Le code de sécurité est incorrect',
        deleteCard: 'Supprimer la carte',
        removeCardMessage: 'Souhaitez-vous vraiment supprimer cette carte?',
        yes: 'Oui',
        no: 'Non',
        genericBookingApiErr: 'Votre demande de réservation n’a pas pu être complétée pour le moment. Veuillez réessayer plus tard.',
        timeSlotAlreadyTakenErr: 'We\'re sorry, but it looks like someone else has just booked the time slot you selected. This can occasionally happen when another client completes their booking while you were making your selection.',
        securityCodeNotConfirmed: 'Appuyez sur « Confirmer » pour poursuivre la réservation.',
        cardNotSelectedErr: 'Veuillez appuyer sur « Utiliser cette carte » pour procéder à la réservation.',
        initialDataLoadError: 'We could not fetch any credit card data at this time. Please try again later.',
        timeSlotUnavailable: 'Time Slot Unavailable',
        selectNewTimeSlot: 'Select A New Time Slot'
    };

    return resources[label];
}
