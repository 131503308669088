export default function getResource(label, vars = []) {
    const resources = {
        today: 'Aujourd’hui',
        emailAddressLabel: 'Adresse de courriel',
        eventCheckIn: 'Enregistrement de l’événement',
        firstNameLabel: 'Prénom',
        lastNameLabel: 'Nom de famille',
        checkInButton: 'Enregistrement',
        byClicking: 'En cliquant sur « Enregistrement », vous (1) acceptez la version actuelle de nos ',
        termsOfUse: 'CONDITIONS D’UTILISATION',
        andHaveRead: 'et confirmez (2) avoir lu notre ',
        privacyPolicy: 'Politique de confidentialité',
        enterEmailEmptyErrorMessage: 'Veuillez entrer votre adresse électronique.',
        enterEmailFormatErrorMessage: 'Veuillez saisir une adresse courriel au format nomutilisateur@domaine.com.',
        enterFirstNameErrorMessage: 'Veuillez saisir votre prénom.',
        enterLastNameErrorMessage: 'Veuillez saisir un nom de famille.',
        youAreCheckedInHeader: 'Vous êtes inscrit(e)!',
        youAreCheckedInHeaderGreenMessage: 'Vous êtes inscrit(e)',
        getMoreFromSephoraHeader: 'En savoir plus sur Sephora',
        downloadAppText: 'Téléchargez notre appli complète et devenez un membre Beauty Insider. Accumulez des points, échangez des récompenses et réclamez un cadeau d’anniversaire!',
        downloadAppBtn: 'Télécharger notre application',
        whatToExpectHeader: 'À quoi s’attendre lors des événements',
        whatToExpectText: 'Voici ce à quoi vous devez vous attendre lors des événements Sephora.',
        participantsHeader: 'Participants',
        participantsText: 'Nos événements peuvent généralement accueillir de 10 à 30 personnes. Les invité(e)s peuvent confirmer leur présence ou se présenter. Les événements peuvent durer de 1 à 4 heures. Nous espérons que vous vous joindrez à nous!',
        costHeader: 'Coût',
        costText: 'Nos événements sont GRATUITS pour notre clientèle! Nous vous encourageons à confirmer votre présence à autant d’événements que vous le souhaitez afin que nous vous envoyions un rappel 24 heures avant d’y assister.',
        valueHeader: 'Valeur',
        valueText: 'Nous espérons que vous apprendrez quelque chose de nouveau en participant à nos événements. Nous offrons également des incitatifs et des cadeaux avec achat si vous achetez un produit à l’un de nos événements.',
        errorTitle: 'Enregistrement impossible',
        errorBody: 'Nous sommes désolés des inconvénients que cette situation pourrait vous causer. Veuillez communiquer avec la personne représentante de la marque pour connaître les prochaines étapes.',
        errorFooter: 'Ok',
        showMore: 'Afficher plus',
        showLess: 'Afficher moins'
    };

    return resources[label];
}
