export default function getResource(label, vars = []) {
    const resources = {
        shipToFedex: 'Expédition à un lieu de ramassage FedEx',
        shipToPickupLocation: 'Expédition à un lieu de ramassage',
        selectLocationNearYou: 'Sélectionner un emplacement près de chez vous',
        orShipToFedexLocation: 'Ou expédier à un lieu de ramassage FedEx près de chez vous',
        orShipToLocation: 'Ou expédier à un lieu de ramassage',
        accessPointInfoTitle: 'Expédier à un lieu de ramassage FedEx',
        changeAlternateLocation: 'Changer l’autre lieu de ramassage'
    };

    return resources[label];
}
