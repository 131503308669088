/* eslint-disable class-methods-use-this */
import RenderTime from 'utils/framework/performance/RenderTime';
import Stack from 'utils/framework/performance/Stack';

let _stack;
let _renderTime;
let _hooks;

if (Sephora.isNodeRender) {
    if (Sephora.isIsomorphicBuild) {
        _hooks = require('perf_hooks');
    }
}

class Performance {
    constructor() {
        _stack = new Stack();
        _renderTime = new RenderTime();
    }

    get stack() {
        return _stack;
    }

    get renderTime() {
        return _renderTime;
    }

    static initialize = () => {
        Sephora.performance = new Performance();
        require('utils/framework/Perf');
    };

    static now = () => {
        if (Sephora.isNodeRender) {
            if (Sephora.isIsomorphicBuild) {
                return _hooks.performance.now();
            } else {
                return global.window.performance.now();
            }
        } else {
            return window.performance.now();
        }
    };

    clear = () => {
        _stack.clear();
        _renderTime.clear();
    };
}

export default Performance;
