export default function getResource(label) {
    const resources = {
        addFullSize: 'Add Full Size',
        addToBasket: 'Add to Basket',
        addToBasketShort: 'Add',
        remove: 'Remove',
        signInToAccess: 'Sign in to access',
        allRewards: 'All Rewards',
        exclusive: 'Exclusive'
    };
    return resources[label];
}
