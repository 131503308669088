const resources = {
    continueShopping: 'Continue Shopping',
    checkoutNow: 'Checkout Now',
    viewDetails: 'View details',
    whatYouNeedToKnow: 'What You Need to Know',
    creditLimit: 'Credit Limit: ',
    certonaCarouselTitle: 'More Beauty for You',
    certonaSubHead: 'Give these a try.'
};

export default function getResource(label) {
    return resources[label];
}
