const resources = {
    selectSubstitute: 'Sélectionnez un article de remplacement',
    selectSubstituteFor: 'Sélectionnez un article de remplacement pour :',
    recommendedSubstitutions: 'Substitutions recommandées :',
    cancel: 'Annuler',
    confirm: 'Confirmer',
    outOfStock: 'Rupture de stock',
    loadLastSubstitute: 'Something went wrong loading the last substitute. Please choose your substitute again.'
};

module.exports = function getResource(label) {
    return resources[label];
};
