export default function getResource(label) {
    const resources = {
        creditLimit: 'Limite de crédit :',
        viewDetails: 'Voir les détails',
        certonaCarouselTitle: 'carrousel certona',
        continueShoppingButton: 'Continuer à magasiner',
        certonaSubHead: 'Essayez-les...'
    };

    return resources[label];
}
