export default function getResource(label, vars = []) {
    const resources = {
        UPCOMING: 'À venir',
        PAST: 'Passé',
        CANCELLED: 'Annulé',
        CANCELLED_BY_STORE: 'Annulé par le magasin',
        LATE_CANCELLATION: 'Late Cancelation',
        NO_SHOW: 'No Show',
        reschedule: 'Changer la date',
        cancel: 'Annuler',
        bookAgain: 'Réserver à nouveau',
        viewProduct: 'Afficher les recommandations',
        cancelRSVP: 'Annuler la confirmation de votre présence',
        rsvp: 'Réserver',
        free: 'GRATUIT',
        noArtist: 'Any Available Artist',
        fee: `${vars[0]} Fee`
    };

    return resources[label];
}
