const resources = {
    continueShopping: 'Continuer à magasiner',
    checkoutNow: 'Passer à la caisse',
    viewDetails: 'Voir les détails',
    whatYouNeedToKnow: 'Important à savoir',
    creditLimit: 'Limite de crédit : ',
    certonaCarouselTitle: 'Profitez de plus d’essentiels',
    certonaSubHead: 'Essayez-les.'
};

export default function getResource(label) {
    return resources[label];
}
