import Location from 'utils/Location';
import envUtils from 'utils/Env';

const shouldServiceRun = {
    certona: function () {
        return false;
    },

    testTarget: function () {
        return true;
    },

    addStructure: function () {
        return Location.isProductPage();
    },

    search: function () {
        return Location.isSearchPage();
    },

    catalog: function () {
        return true;
    },
    refreshBasket: function () {
        return true;
    }
};

const buildServiceUrl = {
    certona: function () {
        const isProduction = envUtils.isUfeEnvProduction;
        const envPrefix = isProduction ? 'www.' : 'www.qa-';

        return `//edge1.certona.net/cd/1e15a405/${envPrefix}sephora.com/scripts/resonance.js`;
    }
};

const POST_LOAD_TIMEOUT = 15000;

export default {
    shouldServiceRun,
    buildServiceUrl,
    POST_LOAD_TIMEOUT
};
